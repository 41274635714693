angular.module('app.controllers', ['ngFileUpload', 'ngImgCrop', '720kb.tooltips', 'ngDropdowns', 'luegg.directives', 'mentio', 'app.services', 'dndLists', '720kb.datepicker', 'ui.timepicker', 'rzSlider', 'toaster', 'vsGoogleAutocomplete', 'ngSanitize', 'ngQuill', 'angularjs-dropdown-multiselect', 'angular-intro', 'googlechart', 'ngTagsInput', 'analytics.mixpanel'])

app.config(['ngQuillConfigProvider', 'NG_QUILL_CONFIG_DESCRIPTIONS', function (ngQuillConfigProvider, NG_QUILL_CONFIG_DESCRIPTIONS) {
    ngQuillConfigProvider.set(NG_QUILL_CONFIG_DESCRIPTIONS);
}
])

    .controller('DashboardHeaderCtrl', ["$scope", "$rootScope", "$state", "Server", "PopupService", "overlaySpinner", "Translate", "Util", "ToasterService", "authorizationService", "Upload", "EventTracker", "NotificationBannerService", function ($scope, $rootScope, $state, Server, PopupService, overlaySpinner, Translate, Util, ToasterService, authorizationService, Upload, EventTracker, NotificationBannerService) {

        $rootScope.back = function (opts) {
            if ($state.current.name.includes('submission-assessment-result')) {
                return $state.go('submission-assessment-summary', { candidateId: $state.params.candidateId, assessmentId: $state.params.assessmentId });
            }
            else if ($state.current.name.includes('submission-assessment-summary')) {
                return $state.go('submission-assessments', { candidateId: $state.params.candidateId });
            }
            else if ($state.current.name.indexOf('submission') == 0) {

                /*  if we came from a candidate page, go back to it  */
                let lastKnownState = $rootScope.fns.getLastStateIn(['candidate-page', 'candidates']);
                if (lastKnownState) {
                    return $state.go(lastKnownState.name, lastKnownState.params, { reload: opts.reload });
                }
                return $state.go('candidates', { campaignId: $rootScope.campaign._id }, { reload: opts.reload });
            }

            if ($state.current.name.indexOf('cc-') == 0) {

                /*  if new campaign  */
                if (!$rootScope.campaign._id || $rootScope.campaign._id == 'new') {
                    return $state.go('campaigns');
                }

                let lastKnownState = $rootScope.fns.getLastStateIn(['campaigns', 'candidates']);
                if (lastKnownState) {
                    return $state.go(lastKnownState.name, lastKnownState.params);
                }
                return $state.go('candidates', { campaignId: $rootScope.campaign._id });
            }

            if ($state.current.name.indexOf('assessment-edit') == 0) {
                /*  if new assessment  */
                if (!$rootScope.assessment._id || $rootScope.assessment._id == 'new') {
                    return $state.go('assessments');
                }
                let lastKnownState = $rootScope.fns.getLastStateIn(['assessments', 'assessment-candidates']);
                if (lastKnownState) {
                    return $state.go(lastKnownState.name, lastKnownState.params);
                }
                return $state.go('assessment-candidates', {campaignId: $rootScope.assessment._id});
            }

            if ($state.current.name === 'assessment-candidates') {
                return $state.go('assessments');
            }

            if ($rootScope.campaign) {
                if ($state.current.name.indexOf('campaign-vonq-marketplace') >= 0) {
                    return $state.go('candidates', { campaignId: $rootScope.campaign._id });
                }
                if ($state.current.name.indexOf('campaign-vonq-checkout.campaign-vonq-checkout-1') >= 0) {
                    return $state.go('campaign-vonq-marketplace', { campaignId: $rootScope.campaign._id });
                }
                if ($state.current.name.indexOf('campaign-vonq-checkout.campaign-vonq-checkout-2') >= 0) {
                    return $state.go('campaign-vonq-checkout.campaign-vonq-checkout-1', { campaignId: $rootScope.campaign._id });
                }
                if ($state.current.name.indexOf('campaign-vonq-thankyou') >= 0) {
                    return $state.go('campaign-vonq-marketplace', { campaignId: $rootScope.campaign._id });
                }
            }

            $rootScope.activeTab = undefined;
            $state.go('campaigns');
        };

        $rootScope.updateAndNext = function (goNextStep) {
            console.log('updateAndNext, next=', goNextStep, $state.current);
        };

        $scope.navbarLimit = 5;
        $rootScope.userFullName = Util.userFullName;
        $rootScope.profilePhoto = Util.profilePhoto;

        $rootScope.getNotes = function (candidate) {
            if (!candidate.notes)
                return [];

            var ar = [];
            candidate.notes.forEach(function (note) {
                note.photoSrc = note.sender.photoSrc;
                note.senderName = $rootScope.userFullName(note.sender);
                if (note.seen && note.seen.indexOf($rootScope.user._id) >= 0) {
                    note.isSeen = true;
                }
                if (note.senderName) {
                    ar.push(note);
                }
            });
            candidate.notes = ar;
            return candidate.notes;
        };

        $rootScope.countUnreadNotes = function (notes) {
            if (!notes || notes.length === 0) {
                return 0
            }
            var count = 0;
            for (var i = 0; i < notes.length; ++i) {
                if (!notes[i].isSeen) {
                    ++count;
                }
            }
            return count;
        };

        $scope.ddClick = function (selected) {
            switch (selected.value) {
                case 'profile':
                    $state.go('user-profile');
                    break;
                case 'campaign':
                    $state.go('cc-settings', { campaignId: 'new' });
                    break;
                case 'collaborators':
                    $state.go('collaborators');
                    break;
                case 'logout':
                    location.replace('/users/logout');
                    break;
                case 'help':
                    PopupService.openSupportMessage($scope);
                    break;
                case 'switch_account':
                    $state.go('account-select');
                    break;
            }
        };

        $rootScope.ddCampaignSelected = {};

        var setupDropdownOptions = function () {
            if (!$rootScope.user) {
                return;
            }
            $scope.ddSelectOptions = _.filter([{
                text: Translate.getLangString('my_profile'),
                value: 'profile'
            }, {
                text: Translate.getLangString('collaborators'),
                value: 'collaborators',
                class: !$rootScope.fns.hasPrivileges(['canCollaborate']) ? 'upgrade-padlock-badge' : '',
            }, {
                text: Translate.getLangString('help_feedback'),
                value: 'help'
            }, {
                text: Translate.getLangString('logout'),
                value: 'logout'
            }
            ], function(item) {
                return !item.requiredUserRights || $rootScope.fns.userHasRights(item.requiredUserRights.name, item.requiredUserRights.level);
            });

            // Check if the user has multiple accounts
            Server.get('users/me/multiacct')
                .then(function(response) {
                    if (response.linkedAccountUsers && response.linkedAccountUsers.length > 0) {
                        $scope.ddSelectOptions.splice($scope.ddSelectOptions.length - 1, 0, {
                            text: Translate.getLangString('switch_account'),
                            value: 'switch_account',
                        });
                    }
                })
                .catch(function(err) {
                    console.error('Error fetching accounts:', err);
                });


            // Warning : this array is filtered
            $rootScope.ddCampaignOptions_Archive = _.filter([{
                text: Translate.getLangString('edit_campaign'),
                value: 'edit',
                requiredUserRights: { name: 'campaigns.list', level: 'edit' },
            }, {
                text: Translate.getLangString('view'),
                value: 'view'
            }, {
                text: Translate.getLangString('duplicate_campaign'),
                value: 'duplicate',
                requiredUserRights: { name: 'campaigns.list', level: 'edit' },
            }, {
                text: Translate.getLangString('assign_status'),
                value: 'assignStatus',
                requiredPrivileges: ['canUseCampaignStatus'],
                requiredUserRights: { name: 'campaigns.status', level: 'edit' },
            }, {
                text: Translate.getLangString('archive_campaign'),
                value: 'archive',
                requiredUserRights: { name: 'campaigns.list', level: 'edit' },
            }, {
                text: Translate.getLangString('export_csv'),
                value: 'csv',
                requiredPrivileges: ['canUseCsvExport'],
                requiredUserRights: { name: 'accessToCandidateExport', level: true },
            },
            ], function (item) {
                const privilegeCheck = !item.requiredPrivileges || $rootScope.fns.hasPrivileges(item.requiredPrivileges);
                const userRightCheck = !item.requiredUserRights || $rootScope.fns.userHasRights(item.requiredUserRights.name, item.requiredUserRights.level);
                return privilegeCheck && userRightCheck;
            });

            $rootScope.ddCampaignOptions_Delete = [{
                text: Translate.getLangString('edit_campaign'),
                value: 'edit'
            }, {
                text: Translate.getLangString('unarchive_campaign'),
                value: 'unarchive'
            }, {
                text: Translate.getLangString('export_csv'),
                value: 'csv',
                requiredPrivileges: ['canUseCsvExport'],
                requiredUserRights: { name: 'accessToCandidateExport', level: true },
            },{
                text: Translate.getLangString('duplicate_campaign'),
                value: 'duplicate',
                requiredUserRights: { name: 'campaigns.list', level: 'edit' },
            }, {
                text: Translate.getLangString('delete'),
                value: 'delete',
                requiredUserRights: { name: 'campaigns.list', level: 'delete' },
            },
            ];

            $rootScope.ddCampaignOptions_WaitingForValidation = [{
                text: Translate.getLangString('edit_campaign'),
                value: 'edit'
            },{
                text: Translate.getLangString('delete'),
                value: 'delete',
                requiredUserRights: { name: 'campaigns.list', level: 'delete' },
            },
            {
                text: Translate.getLangString('duplicate_campaign'),
                value: 'duplicate',
                requiredUserRights: { name: 'campaigns.list', level: 'edit' },
            },
            ];
        };

        setupDropdownOptions();
        $rootScope.trackSettingsClick = function() {
            EventTracker.trackCampaignGear();
        }

        $rootScope.ddCampaignClick = function (selected, campaignId, campaign) {

            switch (selected.value) {

                case 'edit':
                    EventTracker.trackCampaignEdit();
                    $state.go('cc-settings', { campaignId: campaignId });
                    break;

                case 'view':
                    EventTracker.trackCampaignView();
                    window.open(campaign.inviteLink, "_blank");
                    break;

                case 'duplicate':
                    EventTracker.trackCampaignDuplicate();
                    Server.post('campaigns/' + campaignId + '/duplicate')
                        .then(function (newCampaign) {
                            if (!campaign.isActive){
                                ToasterService.success('toaster_success_message_for_duplicate_an_active_campaign');
                            }
                            if ($state.is('campaigns') && $rootScope.campaigns) {
                                // the view is the list of campaigns
                                $rootScope.campaigns.push(newCampaign);
                                $rootScope.$emit('campaignsList.onCampaignsData');
                            } else {
                                // the view is the list of candidates
                                $state.go('candidates', { campaignId: newCampaign._id });
                            }
                        })
                        .catch(function (err) {
                            ToasterService.failure(err, 'err_35_duplicating_campaign');
                        });
                    break;

                case 'archive':
                    EventTracker.trackCampaignPause();
                    if ($rootScope.user.settings.ui.userSpecificFields.includes('aldelia')) {
                        PopupService.openAldeliaArchiveCampaign($scope, campaign)
                            .then(campaignData => {
                                Object.assign(campaign, campaignData);
                            });
                    } else {
                        PopupService.openGenericPopup($scope, {
                            submit: function () {
                                $scope.modalHandle.close();
                                Server.archiveObject('campaigns', campaignId)
                                    .then(function (campaignData) {
                                        Object.assign(campaign, campaignData);
                                    }, function (err) {
                                        console.error(err);
                                        ToasterService.failure(err, 'err_0_error_occurred');
                                    });
                            },
                            title: Translate.getLangString('confirmation'),
                            warningText: Translate.getLangString('archive_campaign_confirmation_warning'),
                            messageText: Translate.getLangString('archive_campaign_confirmation_message'),
                            yesText: Translate.getLangString('archive_campaign_confirmation_yes'),
                            noText: Translate.getLangString('cancel')
                        }, 'templates/modal-confirm-warning.html', {});
                    }
                    break;

                case 'unarchive':
                    Server.unarchiveObject('campaigns', campaignId)
                        .then(function (campaignData) {
                            Object.assign(campaign, campaignData);
                        }, function (err) {
                            console.error(err);
                            ToasterService.failure(err, 'err_0_error_occurred');
                        });
                    break;

                case 'delete':
                    PopupService.openGenericPopup($scope, {
                        submit: function () {
                            $scope.modalHandle.close();
                            Server.deleteObject('campaigns', campaignId)
                                .then(function () {
                                    if ($state.is('campaigns') && $rootScope.campaigns) {
                                        // the view is the list of campaigns
                                        _.remove($rootScope.campaigns, c => c._id === campaignId);
                                    } else {
                                        // the view is the list of candidates
                                        $state.go('campaigns');
                                    }
                                });
                        },
                        title: Translate.getLangString('delete_confirmation_title'),
                        warningText: Translate.getLangString('delete_confirmation_warning'),
                        messageText: Translate.getLangString('delete_confirmation_message'),
                        yesText: Translate.getLangString('delete_confirmation_yes'),
                        noText: Translate.getLangString('cancel')
                    }, 'templates/modal-confirm-warning.html', {});
                    break;

                case 'csv':
                    EventTracker.trackCampaignExport();
                    Util.downloadFile(Server.makeUrl('campaigns/' + campaignId + '/csv'), campaign.title[campaign.language] + ' export.csv');
                    break;
                case 'assignStatus':
                    PopupService.assignStatusToACampaign($scope, campaign)
                            .then(campaignData => {
                                Object.assign(campaign, campaignData);
                            });
                    break;

            }
        };
        /**
         * Opens a pop-up with text and a CTA redirecting to the static page (where they are invited to upgrade their plan)
         * @param  { String } reason - identifier to choose among the different texts
         * @param  { Boolean } opts.backOnClose - true if closing the pop-up should navigate back. Used if the pop-up appears on pages that are locked because restricted to users having some privilege (field 'user.privileges...')
         */
        $rootScope.upgradePlan = function (reason, opts) {

            let modalOptions = {};
            let title;
            let body;
            let cta;

            switch (reason) {
                case 'upgrd_live_interview':
                    title = Translate.getLangString('popup_upgrade_title_live_interview');
                    body = Translate.getLangString('popup_upgrade_body_live_interview');
                    cta = Translate.getLangString('popup_upgrade_CTA_live_interview');
                    break;
                case 'upgrd_candidate_page':
                    title = Translate.getLangString('popup_upgrade_title_candidate_page');
                    body = Translate.getLangString('popup_upgrade_body_candidate_page');
                    cta = Translate.getLangString('popup_upgrade_CTA_candidate_page');
                    break;
                case 'upgrd_career_page':
                    title = Translate.getLangString('popup_upgrade_title_career_page');
                    body = Translate.getLangString('popup_upgrade_body_career_page');
                    cta = Translate.getLangString('popup_upgrade_CTA_career_page');
                    break;
                case 'upgrd_collaboration':
                    title = Translate.getLangString('popup_upgrade_title_collaboration');
                    body = Translate.getLangString('popup_upgrade_body_collaboration');
                    cta = Translate.getLangString('popup_upgrade_CTA_collaboration');
                    break;
                case 'upgrd_career_page_settings':
                    title = Translate.getLangString('popup_upgrade_title_career_page_settings');
                    body = Translate.getLangString('popup_upgrade_body_career_page_settings');
                    cta = Translate.getLangString('popup_upgrade_CTA_career_page_settings');
                    break;
                case 'upgrd_company_video':
                    title = Translate.getLangString('popup_upgrade_title_company_video');
                    body = Translate.getLangString('popup_upgrade_body_company_video');
                    cta = Translate.getLangString('popup_upgrade_CTA_company_video');
                    break;
                case 'upgrd_intro_video':
                    title = Translate.getLangString('popup_upgrade_title_intro_video');
                    body = Translate.getLangString('popup_upgrade_body_intro_video');
                    cta = Translate.getLangString('popup_upgrade_CTA_intro_video');
                    break;
                case 'upgrd_thank_you_video':
                    title = Translate.getLangString('popup_upgrade_title_thank_you_video');
                    body = Translate.getLangString('popup_upgrade_body_thank_you_video');
                    cta = Translate.getLangString('popup_upgrade_CTA_thank_you_video');
                    break;
                case 'upgrd_create_campaign':
                    title = Translate.getLangString('popup_upgrade_title_create_campaign');
                    body = Translate.getLangString('popup_upgrade_body_create_campaign');
                    cta = Translate.getLangString('popup_upgrade_CTA_create_campaign');
                    break;
                case 'upgrd_candidate_exceeded':
                    title = Translate.getLangString('popup_upgrade_title_invite_candidate');
                    body = Translate.getLangString('popup_upgrade_body_invite_candidate');
                    cta = Translate.getLangString('popup_upgrade_CTA_invite_candidate');
                    break;
                case 'upgrd_candidate_exceeded':
                    title = Translate.getLangString('popup_upgrade_title_candidate_exceeded');
                    body = Translate.getLangString('popup_upgrade_body_candidate_exceeded');
                    cta = Translate.getLangString('popup_upgrade_CTA_candidate_exceeded');
                    break;
                case 'upgrd_analytics':
                    title = Translate.getLangString('popup_upgrade_title_analytics');
                    body = Translate.getLangString('popup_upgrade_body_analytics');
                    cta = Translate.getLangString('popup_upgrade_CTA_analytics');
                    break;
                case 'upgrd_assessfirst':
                    title = Translate.getLangString('popup_upgrade_title_assessfirst');
                    body = Translate.getLangString('popup_upgrade_body_assessfirst');
                    cta = Translate.getLangString('popup_upgrade_CTA_assessfirst');
                    break;
                case 'upgrd_assessfirst_individual':
                    title = Translate.getLangString('popup_upgrade_title_assessfirst_individual');
                    body = Translate.getLangString('popup_upgrade_body_assessfirst_individual');
                    cta = Translate.getLangString('popup_upgrade_CTA_assessfirst_individual');
                    break;
                case 'upgrd_default':
                default:
                    title = Translate.getLangString('popup_upgrade_title_default');
                    body = Translate.getLangString('popup_upgrade_body_default');
                    cta = Translate.getLangString('popup_upgrade_CTA_default');
            }

            let config = {
                modalClass: 'modal--upgrade',
                submit: function () {
                    window.location.href = Translate.getLangString('login_signup_link');
                },
                title: title,
                body: body,
                cta: cta,
            };

            if (opts && opts.backOnClose) {
                modalOptions = {
                    closeOnEscape: false,
                    closeOnOverlayClick: false,
                };
                config.close = function () {
                    overlaySpinner.hide('modal');
                    $scope.modalHandle.close();
                    if ($rootScope.previousStates && $rootScope.previousStates.length > 1) {
                        return window.history.back();
                    } else {
                        return $state.go('campaigns');
                    }

                }
            }

            PopupService.openGenericPopup($scope, config, 'templates/modal-upgrade.html', modalOptions);
        };

        $rootScope.reloadUser = async function () {
            if (!$rootScope.user) {
                await authorizationService.isAuthenticated();
            }
            $scope.user = $rootScope.user;
            const hostname = window.location.hostname === 'localhost' ? window.location.hostname + ':8000' : window.location.hostname;
            const host = window.location.protocol + '//' + hostname;
            let socket = io.connect(host, { secure: true, reconnect: true, rejectUnauthorized: false, transports: ["websocket"] });
            socket.on($rootScope.user._id, (message) => {
                ToasterService.success(message.message);
                //todo: do someting with the message
            })
            $rootScope.$broadcast('onCurrentUser', $rootScope.user);
            $rootScope.updateNavbar();
            Translate.setHtmlLang();
            setupDropdownOptions();
            hideLoader();
            return $rootScope.user;
        };

        $rootScope.loadUserRightsMapping = function() {
            if (!$rootScope.userRights) {
                return Server.get('user-rights')
                    .then(userRights => {
                        $rootScope.userRights = userRights;
                        $rootScope.userRights.basic = _.pickBy($rootScope.userRights.basic, (v, k) => !v.isHidden);
                        return JSON.parse(JSON.stringify($rootScope.userRights));
                    })
                    .catch(err => {
                        ToasterService.failure(err, 'load_user_rights_error');
                    });
            } else {
                return Promise.resolve(JSON.parse(JSON.stringify($rootScope.userRights)));
            }
        }

        $rootScope.updateNavbar = function() {
            $scope.navigationItems = [
                {
                    sRef: 'campaigns',
                    text: Translate.getLangString('campaigns'),
                    visible: true,
                    needsUpgrade: false,
                    tooltipText: '',
                    onClick: function () {
                        EventTracker.trackMenuCampaign();
                    }
                },
                {
                    sRef: 'candidate-page',
                    text: Translate.getLangString('candidate_page'),
                    visible: $rootScope.user && $rootScope.fns.userHasRights('candidates.profileInfo', 'view'),
                    needsUpgrade: !$rootScope.fns.hasPrivileges(["hasCandidatePage"]),
                    tooltipText: 'tooltip_upgrade_corporate',
                    onClick: function () {
                        EventTracker.trackMenuCandidates();
                    }
                },
                {
                    sRef: 'dashboard-branding',
                    text: Translate.getLangString('employer_branding'),
                    visible: $rootScope.user && $rootScope.fns.userHasRights('employerBranding.list', 'view'),
                    needsUpgrade: false,
                    tooltipText: '',
                    onClick: function () {
                        EventTracker.trackMenuEmployerBranding();
                    }
                },
                {
                    sRef: 'tasks',
                    text: Translate.getLangString('tasks'),
                    visible: $rootScope.user && $rootScope.fns.userHasRights('tasks.list', 'view'),
                    needsUpgrade: false,
                    tooltipText: '',
                    onClick: function () {
                        EventTracker.trackMenuTasks();
                    }
                },
                {
                    sRef: 'analytics',
                    text: Translate.getLangString('analytics'),
                    visible: $rootScope.user && $rootScope.fns.userHasRights('statistics.list', 'view'),
                    needsUpgrade: !$rootScope.fns.hasPrivileges(["hasAnalytics"]),
                    tooltipText: 'tooltip_upgrade_pro',
                    onClick: function () {
                        EventTracker.trackMenuAnalytics();
                    }
                },
                {
                    sRef: 'live-interview',
                    text: Translate.getLangString('interview'),
                    visible: $rootScope.user && $rootScope.fns.userHasRights('interviewScheduler.interviewSessions', 'view'),
                    needsUpgrade: !$rootScope.fns.hasPrivileges(["canLiveInterview"]),
                    tooltipText: 'tooltip_upgrade_pro',
                    onClick: function () {
                        EventTracker.trackMenuInterviewScheduler();
                    }
                },
                {
                    sRef: 'assessments',
                    text: `${Translate.getLangString('assessments')}`,
                    visible: $rootScope.user && $rootScope.fns.hasPrivileges(["hasAssessments"]) && $rootScope.fns.userHasRights('assessments.list', 'view'),
                    needsUpgrade: false,
                    tooltipText: '',
                },
                {
                    sRef: 'dashboard-departments',
                    text: `${Translate.getLangString('departments')}`,
                    visible: $rootScope.user && $rootScope.fns.hasCollaboratorsSettings(["useDepartments"]) && $rootScope.fns.userHasRights('departments.list', 'view'),
                    needsUpgrade: false,
                    tooltipText: '',
                }
            ].filter(m => m.visible)
            $scope.definedHorizontalNavLimit();
            $scope.navMoreOptions = $scope.navigationItems.slice($scope.navbarLimit).filter(m => !m.needsUpgrade)
        }

        $scope.definedHorizontalNavLimit = () => {
            const navRect = document.querySelector('.main-nav__links').getBoundingClientRect();
            let availableWidth = window.innerWidth - navRect.left - 200; // 200 assumed as minimum size for profile+padding
            navLimit = 0;
            for (const navItem of $scope.navigationItems) {
                const itemWidth = navItem.text.length*11 + 30;
                if (itemWidth < availableWidth) {
                    navLimit++;
                    availableWidth -= itemWidth;
                } else {
                    break;
                }
            }

            $scope.navbarLimit = navLimit;
        }

        $scope.navMoreClick = function(selected) {
            $state.go(selected.sRef)
        }

        $scope.openDropdownOnEnter = function(enterEvent) {
            const element = enterEvent.target;
            const ddl = element.parentElement.querySelector('ul');
            let closeTimeout;
            const openHandler = () => {
                if (closeTimeout) {
                    clearTimeout(closeTimeout);
                    closeTimeout = null;
                }
                if (!ddl.classList.contains('active')) {
                    element.click();
                }
            }
            const closeHandler = (leaveEvent) => {
                const targetElementClasses = leaveEvent.toElement ? new Array(...leaveEvent.toElement.classList) : [];
                if (!targetElementClasses.includes('dropdown--nav-more') && !targetElementClasses.includes('dropdown')) {
                    if (!closeTimeout) {
                        closeTimeout = setTimeout(() => {
                            if (ddl.classList.contains('active')) {
                                element.click();
                            }
                        }, 1000)
                    }
                }
            };
            if (!element.parentElement.onmouseleave) {
                element.parentElement.onmouseleave = closeHandler;
            }
            if (!ddl.onmouseleave) {
                ddl.onmouseleave = closeHandler
            }
            if (!ddl.onmouseenter) {
                ddl.onmouseenter = openHandler;
            }
            openHandler();
        }

        // Load notification banner
        NotificationBannerService.loadNotificationBanner($scope, 'recruiter');

        $rootScope.updateNavbar();
        $rootScope.reloadUser();

        window.onresize = _.debounce(function() {
            $rootScope.updateNavbar();
            $scope.$apply();
        }, 200);
    }])

    .controller('CampaignsCtrl', ["$scope", "$rootScope", "$state", "$compile", "Server", "multiSelect", "overlaySpinner", "Translate", "Onboarding", "$timeout", "Util", "igbPublicationFactory", "talentplugPublicationFactory", "dateRangePicker", "EventTracker", "$q", "componentCache", "PopupService", function ($scope, $rootScope, $state, $compile, Server, multiSelect, overlaySpinner, Translate, Onboarding, $timeout, Util, igbPublicationFactory, talentplugPublicationFactory, dateRangePicker, EventTracker, $q, componentCache, PopupService) {
        let multiSelectln = JSON.parse(JSON.stringify(multiSelect))

        Util.setPageTitle(Translate.getLangString('campaigns'));
        $rootScope.secondaryNav = null;
        $rootScope.backEnabled = false;
        $scope.filterCacheKey = $state.current.name + '/filters';

        $scope.search = { collaborators: [], creators: [], brandings: [], departments: [], language: [], jobCategories: [], aldeliaHubspotCompanies: [], countries: [], campaignStatus: []};
        $scope.searchField = '';

        $scope.isResettingFilters = false;

        $scope.saveFilters = function() {
            let requestBody = {
                filters: {
                    dashboardCampaigns: {
                collaborators: $scope.search.collaborators,
                creators: $scope.search.creators,
                brandings: $scope.search.brandings,
                departments: $scope.search.departments,
                language: $scope.search.language,
                jobCategories: $scope.search.jobCategories,
                aldeliaHubspotCompanies: $scope.search.aldeliaHubspotCompanies,
                countries: $scope.search.countries,
                campaignStatus: $scope.search.campaignStatus,
                sort: $scope.ddSortSelected.value,
                filter: $scope.ddFilterSelected.value,
                startDate: $scope.filters.startDate ? $scope.filters.startDate : null,
                endDate: $scope.filters.endDate ? $scope.filters.endDate : null,
            }
        }
    };
    Server.patch(`users/${$rootScope.user._id}/settings`, requestBody)
                .then(function(response) {
                }, function(error) {
                    console.error("Error when saving filter: ", error);
                });
        };

        $scope.resetFilters = function() {

            let filtersAreAlreadyReset = Object.keys($scope.search).every((key) => {
                let filter = $scope.search[key];
                return Array.isArray(filter) && filter.length === 0;
            });
            
            let sortAndFilterAreDefault = $scope.ddSortSelected.value === $scope.ddSortOptions[0].value &&
                                   $scope.ddFilterSelected.value === $scope.ddFilterOptions[0].value;
            
            let datesAreDefault = $scope.filters.startDate === undefined && $scope.filters.endDate === undefined;
            if (!filtersAreAlreadyReset || !sortAndFilterAreDefault || !datesAreDefault) {
                $scope.isResettingFilters = true;
                $scope.ddSortClick($scope.ddSortOptions[0]);
                $scope.ddSortSelected = $scope.ddSortOptions[0];
                $scope.ddFilterClick($scope.ddFilterOptions[0]);
                $scope.ddFilterSelected = $scope.ddFilterOptions[0];
                $scope.filters.startDate = undefined;
                $scope.filters.endDate = undefined;
                dateRangePicker.updateDateRangePicker({ startDate: undefined, endDate: undefined });
                $scope.search = { 
                    collaborators: [], 
                    creators: [], 
                    brandings: [], 
                    departments: [], 
                    language: [], 
                    jobCategories: [], 
                    aldeliaHubspotCompanies: [], 
                    countries: [],
                    campaignStatus: [],
                };
                let requestBody = {
                    filters: {
                        dashboardCampaigns: {
                            ...$scope.search,
                            sort: $scope.ddSortSelected,
                            filter: $scope.ddFilterSelected,
                            startDate: undefined,
                            endDate: undefined,
                        }
                    }
                };
        
                Server.patch(`users/${$rootScope.user._id}/settings`, requestBody)
                .then(function(response) {
                    $scope.isResettingFilters = false;
                }, function(error) {
                    console.error("Error when saving filter: ", error);
                    $scope.isResettingFilters = false;
                });
            }     
            if ($rootScope.user?.settings?.filters) {
                $rootScope.user.settings.filters.dashboardCampaigns = {
                    collaborators: [],
                    creators: [],
                    brandings: [],
                    departments: [],
                    language: [],
                    jobCategories: [],
                    aldeliaHubspotCompanies: [],
                    countries: [],
                    campaignStatus: [],
                    sort: $scope.ddSortOptions[0].value,
                    filter: $scope.ddFilterOptions[0].value,
                    startDate: null,
                    endDate: null,
                };
            }
        }

        $scope.isAnyFilterActive = function() {
            let isSearchFilterActive = Object.keys($scope.search).some(key => {
                let isActive = Array.isArray($scope.search[key]) && $scope.search[key].length > 0;
                return isActive;
            });
            let isSortOrFilterActive = $scope.ddSortSelected.value !== $scope.ddSortOptions[0].value || $scope.ddFilterSelected.value !== $scope.ddFilterOptions[0].value;
            let areDatesActive = ($scope.filters.startDate && $scope.filters.startDate.isValid()) || ($scope.filters.endDate && $scope.filters.endDate.isValid());
            let isActive = isSearchFilterActive || isSortOrFilterActive || areDatesActive;

            return isActive;
        };
        
        

        // Watcher for filters changes
        const filterKeys = ['collaborators', 'creators', 'brandings', 'departments', 'language', 'jobCategories', 'aldeliaHubspotCompanies', 'countries', 'campaignStatus'];

        function watchFilterChanges(filterKey) {
            $scope.$watch(`search.${filterKey}`, function(newValue, oldValue) {
                if (!angular.equals(newValue, oldValue)) {
                    $scope.debounceSaveFilters();
                }
            }, true);
        }

        $scope.$watch('ddSortSelected', function(newValue, oldValue) {
            if (newValue !== oldValue) {
                $scope.debounceSaveFilters();
            }
        }, true);
        
        $scope.$watch('ddFilterSelected', function(newValue, oldValue) {
            if (newValue !== oldValue) {
                $scope.debounceSaveFilters();
            }
        }, true);

        $scope.$watch('filters.startDate', function(newValue, oldValue) {
            if (newValue !== oldValue) {
                $scope.debounceSaveFilters();
            }
        }, true);
        
        $scope.$watch('filters.endDate', function(newValue, oldValue) {
            if (newValue !== oldValue) {
                $scope.debounceSaveFilters();
            }
        }, true);
        
        filterKeys.forEach(watchFilterChanges);

        $scope.debounceSaveFilters = (function() {
            var timer = null;
            return function() {
                if ($scope.initializing || $scope.isResettingFilters) {
                    return;
                }
                $timeout.cancel(timer);
                timer = $timeout(function() {
                    $scope.saveFilters.apply(this,arguments);
                }, 2000);
            };
        })();

        $scope.initializing = true;

        function initializeSavedFilters() {

            var responseData = $rootScope.user.settings.filters || {};
            var dashboardCampaignsFilters = responseData.dashboardCampaigns ? responseData.dashboardCampaigns[0] : null;
            if (dashboardCampaignsFilters && $scope.ddSortOptions && $scope.ddFilterOptions) {
                $scope.search.collaborators = dashboardCampaignsFilters.collaborators || [];
                $scope.search.creators = dashboardCampaignsFilters.creators || [];
                $scope.search.brandings = dashboardCampaignsFilters.brandings || [];
                $scope.search.departments = dashboardCampaignsFilters.departments || [];
                $scope.search.language = dashboardCampaignsFilters.language || [];
                $scope.search.jobCategories = dashboardCampaignsFilters.jobCategories || [];
                $scope.search.aldeliaHubspotCompanies = dashboardCampaignsFilters.aldeliaHubspotCompanies || [];
                $scope.search.countries = dashboardCampaignsFilters.countries || [];
                $scope.search.campaignStatus = dashboardCampaignsFilters.campaignStatus || [];
                $scope.ddSortSelected = $scope.ddSortOptions.find(option => option.value === dashboardCampaignsFilters.sort) || $scope.ddSortOptions[0];
                $scope.ddFilterSelected = $scope.ddFilterOptions.find(option => option.value === dashboardCampaignsFilters.filter) || $scope.ddFilterOptions[0];
                $scope.filters.startDate = dashboardCampaignsFilters.startDate ? moment(dashboardCampaignsFilters.startDate) : null;
                $scope.filters.endDate = dashboardCampaignsFilters.endDate ? moment(dashboardCampaignsFilters.endDate) : null;
                if ($scope.filters.startDate && $scope.filters.endDate && $scope.filters.startDate.isValid() && $scope.filters.endDate.isValid()) {     
                    dateRangePicker.updateDateRangePicker({ startDate: $scope.filters.startDate, endDate: $scope.filters.endDate });     
                }
            }
           
    
            $timeout(function () {
                $scope.readyTobuildMultiSelectln = true;
                $scope.initializing = false;
            }, 0);
        }
    
        // Initializing saved filters
        $timeout(function() {
            initializeSavedFilters();
        });

        // for languages
        $scope.allLanguages = Translate.getLangDropdownObject().map(l => ({ id: l.value, label: l.full })); //  [ {id: 0, label: "Anglais"}, {id: 1, label: "Français"}, ...]
        // Because "arraySettings" must be used when selecting strings without id (like countries above)
        $scope.multiselectSettingsln = { ...multiSelectln.objectSettings, idProperty: 'id' };
        $scope.multiselectSettingsJobCategoryln = { ...multiSelectln.objectSettings, idProperty: '_id' };
        $scope.multiselectSettingsDepartmentsln = { ...multiSelectln.objectSettings, idProperty: 'id', displayProp: "name" };
        $scope.multiselectSettingsCountriesln = { ...multiSelectln.objectSettings, idProperty: 'id'};
        $scope.multiselectSettingsCampaignStatusln = { ... multiSelectln.objectSettings, idProperty: '_id'};
        $scope.multiselectTextsln = multiSelectln.texts;
        $scope.readyTobuildMultiSelectln = false;
        $scope.multiselectTextsAldeliaHubspotCompany = { ...multiSelectln.texts, buttonDefaultText: Translate.getLangString('client')};
        $scope.multiselectTextsCollaborators = { ...multiSelectln.texts, buttonDefaultText: Translate.getLangString('collaborators_abbreviated')};
        $scope.multiselectTextsCreators = { ...multiSelectln.texts, buttonDefaultText: Translate.getLangString('creator')};
        $scope.multiselectTextsBranding = { ...multiSelectln.texts, buttonDefaultText: Translate.getLangString('brand')};
        $scope.multiselectTextsJobCategory = { ...multiSelectln.texts, buttonDefaultText: Translate.getLangString('job_category')};
        $scope.multiselectTextsDepartments = { ...multiSelectln.texts, buttonDefaultText: Translate.getLangString('campaign_sort_user_group')};
        $scope.multiselectTextsCountries = { ...multiSelectln.texts, buttonDefaultText: Translate.getLangString('country')}
        $scope.multiselectTextsCampaignStatus = { ...multiSelectln.texts, buttonDefaultText: Translate.getLangString('status')};
        $scope.readyTobuildMultiSelectJobCategory = false;
        
        $scope.collaboratorsOptions = [];
        $scope.aldeliaHubspotCompanyOptions = [];
        $scope.brandingOptions = [];
        $scope.jobCategoryOptions = [];
        $scope.countriesOptions = [];
        $scope.campaignStatusOptions = [];

        $scope.ddSortOptions = [
            {
                text: Translate.getLangString('default'),
                value: 'default',
                property: ['isActive', 'created'],
                reverse: true
            },
            {
                text: Translate.getLangString('campaign_sort_by_recent'),
                value: 'recent',
                property: 'created',
                reverse: true
            },
            {
                text: Translate.getLangString('campaign_sort_by_candidates'),
                value: 'totalcandidates',
                property: 'candidatesStats.active',
                reverse: true
            },
            {
                text: Translate.getLangString('campaign_sort_by_alphabetic'),
                value: 'alphabetic',
                property: c => Util.getDisplayedTitle(c, $rootScope.user).toLowerCase(),
                reverse: false
            }
        ];

        $scope.filters = {
            startDate: undefined,
            endDate: undefined,
        };

        $scope.onDropdownSelect = function(selected, defaultValue, dropdownId) {
            if (selected.value === defaultValue) {
                $('#'+dropdownId).removeClass('filter__item--selected');
            } else {
                $('#'+dropdownId).addClass('filter__item--selected');
            }
        }
        $scope.ddSortSelected = $scope.ddSortOptions[0];
        $scope.ddSortClick = function (selected) {
            const selectedOption = $scope.ddSortOptions.find(x => x.value === selected.value) || $scope.ddSortOptions[0];
            $rootScope.campaignSortProperty = selectedOption.property;
            $rootScope.campaignSortReverse = selectedOption.reverse;

            $scope.onDropdownSelect(selectedOption, $scope.ddSortOptions[0].value, 'dropdownSort');
        };
        $scope.ddSortClick($scope.ddSortOptions[0]);

        $scope.ddFilterOptions = [
            {
                text: Translate.getLangString('campaign_filter_active'),
                value: 'active'
            },
            {
                text: Translate.getLangString('campaign_filter_archived'),
                value: 'archived'
            },
            {
                text: Translate.getLangString('campaign_filter_all'),
                value: 'all'
            },
        ];
        $scope.ddFilterSelected = $scope.ddFilterOptions[0];
        $scope.ddFilterClick = function (selected) {
            $scope.onDropdownSelect(selected.value, $scope.ddFilterOptions[0].value, 'dropdownFilter')
        };

        $scope.infiniteScrollStep = (Math.floor(window.innerWidth /1200) || 1) * 24;
        $scope.campaignsLimit = $scope.infiniteScrollStep;
        $scope.onInfiniteScroll = function () {
            if ($rootScope.campaigns && $rootScope.campaigns.length > $scope.campaignsLimit) {
                $scope.campaignsLimit += $scope.infiniteScrollStep;
            }
        }

        function updateMultiselectButtonTextln() {
            $scope.multiselectTextsln.buttonDefaultText = Translate.getLangString('career_search_language');
            $scope.readyTobuildMultiSelectln = false;
            $timeout(function () {
                $scope.readyTobuildMultiSelectln = true;
            }, 10);
        }

        $scope.trackNewCampaign = function () {
            EventTracker.trackCampaignNew();
        }
        $scope.trackCampaignClick = function() {
            EventTracker.trackCampaignCandidates();
        }

        $scope.filterFn = function (campaign) {
            return textFilter(campaign)
                && collaboratorFilter(campaign)
                && creatorFilter(campaign)
                && brandingFilter(campaign)
                && departmentFilter(campaign)
                && statusFilter(campaign)
                && typeFilter(campaign)
                && languageFilter(campaign)
                && jobCategoryFilter(campaign)
                && aldeliaHubspotCompanyFilter(campaign)
                && dateFilter(campaign)
                && countryFilter(campaign)
                && campaignStatusFilter(campaign);
        };

        function textFilter(campaign) {
            return Util.userSearch([
                campaign.privateTitle,
                (campaign.location && campaign.location.name) || '',
                campaign.inviteKey,
                campaign.jobCategories.map(question => question.label).join(' '),
                (campaign.details && campaign.details.additionalInfo) || '',
            ], [
                campaign.title,
                campaign.fullDescription,
            ], 
            $scope.searchField);
        }

        function statusFilter(campaign) {
            switch ($scope.ddFilterSelected.value) {
                case 'all':
                    return true;
                case 'active':
                    return campaign.isActive;
                case 'archived':
                    return !campaign.isActive;
            }
        }

        function typeFilter(campaign) {
            return campaign.type !== 'hiddenCampaign';
        }

        function languageFilter(campaign) {
            var checkedLanguagesIds = _.map($scope.search.language, 'id');
            if (!checkedLanguagesIds || !checkedLanguagesIds.length)
                return true;
            return _.includes(checkedLanguagesIds, campaign.language);
        }

        function collaboratorFilter(campaign) {
            if ($scope.search.collaborators.length === 0) {
                return true;
            }
            return $scope.search.collaborators.some(filter => {
                return _.includes(campaign.collaborators, filter._id);
            });
        }

        function creatorFilter(campaign) {
            if ($scope.search.creators.length === 0) {
                return true;
            }
            return $scope.search.creators.some(filter => {
                return campaign.createdBy === filter._id;
            });
        }

        function brandingFilter(campaign) {
            if ($scope.search.brandings.length === 0) {
                return true;
            }
            return $scope.search.brandings.some(filter => {
                return campaign.customization
                    && campaign.customization.employerBrandingId
                    && campaign.customization.employerBrandingId._id === filter._id;
            });
        }

        function departmentFilter(campaign) {
            if ($scope.search.departments.length === 0) {
                return true;
            }
            return $scope.search.departments.some(filter => {
                return campaign.departmentIds
                    && campaign.departmentIds.includes(filter._id);
            });
        }

        function jobCategoryFilter(campaign) {
            return $scope.search.jobCategories.every(filter => campaign.jobCategories.find(jobCategory => filter._id === jobCategory._id))
        }

        function aldeliaHubspotCompanyFilter(campaign) {
            if ($scope.search.aldeliaHubspotCompanies.length === 0) {
                return true;
            }
            return _.some($scope.search.aldeliaHubspotCompanies, filter => {
                return campaign?.clientSpecificFields?.aldelia?.hubspotCompany?.id === filter.id
            });
        }

        function countryFilter(campaign) {
            // If no countries are selected, return true for all campaigns to show all of them.
            if ($scope.search.countries.length === 0) {
                return true;
            }
        
            // Check if the "Worldwide" filter is selected among the chosen countries.
            const isWorldwideSelected = _.some($scope.search.countries, { id: "Worldwide" });

            // Check if the campaign's country matches any of the selected countries in the filter.
            const isCountryMatch = _.some($scope.search.countries, filter => {
                return campaign?.location?.country === filter.id;
            });
        
            /* Return true if any of the following conditions are met:
                1. The campaign is marked as worldwide, and either "Worldwide" is selected or any country is selected.
                2. The campaign's country directly matches one of the selected countries.
                3. The campaign has no country defined and the "NoCountry" filter is selected. 
            */
            return (campaign.location && campaign.location.isWorldwide && 
                   (isWorldwideSelected || $scope.search.countries.length > 0)) ||
                   isCountryMatch ||
                   (_.some($scope.search.countries, { id: "NoCountry" }) && !campaign.location?.country && !campaign.location?.isWorldwide);
        }

        function campaignStatusFilter(campaign) {
            if ($scope.search.campaignStatus.length === 0) {
                return true;
            }
        
            return $scope.search.campaignStatus.some(filter => {
                if (!filter._id) {
                    // Filter for campaigns with no status
                    return !campaign.campaignStatusId;
                }
                return campaign.campaignStatusId === filter._id;
            });     
        }
        

        function dateFilter(campaign) {
            if (!$scope.filters.startDate?.isValid() || !$scope.filters.endDate?.isValid() || !campaign.created) {
                return true;
            }
            let campDate = moment(campaign.created);
            return $scope.filters.startDate.isBefore(campDate) && $scope.filters.endDate.isAfter(campDate);
        }

        componentCache.applyCache($scope.filterCacheKey, $scope);
        $scope.$on('$destroy', function() {
            const properties = [
                'filters.startDate',
                'filters.endDate',
                'search.collaborators',
                'search.creators',
                'search.brandings',
                'search.departments',
                'search.language',
                'search.jobCategories',
                'search.aldeliaHubspotCompanies',
                'search.countries',
                'search.campaignStatus',
                'searchField',
                'ddSortSelected',
                'ddFilterSelected',
            ];
            componentCache.persistCache($scope.filterCacheKey, $scope, properties);
        });

        dateRangePicker.initDatePicker($scope.filters, { useLongRange: true }, () => $scope.$apply());
        $rootScope.$on('campaignsList.onCampaignsData', $scope.onCampaignsData);

        let url;
        let loadIgbStatBadge = 
            $rootScope.fns.hasUiSettings(['preLoadCampaignIgbStatus']) 
            && !$rootScope.fns.hasCampaignsSettings(['individual_notPreLoadCampaignIgbStatus'], null, false);
        if (loadIgbStatBadge) {
            url = `campaignsForCampaignsList?loadJobPostingPublicationStatus=lazy`;
        } else {
            url = `campaignsForCampaignsList`;
        }

        $scope.onCampaignsData = function() {
            overlaySpinner.hide('campaigns');

            updateMultiselectButtonTextln();

            let jobCategories = [];
            let brandings = [];
            let aldeliaHubspotCompanies = [];
            let countrySet = new Set();

            $rootScope.campaigns.forEach(function (campaign) {

                var d = new Date(campaign.created);
                d.setDate(d.getDate() + campaign.inviteExp);
                campaign.expiresText = d.toLocaleDateString();

                overlaySpinner.show('campaign', campaign._id);

                campaign.jobCategories.forEach(jobCategory => {
                    if (!jobCategories.find(c => c._id === jobCategory._id)) {
                        jobCategories.push(jobCategory);
                    }
                })

                if (campaign.customization && campaign.customization.employerBrandingId) {
                    if (!brandings.find(b => b._id === campaign.customization.employerBrandingId._id)) {
                        brandings.push({
                            id: campaign.customization.employerBrandingId._id,
                            _id: campaign.customization.employerBrandingId._id,
                            label: campaign.customization.employerBrandingId.name
                        });
                    }
                }

                if (campaign?.clientSpecificFields?.aldelia?.hubspotCompany?.id) {
                    aldeliaHubspotCompanies.push({
                        id: campaign.clientSpecificFields.aldelia.hubspotCompany.id,
                        name: campaign?.clientSpecificFields?.aldelia?.hubspotCompany?.name || campaign.clientSpecificFields.aldelia.hubspotCompany.id,
                    })
                }

                if($scope.ddCandidateActions) {
                    $scope.ddFilteredCandidateActions = $scope.ddCandidateActions.filter($scope.candidateActionsFilter);
                }

                let loadIgbStatBadge = 
                    $rootScope.fns.hasUiSettings(['preLoadCampaignIgbStatus'])
                    && !$rootScope.fns.hasCampaignsSettings(['individual_notPreLoadCampaignIgbStatus'], null, false);
                if (loadIgbStatBadge && campaign?.igb?.publicationStatus?.status) {
                    campaign.igbStatus = igbPublicationFactory.getStatus(campaign?.igb?.publicationStatus?.status);
                }
                if (loadIgbStatBadge && campaign?.talentplug?.publicationStatus?.status) {
                    campaign.talentplugStatus = talentplugPublicationFactory.getStatus(campaign?.talentplug?.publicationStatus.status);
                }
                if (campaign.location && campaign.location.country) {
                    countrySet.add(campaign.location.country);
                }

                let jobCategoryTooltip = ''
                for (let jobCategory of campaign.jobCategories.slice(1)) {
                    jobCategoryTooltip += `<div>${jobCategory.label}</div>`;
                }
                campaign.jobCategoriesTooltip = jobCategoryTooltip;

                overlaySpinner.hide('campaign', campaign._id);
            });

            let uniqueCountries = Array.from(countrySet).map(country => ({
                id: country,
                label: country
            }));

            setCountriesOptions(uniqueCountries);
            setAldeliaHubspotCompanyOptions(aldeliaHubspotCompanies);
            setJobCategoryOptions(jobCategories);
            setBrandingOptions(brandings);
            $scope.ddSortClick($scope.ddSortSelected);
            $scope.ddFilterClick($scope.ddFilterSelected);
        }

        function setCreatorsOptions() {
            const creatorsIds = $rootScope.campaigns.map(c => c.createdBy);
            $scope.creatorsOptions = $scope.collaboratorsOptions.filter(x => creatorsIds.includes(x.id));
        }
        function setCollaboratorsOptions(collaborators) {
            $scope.collaboratorsOptions = _.chain(collaborators) 
                .map(collaborator => { 
                    return _.extend({}, collaborator, {
                        id: collaborator._id,
                        label: Util.userFullName(collaborator),
                    });
                })
                .sort((a, b) => a.label > b.label ? 1 : a.label < b.label ? -1 : 0)
                .value();
        }
        function setAldeliaHubspotCompanyOptions(aldeliaHubspotCompanies) {
            $scope.aldeliaHubspotCompanyOptions = _.chain(aldeliaHubspotCompanies) 
                .map(aHCompany => { 
                    return _.extend({}, aHCompany, {
                        id: aHCompany.id,
                        label: aHCompany.name,
                    });
                })
                .sort((a, b) => a.label > b.label ? 1 : a.label < b.label ? -1 : 0)
                .uniqBy('id')
                .value();
        }
        function setJobCategoryOptions(jobCategories) {
            $scope.jobCategoryOptions = jobCategories.sort((a, b) => a.label > b.label ? 1 : a.label < b.label ? -1 : 0);    
        }
        function setBrandingOptions(brandings) {
            $scope.brandingOptions = brandings.sort((a, b) => a.label > b.label ? 1 : a.label < b.label ? -1 : 0);
        }
        function setCountriesOptions(countries) {
            // Filter out "No Country" option
            let filteredCountriesOptions = countries.filter(country => country.id !== "NoCountry");
            // Sort alphabetically by label
            filteredCountriesOptions.sort((a, b) => a.label.localeCompare(b.label));
            // Add "No Country" option at the beginning of the list
            let noCountryOption = { id: "NoCountry", label: Translate.getLangString('no_country') };
            // Add "Worldwide" option at the beginning of the list
            let worldwideOptionExists = $rootScope.campaigns.some(campaign => campaign.location?.isWorldwide);
            if (worldwideOptionExists) {
                let worldwideOption = { id: "Worldwide", label: Translate.getLangString('worldwide_filter') };
                filteredCountriesOptions.unshift(worldwideOption);
            }
            filteredCountriesOptions.unshift(noCountryOption);
            $scope.countriesOptions = filteredCountriesOptions;
        }

        $scope.loadDepartments = async function() {
            if (!$rootScope.fns.hasCollaboratorsSettings(["useDepartments"])) {
                return;
            }
            if ($rootScope.departments && $rootScope.departments.length > 0) {
                const deferred = $q.defer();
                deferred.resolve($rootScope.departments);
                return deferred.promise;
            } else {
                try {
                    return Server.get('departments').then(departments => {
                        $rootScope.departments = departments;
                        return departments;
                    });
                } catch (err) {
                    ToasterService.failure(err, 'departments_load_error');
                    const deferred = $q.defer();
                    deferred.resolve([]);
                    return deferred.promise;
                }
            }
        };

        $scope.loadCampaignStatus = async function() {
            return Server.get('users/' + $rootScope.user._id + '/campaign-status')
            .then(campaignStatus => {
                $scope.campaignStatusOptions = [{
                    _id: null, 
                    label: Translate.getLangString('campaign_no_status_filter')
                }]
                .concat(campaignStatus);
            })
        }
      
        function loadAll() {
            overlaySpinner.show('campaigns');
            Server.get(url)
                .then(function ({campaigns, collaborators, totalContractActiveCampaigns}) {
                    $rootScope.campaigns = campaigns;
                    $rootScope.totalContractActiveCampaigns = totalContractActiveCampaigns;
                    $scope.onCampaignsData();
                    $scope.loadDepartments();
                    $scope.loadCampaignStatus();
                    setCollaboratorsOptions(collaborators);
                    setCreatorsOptions(collaborators);
                });
        }

        $scope.assignCampaignStatus = function(campaign) {
            PopupService.assignStatusToACampaign($scope, campaign)
                .then(campaignData => {
                    Object.assign(campaign, campaignData);
                });
        }

        $scope.filteredCampaigns = function() {
            if (!$rootScope.campaigns || $rootScope.campaigns.length === 0) {
                return [];
            }
            return $rootScope.campaigns.filter($scope.filterFn);
        };

        $scope.exportFilteredCampaignsInCSV = function() {
            let filteredCampaigns = $scope.filteredCampaigns();
            let campaignIds = filteredCampaigns.map(campaign => campaign._id);
            PopupService.exportCampaignsToCsv($scope, campaignIds);
        };

        loadAll();
        
        Onboarding.initWidget('dashboard-campaigns');
    }])
    
    .controller('CampaignEditorCtrl', ["$scope", "$rootScope", "$state", "$stateParams", "Server", "$timeout", "Util", "overlaySpinner", "Translate", "PopupService", "VideoClipUploader", "InterviewDocuments", "ToasterService", "Onboarding", "Upload", "EventTracker", "$q", function ($scope, $rootScope, $state, $stateParams, Server, $timeout, Util, overlaySpinner, Translate, PopupService, VideoClipUploader, InterviewDocuments, ToasterService, Onboarding, Upload, EventTracker, $q) {
        $scope.mainDocuments = [
            Translate.getLangString('document_type_cv'),
            Translate.getLangString('document_type_motivation_l')
        ];
        $scope.mainChecks = [];
        $scope.mainChecksOptional = [];
        $scope.otherCheck = false;
        $scope.others = [{ name: '' }];
        $scope.showAdvanced = false;
        $scope.introductionVideo = {
            text: '',
            category: 'Introduction video'
        };
        $scope.thankyouVideo = {
            text: '',
            category: 'Thankyou video'
        };
        $scope.videoPermission = {
            key: 'hasCustomVideos',
            upgradePlan: 'upgrd_company_video',
            text: Translate.getLangString('tooltip_upgrade_pro')
        };
        $scope.isCV = InterviewDocuments.isCV;
        Util.setPageTitle(Translate.getLangString('create_campaign_title'));
        $rootScope.secondaryNav = 'editcampaign';
        $rootScope.backEnabled = true;
        $rootScope.processNextText = Translate.getLangString('next');
        $rootScope.titleLimitSize = 128
        $rootScope.errors = {};
        $rootScope.resetLocation = function () {
            $rootScope.campaign.location = { name: $rootScope.campaign.location.name };
        }
        $scope.isNewCampaign = $stateParams.campaignId == 'new';

        $scope.contractTypes = [
            { value: '', text: '-' },
            { value: 'contractType_fixedTerm', text: Translate.getLangString('contractType_fixedTerm') },
            { value: 'contractType_permanent', text: Translate.getLangString('contractType_permanent') },
            { value: 'contractType_interim', text: Translate.getLangString('contractType_interim') },
            { value: 'contractType_internship', text: Translate.getLangString('contractType_internship') },
            { value: 'contractType_student', text: Translate.getLangString('contractType_student') },
            { value: 'contractType_freelance', text: Translate.getLangString('contractType_freelance') },
            { value: 'contractType_apprenticeship', text: Translate.getLangString('contractType_apprenticeship') },
            { value: 'contractType_replacement', text: Translate.getLangString('contractType_replacement') },
        ];
        $scope.remoteWorkOptions = [
            { value: '', text: '-' },
            { value: 'remoteWork_none', text: Translate.getLangString('remoteWork_none') },
            { value: 'remoteWork_partial', text: Translate.getLangString('remoteWork_partial') },
            { value: 'remoteWork_full', text: Translate.getLangString('remoteWork_full') },
        ];
        $scope.contractDurations = [
            { value: '', text: '-' },
            { value: 'contractDuration_fullTime', text: Translate.getLangString('contractDuration_fullTime') },
            { value: 'contractDuration_partTime', text: Translate.getLangString('contractDuration_partTime') },
            { value: 'contractDuration_0h-10h', text: Translate.getLangString('contractDuration_0h-10h') },
            { value: 'contractDuration_10h-20h', text: Translate.getLangString('contractDuration_10h-20h') },
            { value: 'contractDuration_20h-30h', text: Translate.getLangString('contractDuration_20h-30h') },
        ];
        $scope.aldeliaJobTypes = [
            { text: "-", value: "" },
            { text: Translate.getLangString("aldelia_job_type_perm"), value: "perm" },
            { text: Translate.getLangString("aldelia_job_type_out"), value: "out" },
        ];
        $scope.brandings = [];
        $scope.brandingsLoaded = false;
        
        const langsArray = Translate.getLangsArray();
        $scope.langsArray = langsArray;

        $scope.ddLangs = Translate.getLangDropdownObject();
        $scope.editLang = Translate.currentLanguage();

        $scope.changeEditLangLang = function (selected) {
            if (selected && typeof selected.value == 'number') {
                $scope.editLang = selected.value;
            }
        }

        $scope.onChangeCampaignQuestions = function(newQuestions) {
            $rootScope.campaign.questions = newQuestions;
        }

        var updateVideoIds = function () {
            if (!$rootScope.campaign)
                return;

            $scope.introductionVideo.videoClipId = $rootScope.campaign.introVideoClipId;
            $scope.thankyouVideo.videoClipId = $rootScope.campaign.thankYouVideoClipId;

            if (!$rootScope.campaign.videoTrials) {
                $rootScope.campaign.videoTrials = 0;
            }
            if (!$rootScope.questionsLanguage) {
                $rootScope.questionsLanguage = $rootScope.campaign.language;
            }
        };


        var loadDocuments = function () {

            if (!$rootScope.campaign)
                return;

            for (var i = 0; i < $scope.mainDocuments.length; ++i) {
                var hasDocOptional = ($rootScope.campaign.documents.indexOf($scope.mainDocuments[i]) >= 0);
                var hasDocRequired = ($rootScope.campaign.documents.indexOf('*' + $scope.mainDocuments[i]) >= 0);

                $scope.mainChecks[i] = (hasDocOptional || hasDocRequired);
                $scope.mainChecksOptional[i] = !hasDocRequired;
            }

            $scope.others = [];
            $rootScope.campaign.documents.forEach(function (doc) {
                var isRequired;
                if (doc.charAt(0) == '*') {
                    isRequired = true;
                    doc = doc.substr(1);
                }

                if ($scope.mainDocuments.indexOf(doc) == -1) {
                    $scope.others.push({ enabled: true, name: doc, optional: !isRequired });
                }
            });

            $scope.others.push({ enabled: false, name: '', optional: true });
        };

        var isValidQuestion = function (el) {
            return !(!el.text || !el.text.trim().length);
        };

        /**
         * @returns false if no question uses matching 
         */
        var validateAtLeastOneQuestionWithMatching = function () {
            $scope.clearEmptyQuestions();
            let usesAutomatedActions = 
                _.get($rootScope.campaign, 'automatedActions.matchingAbove.isActive') 
                || _.get($rootScope.campaign, 'automatedActions.matchingBelow.isActive');
            if(usesAutomatedActions) {
                return _.some($rootScope.campaign.questions, question => Util.matchingFcts.qUses(question));
            }
            return true;
        };

        var redirect = function (data) {
            var campaignId = $rootScope.campaign._id;
            delete $rootScope.campaign;
            overlaySpinner.hide('editcampaign');
            $state.go('candidates', { campaignId: campaignId });
        };

        function setVideoClips(campaign) {
            campaign.introVideoClipId = $scope.introductionVideo.videoClipId;
            campaign.thankYouVideoClipId = $scope.thankyouVideo.videoClipId;
        }

        /**
         * Deadline is always one day after the selected date (because the date is included)
         */
        function setDeadLine(campaign, selectedEarlyDateString) {
            campaign.deadline = campaign.deadline || {};
            if (!selectedEarlyDateString) {
                campaign.deadline.date = undefined;
                return;
            }
            let earlyDate = Util.getDateFromString(selectedEarlyDateString);
            let trueDeadLineDate = moment(earlyDate).add(1, 'days').toDate();
            campaign.deadline.date = trueDeadLineDate;
        }

        var updateCampaign = function (nextState) {

            setVideoClips($rootScope.campaign);
            if ($scope.selectedScorecardList) {
                $rootScope.campaign.scorecardsId = $scope.selectedScorecardList;
            }

            const overlay = overlaySpinner.show('editcampaign');

            return Server.post('campaigns/' + $rootScope.campaign._id, $rootScope.campaign)
                .then(function (campaign) {
                    const departments = $rootScope.campaign.departments;
                    $rootScope.campaign = campaign;
                    $rootScope.campaign.departments = departments;
                    $rootScope.campaign.departmentsNames = departments.map(department => department.name);
                    overlay.hide();
                    ToasterService.success('campaign_editor_toaster_saved')
                    postMessage({ name: 'reloadCampaign' });
                    if (nextState) {
                        $state.go(nextState, { campaignId: $rootScope.campaign._id });
                    }
                }).catch(err => {
                    overlay.hide();
                    ToasterService.failure(err, "err_0_error_occurred");
                });
        };

        var createCampaign = function (goNextStep) {

            setVideoClips($rootScope.campaign);
            
            const overlay = overlaySpinner.show('editcampaign');
            return Server.put('campaigns', $rootScope.campaign)
                .then(function (campaign) {
                    const departments = $rootScope.campaign.departments;
                    $rootScope.campaign = campaign;
                    $rootScope.campaign.departments = departments;
                    $rootScope.campaign.departmentsNames = departments.map(department => department.name);
                    overlay.hide();
                    if (goNextStep) {
                        $state.go('cc-documents', { campaignId: campaign._id });
                    } else {
                        ToasterService.success('campaign_editor_toaster_saved');
                        $state.go('cc-settings', { campaignId: campaign._id });
                    }
                })
                .catch(function (err) {
                    overlay.hide();
                    ToasterService.failure(err, 'err_34_error_creating_campaign');
                });
        };

        let assFirstPredModLoaded = false;
        $scope.clickShowAdvanced = function (show) {
            $scope.showAdvanced = show;
            const canAssessFirst = $rootScope.fns.hasPrivileges(['canAssessfirst', 'individual_canAssessfirst'], $rootScope.user);
            if (canAssessFirst && !assFirstPredModLoaded) {
                assFirstPredModLoaded = true;
                $timeout(function () {
                    $scope.refreshAssFirstPredMod();
                }, 10);
            }
        };

        $scope.refreshAssFirstPredMod = function () {
            if (!$rootScope.campaign._id || ($rootScope.campaign._id === 'new')) {
                return;
            }

            overlaySpinner.show('predictive-input');

            Server.get('assessfirst-integration/getAllPredictiveModels')
                .then(function (response) {
                    overlaySpinner.hide('predictive-input');
                    console.log({ response });
                    $scope.assFirstPredictiveModels = response;
                    $scope.assFirstPredictiveModels.unshift({ label: '---' }); // select it to unlink predictive model
                    let currentPredMod = _.find($scope.assFirstPredictiveModels, pm => pm.uuid === $rootScope.campaign?.assessFirst?.predictiveModel?.uuid);
                    if ($rootScope.campaign?.assessFirst?.predictiveModel) {
                        document.getElementById('predictive-input').value = currentPredMod.label;
                    }
                }, function (err) {
                    overlaySpinner.hide('predictive-input');
                    ToasterService.failure(err, 'err_0_error_occurred');
                });
        };

        $scope.onContentChanged = async function(editor, html, text, content, delta, oldDelta, source) {                                    
            // prevent Quill from making changes by reloading the page (sometimes Quill added bold in the text)
            if($scope.onUpdateTimeStamp) {
                let secondsSinceChange = moment.duration(moment().diff(moment($scope.onUpdateTimeStamp))).asSeconds();
                if (secondsSinceChange < 2 ) {
                    location.reload();
                }
            }
        };

        $scope.campaignGenerateWithAI = async function(inputType) {            
            const overlay = overlaySpinner.show('generate-description-with-ai-loading', null, 0);

            const contentTypes = ['description'];
            const contentType = contentTypes.filter(p => p === inputType)[0];
            if(!contentType) {
                overlay.hide();
                ToasterService.failure(new Error(`Content type '${inputType}' not available`), "err_0_error_occurred");
                return;
            }


            // Check if the campaign has the required information (job title) to generate the content
            const campaignExists = $rootScope.campaign && $rootScope.campaign.title;
            const langKey = campaignExists ? ($scope.editLang || $rootScope.campaign.language || '0') : undefined;
            const jobTitle = campaignExists && langKey !== undefined && $rootScope.campaign.title.hasOwnProperty(langKey) ? $rootScope.campaign.title[langKey] : undefined;

            if (!jobTitle) {
                overlay.hide();
                ToasterService.failure(null, "err_110_missing_job_title_for_AI_generation");
                return;
            }

            console.log('employerBranding', $rootScope.campaign);
            const context = {
                jobTitle: jobTitle,
                jobLocationName: $rootScope.campaign?.location?.name,
                companyDescription: $rootScope.campaign?.customization?.employerBranding?.companyInformation?.description[$scope.editLang] || $rootScope.campaign?.customization?.employerBranding?.companyInformation?.description[$rootScope.campaign?.language] || $rootScope.campaign?.customization?.employerBranding?.companyInformation?.description[Translate.currentLanguage()] || '',
                companyName: $rootScope.campaign?.customization?.employerBranding?.companyInformation?.name || '',
                language: Translate.getLangStringFromObj($scope.texts['lang_option_' + $scope.editLang || $rootScope.campaign?.language || 0]),
                contractType: Translate.getLangString($rootScope.campaign?.details?.contract?.type)
            }
   

            const content = Server.post('campaigns/generate/' + contentType, context)
                .then(function (response) {
                    overlay.hide();
                    $rootScope.campaign.description = {
                        ...$rootScope.campaign.description,
                        [$scope.editLang]: response.message
                    };
                }).catch(err => {
                    overlay.hide();
                    ToasterService.failure(err, "err_0_error_occurred");
                });
        }

        $scope.chooseAssFirstPredMod = function (selectedLabel) {

            if (!selectedLabel || (selectedLabel === $rootScope.campaign?.assessFirst?.predictiveModel?.label)) {
                return;
            }

            const confirmMessage = Translate.getLangString('settings_assessfirst_predictive_model_confirm');

            let skipConfirmation = true;
            if ($rootScope.campaign?.assessFirst?.predictiveModel) {
                skipConfirmation = false;
                let noCandidateHasAdequacy = _.every($rootScope.campaign?.candidates, candidate => {
                    return !candidate?.assessfirstUser?.adequacies;
                })
                if (noCandidateHasAdequacy) {
                    skipConfirmation = true;
                }
            }

            if (skipConfirmation || confirm(confirmMessage)) {
                overlaySpinner.show('campaign', $rootScope.campaign._id);
                let selectedPredictiveModel = $scope.assFirstPredictiveModels.find(i => i.label === selectedLabel);

                return Server.post(`assessfirst-integration/setPredictiveModel/${$rootScope.campaign._id}`, { predictiveModel: selectedPredictiveModel })
                    .then(function (newCampaign) {
                        $rootScope.campaign = newCampaign;
                        ToasterService.success('assessfirst_toaster_model_changed');
                        overlaySpinner.hide('campaign', $rootScope.campaign._id);
                    }, function (err) {
                        ToasterService.failure(err, 'err_100_assessfirst_model_not_changed');
                        overlaySpinner.hide('campaign', $rootScope.campaign._id);
                    });
            }
        };

        $scope.openVideoClips = function (data) {
            PopupService.openVideoClips($scope, data);
        };

        $scope.removeVideo = function (data) {
            data.videoClipId = null;
            ToasterService.warning('save_to_confirm');
        };

        $scope.uploadVideoClip = function (file, data) {
            if (!file) {
                //console.log('no file', file);
                return;
            }

            var metaData = {
                text: (file && file.name ? file.name : ''),
                category: data.category
            };
            //console.log(metaData);

            overlaySpinner.show('editcampaign');
            VideoClipUploader.uploadFromFile(file, metaData)
                .then(function (result) {
                    overlaySpinner.hide('editcampaign');
                    if (result.errorMessage) {
                        alert(result.errorMessage);
                    } else if (result._id) {
                        data.videoClipId = result._id;
                    }
                });
        };

        /**
         * Used to upload (or delete) the banner
         * @param  { file } file : if not a deletion, file to be used as customization
         */
         $rootScope.uploadBannerImage = function (file) {

            let resourceUrl = `campaigns/${$rootScope.campaign._id}`;
            let url = resourceUrl + '/bannerImage';

            if (!file) {
                console.log('error no file!', file);
                return;
            }

            overlaySpinner.show('editcampaign');
            Upload.upload({
                url: Server.makeUrl(url),
                data: {
                    file: file
                }
            }).then(function (resp) {
                /** Handle both cases campaign or user */
                overlaySpinner.show('editcampaign');
                Server.get(resourceUrl).then(function (data) {
                    overlaySpinner.hide('editcampaign');
                    $rootScope.campaign.customization = data.customization;
                }).catch(err => {
                    overlaySpinner.hide('editcampaign');
                });
            }, function (resp) {
                console.log('Error status: ' + resp.status);
            });
        };

        $scope.addDoc = function () {
            $scope.others.push({ name: '' });
            $scope.updateDocuments();
        };

        $scope.deleteDoc = function (index) {
            $scope.others.splice(index, 1);
            if ($scope.others.length == 0) {
                $scope.others.push({ name: '' });
            }
            $scope.updateDocuments();
        };

        $scope.$watch('introductionVideo.videoClipId', function (newValue) {
            // newValue is `undefined` when CampaignEditorCtrl instantiates introductionVideo at startup, and `null` when removeVideo is called to delete a video 
            if (!$rootScope.campaign || newValue === undefined)
                return;

            $rootScope.campaign.introVideoClipId = newValue;
        });

        $scope.$watch('thankyouVideo.videoClipId', function (newValue) {
             // newValue is `undefined` when CampaignEditorCtrl instantiates introductionVideo at startup, and `null` when removeVideo is called to delete a video 
            if (!$rootScope.campaign || newValue === undefined)
                return;

            $rootScope.campaign.thankYouVideoClipId = newValue;
        });

        $scope.docNameChange = function (doc) {
            doc.enabled = (!!(doc.name && doc.name.length));
            $scope.updateDocuments();
        };

        $scope.updateDocuments = function () {

            $rootScope.campaign.documents = [];

            for (var i = 0; i < $scope.mainDocuments.length; ++i) {
                if ($scope.mainChecks[i]) {
                    var name = $scope.mainDocuments[i];
                    if (!$scope.mainChecksOptional[i]) {
                        name = '*' + name;
                    }
                    $rootScope.campaign.documents.push(name);
                }
            }

            var hasEmpty = false;

            $scope.others.forEach(function (doc) {
                if (doc.name && doc.enabled) {
                    if (!doc.optional) {
                        $rootScope.campaign.documents.push('*' + doc.name);
                    } else {
                        $rootScope.campaign.documents.push(doc.name);
                    }
                } else {
                    hasEmpty = true;
                }
            });

            if (!hasEmpty) {
                $scope.others.push({ enabled: false, name: '', optional: true });
            }

            //console.log($rootScope.campaign.documents)
        };

        $scope.changeLanguage = function () {
            $rootScope.campaign.language = langsArray.indexOf($rootScope.campaign.languageString);
            $scope.editLang = $rootScope.campaign.language;
            $rootScope.questionsLanguage = $rootScope.campaign.language;

            if ($rootScope.campaign.language < 0) {
                $rootScope.campaign.language = ($rootScope.user.language > 0 ? $rootScope.user.language : 0);
            }
        };

        $scope.clearEmptyQuestions = function () {
            $rootScope.campaign.questions = $rootScope.campaign.questions.filter(isValidQuestion);
            $rootScope.campaign.questions.forEach(function (question) {
                if (question.isWritten && (question.mode == 1 || question.mode == 2)) {
                    if (!question.predefinedAnswers || question.predefinedAnswers.length == 0) {
                        question.mode = 0;
                    } else {
                        question.predefinedAnswers = question.predefinedAnswers.filter(function (answer) {
                            return (answer && answer.text && answer.text.length > 0 && answer.text.trim().length > 0);
                        });
                        if (question.predefinedAnswers.length === 0) {
                            question.mode = 0;
                        }
                    }
                }
            });
        };

        $scope.loadJobCategories = async function() {
            let assignedCategories = [];
            if ($scope.jobCategories) {
                assignedCategories.push(...$scope.jobCategories.filter(m => m.assigned));
            }
            const userJobCategories = await Server.get('users/' + $rootScope.user.id + '/job-categories');
            // call could maybe be avoided by directly using campaign.jobCategories
            const campaignJobCategories = $rootScope.campaign._id !== "new"
                ? await Server.get('campaigns/' + $rootScope.campaign._id + '/job-categories')
                : assignedCategories;

            const jobCategories = [];
            for (let jobCategory of userJobCategories) {
                const existing = jobCategories.find(cat => cat._id === jobCategory._id)
                if (!existing) {
                    jobCategories.push({
                        ...jobCategory,
                        assigned: false,
                        isUserJobCategory: true
                    });
                }
            }
            for (let jobCategory of campaignJobCategories) {
                const existing = jobCategories.find(cat => cat._id === jobCategory._id)
                if (!existing) {
                    jobCategories.push({
                        ...jobCategory,
                        assigned: true,
                    });
                } else {
                    existing.assigned = true;
                }
            }

            $scope.jobCategories = jobCategories;
        }

        $scope.loadScorecardsList = function() {
            return Server.get('users/' + $rootScope.user.id + '/scorecards-lists')
                .then((scorecardsList) => {
                    if (scorecardsList.length) {
                        $scope.userScorecardsList = scorecardsList;
                        if ($rootScope.campaign && $rootScope.campaign.selectedScorecardId) {
                            $scope.selectedScorecardList = $rootScope.campaign.selectedScorecardId;
                        }
                    } else {
                        $scope.userScorecardsList = [];
                    }
                })
                .catch(err => {
                    ToasterService.failure(err, 'scorecards_lists_load_failed');
                });
        };

        $scope.$watch('selectedScorecardList', function(newVal) {
            if ($rootScope.campaign) {
                $rootScope.campaign.selectedScorecardId = newVal ? newVal : null;
            }
        });
          
        
        $scope.unassignJobCategory = function(jobCategory) {
            function handleState() {
                if (!$rootScope.campaign.jobCategoriesIds) {
                    $rootScope.campaign.jobCategoriesIds = [];
                } else {
                    $rootScope.campaign.jobCategoriesIds = $rootScope.campaign.jobCategoriesIds.filter(assigned => assigned !== jobCategory._id)
                }
                jobCategory.assigned = false;
            }
            if ($rootScope.campaign._id === "new") {
                const deferred = $q.defer();
                handleState();
                deferred.resolve();
                return deferred.promise;
            } else {
                if ($rootScope.fns.hasCampaignsSettings(['jobCategoryMandatory'])) {
                    if ($rootScope.campaign.jobCategoriesIds.length === 1 && $rootScope.campaign.jobCategoriesIds[0] === jobCategory._id) {
                        $rootScope.errors.campaign_jobCategory = true;
                        return;
                    }
                }
                overlaySpinner.show('editcampaign');
                return Server.delete(`campaigns/${$rootScope.campaign._id}/job-categories/${jobCategory._id}`)
                    .then((_) => {
                        overlaySpinner.hide('editcampaign');
                        handleState();
                    })
                    .then($scope.loadJobCategories)
                    .catch(err => {
                        console.log(err);
                        overlaySpinner.hide('editcampaign');
                        ToasterService.failure(null, 'failed_to_assign_job_category');
                    });
            }
        }
        $scope.assignJobCategory = function(jobCategory) {
            function handleState() {
                if (!$rootScope.campaign.jobCategoriesIds) {
                    $rootScope.campaign.jobCategoriesIds = [];
                }
                $rootScope.campaign.jobCategoriesIds = new Array(...new Set([...$rootScope.campaign.jobCategoriesIds, jobCategory._id])).sort();
                jobCategory.assigned = true;
            }
            if ($rootScope.campaign._id === "new") {
                const deferred = $q.defer();
                handleState();
                deferred.resolve();
                return deferred.promise;
            } else {
                overlaySpinner.show('editcampaign');
                return Server.put(`campaigns/${$rootScope.campaign._id}/job-categories/${jobCategory._id}`)
                    .then((_) => {
                        overlaySpinner.hide('editcampaign');
                        handleState();
                    })
                    .then($scope.loadJobCategories)
                    .catch(err => {
                        console.log(err);
                        overlaySpinner.hide('editcampaign');
                        ToasterService.failure(null, 'failed_to_assign_job_category');
                    });
            }
        }
        $scope.addJobCategoryClick = async function() {
            PopupService.openCreateJobCategoryPopup($scope);
        }
        $scope.loadDepartments = async function() {
            if ($rootScope.departments && $rootScope.departments.length > 0) {
                const deferred = $q.defer();
                deferred.resolve($rootScope.departments);
                return deferred.promise;
            } else {
                try {
                    return Server.get('departments').then(departments => {
                        $rootScope.departments = departments;
                        return departments;
                    });
                } catch (err) {
                    ToasterService.failure(err, 'departments_load_error');
                    const deferred = $q.defer();
                    deferred.resolve([]);
                    return deferred.promise;
                }
            }
        };
        $scope.manageDepartments = async function() {
            const departments = await $scope.loadDepartments();
            const checkedDepartments = await PopupService.openAssignableData(
                $scope,
                {
                    modalTitle: Translate.getLangString('departments'),
                    sourceItem: $rootScope.campaign,
                    targets: departments,
                    getLabel: (target) => {
                        return target.name;
                    },
                    isAssigned: (source, target) => {
                        return source.departmentIds ? source.departmentIds.some(m => m === target._id) : false;
                    },
                    onAssign: (id) => {
                        if (!$stateParams.campaignId || $stateParams.campaignId == 'new') {
                            if (!$rootScope.campaign.departmentIds) {
                                $rootScope.campaign.departmentIds = []
                            }
                            if (!$rootScope.campaign.departmentIds.some(assignedId => assignedId === id)) {
                                EventTracker.trackCampaignAddUserGroup();
                                $rootScope.campaign.departmentIds.push(id);
                            }
                        } else {
                            return Server.post('campaigns/' + $stateParams.campaignId + '/assign-department/' + id);
                        }
                    },
                    onUnassign: (id) => {
                        if (!$stateParams.campaignId || $stateParams.campaignId == 'new') {
                            if (!$rootScope.campaign.departmentIds) {
                                $rootScope.campaign.departmentIds = []
                            }
                            $rootScope.campaign.departmentIds = $rootScope.campaign.departmentIds.filter(assignedId => assignedId !== id);
                        } else {
                            return Server.post('campaigns/' + $stateParams.campaignId + '/unassign-department/' + id);
                        }
                    },
                }
            );
            $rootScope.campaign.departmentIds = checkedDepartments.map(department => department._id);
            $rootScope.campaign.departments = checkedDepartments;
            $rootScope.campaign.departmentsNames = checkedDepartments.map(department => department.name);
            $scope.$apply();
        }

        $scope.hubspotDeals = {
            query: "",
            previousQuery: "",
            items: [],
            companies: [],
            hubspotContacts: [],
            selectedHubspotContact: {},
        };
        $scope.setHubspotFields = function() {
            if ($rootScope.user.settings.ui.userSpecificFields.includes('aldelia')) {
                if (!_.get($rootScope.campaign, "clientSpecificFields.aldelia")) {
                    _.set($rootScope.campaign, "clientSpecificFields.aldelia.hubspotDeal", null);
                    $scope.hubspotDeals = {
                        query: "",
                        previousQuery: "",
                        items: [],
                        companies: [],
                        hubspotContacts: [],
                        selectedHubspotContact: {},
                    };
                } else {
                    let aldeliaFields = $rootScope.campaign.clientSpecificFields.aldelia;
                    if (aldeliaFields.hubspotDeal) {
                        $scope.hubspotDeals.query = aldeliaFields.hubspotDeal.name;
                        $scope.hubspotDeals.items = [aldeliaFields.hubspotDeal];
                    }
                    if (aldeliaFields.hubspotContacts) {
                        $scope.campaign.hubspotContacts = aldeliaFields.hubspotContacts;
                    }
                    if (aldeliaFields.hubspotCompany) {
                        $scope.hubspotDeals.companies = [aldeliaFields.hubspotCompany];
                    }
                    if(!aldeliaFields.selectedHubspotContact) {
                        aldeliaFields.selectedHubspotContact = {};
                    }

                    if(aldeliaFields.hubspotContacts && aldeliaFields.selectedHubspotContact) {
                        let selectedContact = aldeliaFields.hubspotContacts.find(contact => contact.id === aldeliaFields.selectedHubspotContact.id);
                        if(selectedContact) {
                            aldeliaFields.selectedHubspotContact = selectedContact;
                        }
                    }
                }
            }
            
        }
        $scope.loadHubspotDealsOnChange = function() {
            $scope.loadHubspotDeals();
        }
        $scope.clearHubspotLink = function() {
            $rootScope.campaign.clientSpecificFields.aldelia.hubspotDeal = {};
            $rootScope.campaign.clientSpecificFields.aldelia.hubspotContacts = [];
            $rootScope.campaign.clientSpecificFields.aldelia.selectedHubspotContact = {};
            $rootScope.campaign.clientSpecificFields.aldelia.hubspotCompany = {};
            $scope.hubspotDeals.query = "";
            $scope.hubspotDeals.hubspotContacts = [];
            $scope.hubspotDeals.companies = [];
        }
        $scope.loadHubspotDeals = _.debounce(function() {
            
            if ($scope.hubspotDeals.items && $scope.hubspotDeals.items.length && $scope.hubspotDeals.query.length) {
                const selectedItem = $scope.hubspotDeals.items.find(i => i.id === $scope.hubspotDeals.query);
                if (selectedItem) {
                    $scope.setHubspotDeal(selectedItem);
                    return;
                }
            }
            if ($scope.hubspotDeals.query.length < 3) {
                return;
            }
            if ($scope.hubspotDeals.previousQuery && $scope.hubspotDeals.previousQuery.length > 3 && $scope.hubspotDeals.query.includes($scope.hubspotDeals.previousQuery)) {
                return;
            }

            $scope.hubspotDeals.previousQuery = $scope.hubspotDeals.query;
            const overlay = overlaySpinner.show('hubspot');
            Server.get("users/me/hubspot-deals?query="+$scope.hubspotDeals.query)
                .then(res => {
                    overlay.hide();
                    $scope.hubspotDeals.items = res;   
                    
                    if ($scope.hubspotDeals.items && $scope.hubspotDeals.items.length && $scope.hubspotDeals.query.length) {
                        const items = $scope.hubspotDeals.items.filter(deal => deal.id.toLowerCase().includes($scope.hubspotDeals.query.toLowerCase()) || deal.name.toLowerCase().includes($scope.hubspotDeals.query.toLowerCase()));
                        if (items.length === 1) {
                            $scope.setHubspotDeal(items[0]);
                            return;
                        }
                    }
                    
                    $rootScope.campaign.clientSpecificFields.aldelia.hubspotDeal = {};
                    $rootScope.campaign.clientSpecificFields.aldelia.hubspotContacts = undefined;
                    $rootScope.campaign.clientSpecificFields.aldelia.hubspotCompany = undefined;
                    $rootScope.campaign.clientSpecificFields.aldelia.selectedHubspotContact = undefined;
                }).catch(err => {
                    overlay.hide();
                    ToasterService.failure(err, "error_loading_hubspot_deals");
                })
        }, 300);

        $scope.refreshHubspotDealData = function() {
            const overlay = overlaySpinner.show('hubspot');
            const hubspotDealId = $scope.campaign.clientSpecificFields.aldelia.hubspotDeal.id;
            
            if (!hubspotDealId) {
                ToasterService.failure(null, "err_112_select_a_hubspot_deal_first");
                overlay.hide();
                return;
            }
        
            Server.get("users/me/hubspot-deals/" + hubspotDealId)
                .then(res => {
                    overlay.hide();
        
                    const hubspotDataToUpdate = {
                        hubspotContacts: res.contacts || [],
                        hubspotCompany: res.companies?.[0] || {}
                    };

                    // Check if the selectedHubspotContact exists before checking its ID 
                    const selectedContactId = $scope.campaign.clientSpecificFields.aldelia.selectedHubspotContact?.id;

                    // Check if the selected contact exists in the new HubSpot data 
                    const selectedContactExists = selectedContactId && hubspotDataToUpdate.hubspotContacts.some(contact => contact.id === selectedContactId);

                    if (!selectedContactExists) {
                        // If the selected contact doesn't exist anymore, remove it from the campaign
                        delete $scope.campaign.clientSpecificFields.aldelia.selectedHubspotContact;
                    }
        
                    // Update front with new HubSpot data
                    $scope.hubspotDeals.hubspotContacts = hubspotDataToUpdate.hubspotContacts;
                    $scope.hubspotDeals.companies = [hubspotDataToUpdate.hubspotCompany];
                
                    // If the campaign exist, goes here
                    // API Call to update campaign with new HubSpot data
                    if (!$scope.isNewCampaign) {                    
                    return Server.post(`campaigns/refresh-hubspot/${$rootScope.campaign._id}/${hubspotDealId}`, {
                        clientSpecificFields: {
                            aldelia: {
                                ...$rootScope.campaign.clientSpecificFields.aldelia,
                                ...hubspotDataToUpdate,
                                selectedHubspotContact: $rootScope.campaign.clientSpecificFields.aldelia.selectedHubspotContact
                            }
                        }
                        
                    });
                    } else {
                        // New campaign, since it doesn't exist yet, we have to update the fields that we got from our fetched msb data
                        $scope.campaign.clientSpecificFields.aldelia = {
                            ...$scope.campaign.clientSpecificFields.aldelia,
                            ...hubspotDataToUpdate
                        };
                    }
                })
                .then(response => {
                    if (response) {
                    $scope.hubspotDeals.hubspotContacts = response.contacts || [];
                    $scope.hubspotDeals.companies = response.companies || [];
                    $scope.campaign.clientSpecificFields.aldelia.selectedHubspotContact = response.data.clientSpecificFields.aldelia.selectedHubspotContact;
                    $scope.campaign.clientSpecificFields.aldelia.hubspotContacts = response.data.clientSpecificFields.aldelia.hubspotContacts;
                    $scope.campaign.clientSpecificFields.aldelia.hubspotCompany = response.data.clientSpecificFields.aldelia.hubspotCompany;
                }
                })
                .catch(err => {
                    overlay.hide();
                    console.error(err);
                    ToasterService.failure(null, "err_111_err_while_updating_hubspot_data");
                });
        };
        
        $scope.setHubspotDeal = function(hubspotDeal) {
            // Update selected deal
            $rootScope.campaign.clientSpecificFields.aldelia.hubspotDeal = hubspotDeal;
            $rootScope.campaign.clientSpecificFields.aldelia.hubspotDeal.id = hubspotDeal.id;
            $scope.hubspotDeals.query = hubspotDeal.name;
            // Update contacts & company based on the selected deal
            $rootScope.campaign.clientSpecificFields.aldelia.hubspotContacts = hubspotDeal.contacts;
            $rootScope.campaign.clientSpecificFields.aldelia.hubspotCompany = hubspotDeal.company;

            $scope.hubspotDeals.hubspotContacts = hubspotDeal.contacts;
            $scope.hubspotDeals.hubspotCompany = hubspotDeal.company;
            $scope.campaign.clientSpecificFields.aldelia.hubspotContacts = hubspotDeal.contacts;

            // New selected deal, let's save the new deal in the campaign's DB
            $scope.refreshHubspotDealData()
            if (!$scope.hubspotDeals.items.includes(hubspotDeal)) {
                $scope.hubspotDeals.items.push(hubspotDeal);
            }
        }

        function validateTitle () {

            const campaignLang = $rootScope.campaign.language;

            // a title must exist in the language of the campaign
            if (!$rootScope.campaign?.title?.[campaignLang]) {
                $rootScope.errors.title = true;
                $rootScope.errors.title_in_lang = true;
                $rootScope.jobTitleInLanguageErrorMsg = Translate.getLangString('job_title_in_lang')
                    .replace('...', Translate.getLangString('lang_option_' + campaignLang)),
                $scope.changeEditLangLang({value: campaignLang});
            }

            _.forEach(langsArray, (short, lang) => {
                if ($rootScope.campaign?.title?.[lang] && $rootScope.campaign?.title?.[lang]?.length > $rootScope.titleLimitSize) {
                    $rootScope.errors.title = true;
                }
            })

        }

        function validateDescription() {

            const campaignLang = $rootScope.campaign.language;

            // a description must exist in the language of the campaign
            if (!$rootScope.campaign?.description?.[campaignLang]) {
                $rootScope.errors.description = true;
                $rootScope.errors.description_in_lang = true;
                $rootScope.jobDescriptionInLanguageErrorMsg = Translate.getLangString('job_description_in_lang')
                    .replace('...', Translate.getLangString('lang_option_' + campaignLang)),
                $scope.changeEditLangLang({value: campaignLang});
                return;
            }

            _.forEach(langsArray, (short, lang) => {

                // a title must exist in the language of every description
                if ($rootScope.campaign?.description?.[lang] && !$rootScope.campaign?.title?.[lang]) {
                    $rootScope.errors.title_if_description = true;
                    $scope.changeEditLangLang({value: lang});
                }
                
                // a description must exist in the language of every title
                if ($rootScope.campaign?.title?.[lang] && !$rootScope.campaign?.description?.[lang]) {
                    $rootScope.errors.description = true;
                    $rootScope.errors.description_if_title = true;
                    $scope.changeEditLangLang({value: lang});
                }
                
                // all description must be over 20 character
                let description = $rootScope.campaign?.description?.[lang]?.trim();
                if (description && Util.countWords(description) < 20) {
                    $rootScope.errors.description = true;
                    $rootScope.errors.description_limit = true;
                    $scope.changeEditLangLang({value: lang});
                }
            });


        }

        function validateSettings() {

            $rootScope.errors = {};

            validateTitle();
            validateDescription();
            if ($rootScope.campaign.location?.isWorldwide && !($rootScope.campaign.location?.country)) {
                // If both conditions are true, reset the location name to an empty string, since the address is not a valid one
                $rootScope.campaign.location.name = '';
            }
            if ((!$rootScope.campaign.location || !$rootScope.campaign.location.name) && !$rootScope.campaign?.location?.isWorldwide) {
                $rootScope.errors.campaign_jobLocation = true;
            }
            if ($rootScope.user?.settings?.ui?.userSpecificFields?.includes('aldelia')
                && !$rootScope.campaign.clientSpecificFields.aldelia.jobType) { 
                _.set($rootScope.errors, 'clientSpecificFields.aldelia.jobType', true);
            }
            if ($rootScope.campaign.location 
                && $rootScope.campaign.location.name 
                && !$rootScope.campaign.location.country) { // if there is 'location.country' then it means that one of the Google Autocomplete has been selected
                $rootScope.errors.campaign_location_google = true;
            }
            if (!$rootScope.campaign.inviteExp || !($rootScope.campaign.inviteExp > 0)) {
                $rootScope.errors.inviteexp = true;
            }
            if ($rootScope.campaign.videoTrials != '0' 
                && ($rootScope.campaign.videoTrials 
                && !(parseInt($rootScope.campaign.videoTrials, 10)) > 0)) {
                $rootScope.errors.trials = true;
            }
            if ($rootScope.fns.hasCampaignsSettings(['jobCategoryMandatory']) && (!$rootScope.campaign.jobCategoriesIds || !$rootScope.campaign.jobCategoriesIds.length)) {
                $rootScope.errors.campaign_jobCategory = true;
            }
            return _.isEmpty($rootScope.errors);
        }

        switch ($state.current.name) {

            case 'cc-settings':

                Onboarding.initWidget('cc-settings');

                $rootScope.updateAndNext = function (goNextStep) {

                    setDeadLine($rootScope.campaign, $scope.deadline);

                    if (!validateSettings()) {
                        ToasterService.failure(null, 'err_1_form');
                        return;
                    }

                    if (!$stateParams.campaignId || $stateParams.campaignId == 'new') {

                        // is new campaign
                        createCampaign(goNextStep);

                    } else {

                        // existing campaign
                        if (goNextStep) {
                            updateCampaign('cc-documents')
                        } else {
                            updateCampaign()
                        }
                    }

                    // prevent Quill from making changes by reloading the page (sometimes Quill added bold in the text)
                    $scope.onUpdateTimeStamp = new Date();
                };

                break;

            case 'cc-documents':

                Onboarding.initWidget(null);

                $rootScope.updateAndNext = function (goNextStep) {

                    if (!validateSettings()) {
                        $state.go('cc-settings', { campaignId: $rootScope.campaign._id });
                        ToasterService.failure(null, 'err_1_form');
                        return;
                    }

                    if (goNextStep) {
                        updateCampaign('cc-questions');
                    } else {
                        updateCampaign();
                    }
                };

                break;

            case 'cc-questions':

                Onboarding.initWidget('cc-questions');

                $rootScope.updateAndNext = function (goNextStep) {

                    if (!validateSettings()) {
                        $state.go('cc-settings', { campaignId: $rootScope.campaign._id });
                        ToasterService.failure(null, 'err_1_form');
                        return;
                    }

                    if (goNextStep) {
                        updateCampaign('cc-automation');
                    } else {
                        updateCampaign();
                    }
                };

                break;


            case 'cc-automation':

                Onboarding.initWidget(null);
                $rootScope.processNextText = Translate.getLangString('campaign_editor_btn_done');

                $rootScope.updateAndNext = function (goNextStep) {
                    EventTracker.trackCampaignFinish();

                    if (!validateSettings()) {
                        $state.go('cc-settings', { campaignId: $rootScope.campaign._id });
                        ToasterService.failure(null, 'err_1_form');
                        return;
                    }
                    if (!validateAtLeastOneQuestionWithMatching()) {
                        ToasterService.warning('err_39b_at_least_one_question_with_matching');
                    }

                    if (goNextStep) {
                        updateCampaign()
                            .then(redirect);
                    } else {
                        updateCampaign();
                    }
                };

                break;
        }

        if ($stateParams.campaignId == 'new' || !$stateParams.campaignId) {
            $rootScope.campaign = {
                _id: 'new',
                location: $rootScope?.user?.address,
                questions: [],
                inviteExp: 60,
                videoTrials: 2,
                documents: ['*' + Translate.getLangString('document_type_cv')], // '*' is to make the CV non-optionnal by default
                language: ($rootScope.user ? $rootScope.user.language : 0),
                askReferralSource: true,
                departmentIds: [],
                departments: [],
            };

            $rootScope.campaign.languageString = langsArray[$rootScope.campaign.language || 0];
            if ($rootScope.user) {
                $scope.loadJobCategories();
                $scope.setHubspotFields();
                if ($rootScope.user.privileges.canUseScorecards || $rootScope.fns.userHasRights('campaigns.scorecards', 'edit')) { $scope.loadScorecardsList(); }
            } else {
                $rootScope.$on('onCurrentUser', (_, user) => {
                    $scope.loadJobCategories();
                    $scope.setHubspotFields();
                    if ($rootScope.user.privileges.canUseScorecards || $rootScope.fns.userHasRights('campaigns.scorecards', 'edit')) { $scope.loadScorecardsList(); }
                })
            }
        } else {
            if (!$rootScope.campaign || $rootScope.campaign._id != $stateParams.campaignId) {
                overlaySpinner.show('editcampaign');
                Server.get('campaigns/' + $stateParams.campaignId + '?departments=true').then(function (campaign) {
                    overlaySpinner.hide('editcampaign');
                    $scope.editLang = campaign.language;
                    $rootScope.campaign = campaign;
                    $rootScope.campaign.languageString = langsArray[(($rootScope.campaign && ($rootScope.campaign.language > 0)) ? $rootScope.campaign.language : 0)];
                    const campaignTitle = Util.getDisplayedTitleAndEmployerBranding($rootScope.campaign, $rootScope.user);
                    Util.setPageTitle(Translate.getLangString('campaign'), campaignTitle);
                    setEarlyDeadline();
                    loadDocuments();
                    updateVideoIds();
                    $scope.loadJobCategories();
                    if($scope.ensureCampaignBrandingAccessible) {
                        $scope.ensureCampaignBrandingAccessible();
                    }
                    $scope.setHubspotFields();
                }).catch(err => {
                    if (err.status === 404) {
                        $state.go('campaigns');
                        ToasterService.failure(err, 'load_campaign_not_found');
                    }
                    else {
                        ToasterService.failure(err, 'err_0_error_occurred');
                    }
                });
            }
            if ($rootScope.campaign) {
                $scope.loadJobCategories();
                if ($rootScope.user.privileges.canUseScorecards || $rootScope.fns.userHasRights('campaigns.scorecards', 'edit')) { $scope.loadScorecardsList(); }
                
                if($scope.ensureCampaignBrandingAccessible) {
                    $scope.ensureCampaignBrandingAccessible();
                }
            }
            // In case campaign arrives later
            $scope.$watch('$root.campaign', function () {
                if ($rootScope.campaign) {
                    $scope.editLang = $rootScope.campaign.language;
                    $rootScope.campaign.languageString = langsArray[(($rootScope.campaign && ($rootScope.campaign.language > 0)) ? $rootScope.campaign.language : 0)];
                    const campaignTitle = Util.getDisplayedTitleAndEmployerBranding($rootScope.campaign, $rootScope.user);
                    Util.setPageTitle(Translate.getLangString('campaign'), campaignTitle);
                    setEarlyDeadline();
                    setAutomatedActionEmail();
                    loadDocuments();
                    updateVideoIds();
                    $scope.setHubspotFields();
                    $scope.loadJobCategories();
                    if ($rootScope.user.privileges.canUseScorecards || $rootScope.fns.userHasRights('campaigns.scorecards', 'edit')) { $scope.loadScorecardsList(); }
                    if($scope.ensureCampaignBrandingAccessible) {
                        $scope.ensureCampaignBrandingAccessible();
                    }
                }
            });
        }

        $scope.ensureCampaignBrandingAccessible = function() {
            if ($rootScope.campaign && $scope.brandingsLoaded && $rootScope.campaign.customization && $rootScope.campaign.customization.employerBrandingId) {
                if (!$scope.brandings.find(eb => eb.value === $rootScope.campaign.customization.employerBrandingId)) {
                    $scope.brandings.push({
                        value: $rootScope.campaign.customization.employerBrandingId,
                        text: `[ ${Translate.getLangString("restricted_branding")} ]`
                    });
                }
            }
        }
        
        Server.get('employer-brandings')
            .then(res => {
                $scope.brandings.push(...res.map(branding => ({ value: branding.id, text: branding.name, isMainEB: branding.isMainEB })));
                $scope.brandingsLoaded = true;
                if ($scope.brandings.length > 0 && $rootScope.campaign) {
                    if (!$rootScope.campaign.customization) {
                        $rootScope.campaign.customization = {}
                    }
                    if (!$rootScope.campaign.customization.employerBrandingId) {
                        const mainBranding = $scope.brandings.find(b => b.isMainEB)
                        $rootScope.campaign.customization.employerBrandingId = (mainBranding ?? $scope.brandings[0]).value;
                    }
                }

                $scope.ensureCampaignBrandingAccessible();
            })
            .catch(err => {
                ToasterService.failure(err, 'load_brandings_error');
            })

        function setEarlyDeadline() {
            if ($rootScope.campaign?.deadline?.date) {
                let trueDeadLineDate = $rootScope.campaign.deadline.date;
                let earlyDate = moment(trueDeadLineDate).subtract(1, 'days').toDate();
                $scope.initialExpirationDate = earlyDate.toString();
            }
        }

        $scope.uploadLinkedDocument = function (file) {
            let url = 'campaigns/' + $rootScope.campaign._id + '/linkedDocument';
            if (!file) {
                console.log('error no file!', file);
                return;
            }
            overlaySpinner.show('editcampaign');

            Upload.upload({
                url: Server.makeUrl(url),
                data: {
                    file: file
                }
            }).then(function (resp) {
                overlaySpinner.hide('editcampaign');
                console.log('Success ' + resp.config.data.file.name + ' uploaded. Response: ' + resp.data);
                $rootScope.campaign.linkedDocuments = resp.data.linkedDocuments;
            }, function (resp) {
                overlaySpinner.hide('editcampaign');
                console.log('Error status: ' + resp.status);
                ToasterService.failure(resp, 'err_38_save_campaign_before_uploading_hero');
            }, function (evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
            });
        };
        
        $scope.deleteLinkedDocument = function (index) {
            Server.delete(`campaigns/${$rootScope.campaign._id}/linkedDocument/${index}`)
            .then(function (campaign) {
                $rootScope.campaign.linkedDocuments = campaign.linkedDocuments;
            })
            .catch(function (err) {
                ToasterService.failure(err, 'err_0_error_occurred');
            });
        };
        
        $scope.downloadLinkedDocument = function (index) {
            let doc = $rootScope.campaign.linkedDocuments[index];
            var a = document.createElement('a');
            a.style.display = 'none';
            a.href = Server.makeResourceUrl(doc.filename);
            a.download = doc.originalname;
            document.body.appendChild(a);
            a.click();
            setTimeout(function () {
                document.body.removeChild(a);
            }, 100);
        };
        
        // 
        //  Automated Actions (step 4)
        //

        $scope.trackSuperMatch = function() {
            EventTracker.trackCampaignSuperMatch();
        }
        $scope.trackThreshold = function() {
            EventTracker.trackCampaignThreshold();
        }
        function setAutomatedActionEmail() {
            if($scope.campaign?.automatedActions?.matchingAbove?.action?.sendmessage?.templateKey == 'super-match') {
                $scope.matchingAboveEmail = true;
            }
            if($scope.campaign?.automatedActions?.matchingBelow?.action?.sendmessage?.templateKey == 'reject') {
                $scope.matchingBelowEmail = true;
            }
        }
        $scope.$watch('campaign.automatedActions.matchingAbove.isActive', function() {
            if(!$scope.campaign?.automatedActions?.matchingAbove?.scorePercent) {
                _.set($scope.campaign, 'automatedActions.matchingAbove.scorePercent', 75); // default value, also in the placeholder
            }
            if(!$scope.campaign?.automatedActions?.matchingAbove?.isActive && $scope.matchingAboveEmail) {
                $scope.matchingAboveEmail = false;
            }
        });
        $scope.$watch('matchingAboveEmail', function() {
            if($scope.matchingAboveEmail) {
                _.set($rootScope.campaign, 'automatedActions.matchingAbove.action', {
                    sendmessage: {templateKey: 'super-match'},
                }); 
            } else {
                _.set($rootScope.campaign, 'automatedActions.matchingAbove.action', undefined);
            }
        });
        $scope.$watch('campaign.automatedActions.matchingBelow.isActive', function() {
            if(!$scope.campaign?.automatedActions?.matchingBelow?.scorePercent) {
                _.set($scope.campaign, 'automatedActions.matchingBelow.scorePercent', 25); // default value, also in the placeholder
            }
            if(!$scope.campaign?.automatedActions?.matchingBelow?.isActive && $scope.matchingBelowEmail) {
                $scope.matchingBelowEmail = false;
            }
        });
        $scope.$watch('matchingBelowEmail', function() {
            if($scope.matchingBelowEmail) {
                _.set($rootScope.campaign, 'automatedActions.matchingBelow.action', {
                    sendmessage: {templateKey: 'reject'},
                }); 
            } else {
                _.set($rootScope.campaign, 'automatedActions.matchingBelow.action', undefined);
            }
        });
    }])

    .controller('CandidatesCtrl', ["$scope", "$rootScope", "$state", "$stateParams", "$timeout", "Server", "PopupService", "overlaySpinner", "Translate", "_", "Onboarding", "igbPublicationFactory", "talentplugPublicationFactory", "Util", "StageFactory", "ToasterService", "$q", "EventTracker", function ($scope, $rootScope, $state, $stateParams, $timeout, Server, PopupService, overlaySpinner, Translate, _, Onboarding, igbPublicationFactory,talentplugPublicationFactory, Util, StageFactory, ToasterService, $q, EventTracker) {
        Util.setPageTitle(Translate.getLangString('candidates'));
        $rootScope.secondaryNav = null;
        $rootScope.backEnabled = true;
        $scope.limitDisplay = $scope.displayLimitPage = 25;
        $scope.loadMore = function () {
            if ($scope.candidateList) {
              $scope.candidateList.loadNext();
            }
        };
        $scope.canhowNoCandidatesPlaceholder = true;
        $scope.queryParams = Util.getQueryString(location);

        var checkCampaignValidationRequest = function (campaignId) {
            return Server.get('campaignValidationRequest/' + campaignId)
                .then(function (response) {
                    return response;
                })
        }

        var onCampaignData = function (campaign) {
            overlaySpinner.hide('candidates');
            let previousIgb;
            if ($rootScope.campaign) {
                previousIgb = $rootScope.campaign.igb
            }
            $rootScope.campaign = campaign;
            $rootScope.candidates = campaign.candidates;
            if (campaign.departments) {
                $rootScope.campaign.departmentsNames = campaign.departments.map(department => department.name);
            }
            const campaignTitle = Util.getDisplayedTitleAndEmployerBranding($rootScope.campaign, $rootScope.user);
            Util.setPageTitle(Translate.getLangString('campaign'), campaignTitle);
            $rootScope.$emit('candidateList.onCandidatesData')
            
            // Assume the previous igb if it was not refreshed
            if (!campaign.igb) {
                campaign.igb = previousIgb;
            }
            campaign.igbStatus = igbPublicationFactory.getStatus(campaign?.igb?.publicationStatus?.status);
            campaign.talentplugStatus = talentplugPublicationFactory.getStatus(campaign?.talentplug?.publicationStatus?.status);
            if ($scope.queryParams.talentplugRedirect) {
                $scope.openCollectCandidatesPopUp();
                $scope.queryParams.talentplugRedirect = false;
            }

            // Check if the user has the 'useCampaignValidations' setting.
            // And if so, check the campaign validation status
            if ($rootScope.user.settings.campaigns.useCampaignValidations) {
                checkCampaignValidationRequest(campaign._id).then(function (validationRequest) {
                    campaign.validationRequestStatus = validationRequest.status;
                    campaign.validationRequestDate = validationRequest.date;
                    campaign.validationRequestVoters = validationRequest.voters;
                    campaign.validationRequestRecruiterComment = validationRequest.recruiterComment;
                    campaign.validationRequestValidatorsListName = validationRequest.name;
                    campaign.validationRequestRequesterName = validationRequest.requesterName
                    $scope.campaignValidatorRequester = validationRequest.requesterId;
                    campaign.hasRejectedVotes = validationRequest.hasRejectedVotes;
                    campaign.validationRequestRejectedVotesCount = validationRequest.voters.filter(voter => voter.vote === 'rejected').length;
                    campaign.validationRequestVotesCount = validationRequest.voters.filter(voter => voter.vote !== 'pending').length;
                    campaign.validationRequestTotalValidators = validationRequest.voters.length;

                    const requesterName = validationRequest.requesterName;
                    const validationDate = validationRequest.date ? new Date(validationRequest.date).toLocaleDateString() : '';

                    campaign.validationRequestSentByAndDate = Translate.getLangString('validation_request_sent_by_and_date', null, [requesterName, validationDate]);
                    // Check if the current user is a validator for the current campaign
                    const currentUserVote = campaign.validationRequestVoters.find(voter => voter.userId === $rootScope.user._id);
                    if (currentUserVote) {
                        $scope.isCampaignValidator = true;
                        $scope.currentUserVote = {
                            ...currentUserVote,
                            showVoteButtons: false
                        };
                    } else {
                        $scope.isCampaignValidator = false;
                    }
                }).catch(function (error)  {
                    console.error('Error checking validation request status', error);
                });
            }
        }

        var reloadCampaign = function () {
            overlaySpinner.show('candidates');
            Server.get('campaigns/' + $stateParams.campaignId + '?loadJobPostingPublicationStatus=eager&departments=true&campaignStatus=true')
                .then(onCampaignData)
                .catch(err => {
                    overlaySpinner.hide('candidates');
                    if (err.status === 404) {
                        $state.go('campaigns');
                        ToasterService.failure(err, 'load_campaign_not_found');
                    }
                    else if (err.error && err.error < 0) {
                        $state.go('campaigns');
                        ToasterService.failure(err, 'load_campaign_forbidden');
                    } else {
                        ToasterService.failure(err, 'load_candidates_error');
                    }
                });
        };

        $rootScope.validateUserAccess((user) => {
            if ($rootScope.fns.userHasRights('candidates.profileInfo', 'view', user)) {
                reloadCampaign();
            }
        });

        //
        // IGB
        //

        $scope.refreshJobPostingStatus = () => {
            if ($rootScope.fns.hasPrivileges(['canUseIgb'])) {

                Server.get('igb-integration/getCampaignPublicationStatus/' + $rootScope.campaign._id)
                .then(function (res) {
                        $rootScope.campaign.igbStatus = igbPublicationFactory.getStatus(res.publicationStatus);
                        ToasterService.success('multiposting_job_status_refreshed');
                    },
                    function (err) {
                        ToasterService.failure(err, 'err_43_igb_status_error');
                    });

                }
            if ($rootScope.fns.hasPrivileges(['canUseTalentPlug']) && $rootScope.campaign?.talentplug?.offerId) {

                Server.get('talentplug-integration/offers/'+ $rootScope.campaign.talentplug.offerId)
                .then((res) => {
                    $rootScope.campaign.talentplugStatus = talentplugPublicationFactory.getStatus({data: res});
                    ToasterService.success('multiposting_job_status_refreshed');
                }).catch(err => {
                    ToasterService.failure(err, 'err_43_igb_status_error');
                });
            }
            
        };
        

        //
        // Campaign control buttons
        //

        $scope.addCandidate = function () {
            EventTracker.trackCampaignCandidateAdd();
            PopupService.openAddCandidatePopUp($scope, {}, $rootScope.campaign);
        };

        $scope.openCollectCandidatesPopUp = function () {
            EventTracker.trackCampaignPostJob();
            PopupService.openCollectCandidatesPopUp($scope, {
                activeTab: $scope.queryParams.talentplugRedirect ? 'talentplug' : undefined
            }, $rootScope.campaign);
        };

        $scope.trackPostingMarketplace = function () {
            EventTracker.trackCampaignVonq();
        }

        //
        // Collaborator stuff
        //

        $scope.ddAddCollaboratorClick = function (selected) {
            if (selected.value == 'invitecollaborator') {
                $state.go('collaborators');
            } else {
                Server.post('campaigns/' + $stateParams.campaignId + '/assign/' + selected.value)
                    .then(onCampaignData)
                    .catch((err) => {
                        ToasterService.failure(err, 'err_0_error_occurred');
                    });;
            }
        };

        $scope.onCollaboratorAssign = function (collaboratorId) {
            Server.post('campaigns/' + $stateParams.campaignId + '/assign/' + collaboratorId)
                .then(onCampaignData)
                .catch((err) => {
                    ToasterService.failure(err, 'err_0_error_occurred');
                });
        }
        $scope.onCollaboratorRemove = function(collaborator) {
            Server.post('campaigns/' + $stateParams.campaignId + '/unassign/' + collaborator._id)
                .then(onCampaignData)
                .catch((err) => {
                    ToasterService.failure(err, 'err_0_error_occurred');
                });
        }

        $scope.loadDepartments = async function() {
            if ($rootScope.departments && $rootScope.departments.length > 0) {
                const deferred = $q.defer();
                deferred.resolve($rootScope.departments);
                return deferred.promise;
            } else {
                try {
                    return Server.get('departments').then(departments => {
                        $rootScope.departments = departments;
                        return departments;
                    });
                } catch (err) {
                    ToasterService.failure(err, 'departments_load_error');
                    const deferred = $q.defer();
                    deferred.resolve([]);
                    return deferred.promise;
                }
            }
        };
        $scope.manageDepartments = async function() {
            const departments = await $scope.loadDepartments();
            const checkedDepartments = await PopupService.openAssignableData(
                $scope,
                {
                    modalTitle: Translate.getLangString('departments'),
                    sourceItem: $rootScope.campaign,
                    targets: departments,
                    getLabel: (target) => {
                        return target.name;
                    },
                    isAssigned: (source, target) => {
                        return source.departmentIds ? source.departmentIds.some(m => m === target._id) : false;
                    },
                    onAssign: (id) => {
                        return Server.post('campaigns/' + $stateParams.campaignId + '/assign-department/' + id);
                    },
                    onUnassign: (id) => {
                        return Server.post('campaigns/' + $stateParams.campaignId + '/unassign-department/' + id);
                    },
                }
            );
            $rootScope.campaign.departmentIds = checkedDepartments.map(department => department._id);
            $rootScope.campaign.departments = checkedDepartments;
            $rootScope.campaign.departmentsNames = checkedDepartments.map(department => department.name);
            $scope.$apply();
        }

        $scope.fetchCandidates = function (filters, sort, offset, limit, isKanban) {
            const overlay = overlaySpinner.show('candidates');
            filters = { ...filters, campaigns: [ $stateParams.campaignId ] }
            const url = isKanban ? 'candidates/kanban' : 'candidates';
            return Server.post(url + '?fetchHistoryCount=true', { filters, sort, pagination: { offset, limit } }, { preserveCache: true })
                .then((allCandidates) => {
                    $timeout(() => {
                        overlay.hide();
                    }, 1000 * allCandidates.items.length / 100); // add one second delay for every 100 items to account for dom lockup period
                    return allCandidates;
                })
                .catch((err) => {
                    overlay.hide();
                    ToasterService.failure(err, 'load_candidates_error');
                });
        }

        $scope.assignCampaignStatus = function(campaign) {
            PopupService.assignStatusToACampaign($scope, campaign)
                .then(campaignData => {
                    Object.assign(campaign, campaignData);
                });
        }

        //
        // Campaign Validations
        //

        $scope.requestCampaignValidation = async function(campaign) {
            try {
                await PopupService.requestCampaignValidation($scope, campaign);
                // Reload the campaign data to reflect the new validation request
                const validationRequest = await checkCampaignValidationRequest(campaign._id);
                campaign.validationRequestStatus = validationRequest.status;
                campaign.validationRequestDate = validationRequest.date;
                campaign.validationRequestVoters = validationRequest.voters;
                campaign.validationRequestRecruiterComment = validationRequest.recruiterComment;
                campaign.validationRequestValidatorsListName = validationRequest.name;
                campaign.validationRequestRequesterName = validationRequest.requesterName;
                $scope.campaignValidatorRequester = validationRequest.requesterId;
                $scope.isCampaignValidator = campaign.validationRequestVoters.some(voter => voter.userId === $rootScope.user.id);
                if ($scope.isCampaignValidator) {
                    $scope.currentUserVote = campaign.validationRequestVoters.find(voter => voter.userId === $rootScope.user.id);
                }
                campaign.hasRejectedVotes = validationRequest.hasRejectedVotes;
                campaign.validationRequestVotesCount = validationRequest.voters.filter(voter => voter.vote !== 'pending').length;
                campaign.validationRequestTotalValidators = validationRequest.voters.length;
                const requesterName = validationRequest.requesterName;
                const validationDate = validationRequest.date ? new Date(validationRequest.date).toLocaleDateString() : '';
        
                campaign.validationRequestSentByAndDate = Translate.getLangString('validation_request_sent_by_and_date', null, [requesterName, validationDate]);
                // Update the UI
                $scope.$apply();
            } catch (error) {
                console.error('Error requesting campaign validation', error);
            }
        };

        $scope.showCampaignValidationStatus = function(campaign) {
            PopupService.showCampaignValidationStatus($scope, campaign, $scope.campaignValidatorRequester);
        };

        $scope.showCampaignSettings = function(campaign) {
            $state.go('cc-settings', { campaignId: campaign._id });
        };

        $scope.voteOnCampaignValidationRequest = async function (campaign, decision) {
            try {
                await PopupService.voteOnCampaignValidationRequest($scope, campaign, decision);
            } catch (error) {
                console.error('Error submitting vote', error);
                ToasterService.failure('validation_vote_failed');
            }
        };

        Onboarding.initWidget('dashboard-candidates');
    }])

    .controller('SubmissionCtrl', ["$scope", "$rootScope", "$stateParams", "Server", "$timeout", "$state", "PopupService", "overlaySpinner", "Translate", "Util", "StageFactory", "Onboarding", "drawProgressCircle", "ToasterService", "EventTracker", function ($scope, $rootScope, $stateParams, Server, $timeout, $state, PopupService, overlaySpinner, Translate, Util, StageFactory, Onboarding, drawProgressCircle, ToasterService, EventTracker) {
        Util.setPageTitle(Translate.getLangString('Candidate'));
        $rootScope.secondaryNav = null;
        $rootScope.backEnabled = true;
        Onboarding.initWidget(null);
        $scope.matchingFcts = Util.matchingFcts;
        $scope.candidateTags = [];
        $scope.schedule = '';
        $scope.session = '';
        $scope.editingReason;
        $scope.editingOrderAmount;

        if ($rootScope.user) {
            // Only get them if user is connected (otherwise -> auth error)
            StageFactory.getAllStagesDd()
                .then(function (allStagesDd) {
                    $scope.ddCandidateStage = allStagesDd;
                });
        }

        $scope.editReason = function () {
            Server.post('candidates/' + $rootScope.candidate._id, {
                refusalReason: $rootScope.candidate.refusalReason,
            }).then(function (responseCandidate) {
                $rootScope.candidate.refusalReason = responseCandidate.refusalReason;
            });
        };

        $scope.editOrderAmount = function () {
            Server.post('candidates/' + $rootScope.candidate._id, {
                orderAmount: $rootScope.candidate.orderAmount,
            }).then(function (responseCandidate) {
                $rootScope.candidate.orderAmount = responseCandidate.orderAmount;
            });
        };

        $scope.ddStageClick = async function (selectedStage, clickedCandidate) {
            EventTracker.trackCampaignCandidateStageChange();
            if (selectedStage?.action?.setDate) {
                $timeout(function () {
                    PopupService.openStageDate($scope, [clickedCandidate], selectedStage);
                }, 500);
            } else {
                try {
                    clickedCandidate = (await StageFactory.ddClick([clickedCandidate], selectedStage))[0];
                    if (clickedCandidate.stageCurrent?.action?.sendmessage?.templateKey && $rootScope.fns.userHasRights('candidates.messages', 'edit')) {
                        $timeout(function () {
                            PopupService.openCandidateMessage($scope, [clickedCandidate], {
                                preSelectedMsgTemplateKey: clickedCandidate.stageCurrent.action.sendmessage.templateKey
                            });
                        }, 500);
                    }
                } catch (err) {
                    ToasterService.failure(err, 'err_0_error_occurred');
                }
            }
        }


        var buildZipOptions = function (candidateName, session) {
            var schedule = new Date(session.schedule);
            var size = 0;

            if (!session.documents || !session.documents.length)
                return;

            session.documents.forEach(function (doc) {
                if (doc.size) {
                    size += doc.size;
                }
                //console.log(doc);
            });

            var zipUrl = 'sessions/' + session._id + '/download/zip';
            var zipName = ('Live interview ' + candidateName + ' ' + session.subject + ' ' + schedule.getDate() + ' ' + (schedule.getMonth() + 1) + ' ' + schedule.getFullYear() + '.zip').replace(/ /g, '_');

            return {
                originalFilename: zipName,
                mimeType: 'application/zip',
                filename: zipUrl,
                url: Server.makeUrl(zipUrl),
                size: size,
                downloadFilename: zipName
            };
        };

        var buildLiveSessionDocuments = function (candidate) {
            if (!candidate || !candidate.sessions)
                return;

            candidate.sessions.forEach(function (session) {
                var document = buildZipOptions(Util.userFullName(candidate), session);
                //console.log(document);
                if (document) {
                    candidate.documents.push(document);
                }

            });
            //console.log(candidate.sessions, candidate.documents);
        };

        $scope.bookmark = function (candidate) {
            var bookmarked = (candidate.bookmarks && candidate.bookmarks[$rootScope.user._id]);
            Server.post('candidates/' + candidate._id, {
                bookmarks: !bookmarked
            }).then(function (updatedCandidate) {
                candidate.bookmarks = updatedCandidate.bookmarks;
            });
        };

        loadCandidateTags();

        function setCandidateSession(sessions) {
            $scope.session = Util.getNextComingSession(sessions);
            $scope.interviewTooltip = Util.makeSessionTooltip($scope.session);
        }

        function loadCandidateTags() {
            return $stateParams.candidateId && Server.get('candidates/' + $stateParams.candidateId + '/tags')
                .then((tags) => {
                    if (tags.length) {
                        $scope.candidateTags = tags;
                    } else {
                        $scope.candidateTags = [];
                    }
                });
        }

        $scope.onCandidateData = async function (candidate) {
            overlaySpinner.hide('submission');
            $rootScope.candidate = candidate;
            const userFullName = $rootScope.userFullName(candidate);
            const navTitle = `${Translate.getLangString('submission_title')} ${userFullName} (${Util.getDisplayedTitleAndEmployerBranding($rootScope.candidate.campaign, $rootScope.user)})`;
            Util.setPageTitle(Translate.getLangString('Candidate'), navTitle);
            candidate.answeredQuestions = _.filter(candidate.questions, function (q) {
                const answeredQuestionStandard = (q.answer || q.filename || q.skipped) && q.mode !== 20;
                const answeredQuestionBug = q.mode === 20 && q.filename;
                return answeredQuestionStandard || answeredQuestionBug;
            });

            $scope.ddFilteredCandidateActions = $scope.ddCandidateActions.filter($scope.candidateActionsFilter);

            if (!$rootScope.submissionKey) {

                // transfer the "reviews" on the candidate to "ratings" on the questions
                (candidate.questions || []).forEach(function (question) {
                    computeQuestionRatings(candidate, question);
                });

                $rootScope.getNotes(candidate);

                candidate.unreadNotes = $rootScope.countUnreadNotes(candidate.notes);

                if ($rootScope.campaign.collaborators) {
                    $rootScope.campaign.collaborators.forEach(function (collaborator) {
                        collaborator.label = $rootScope.userFullName(collaborator);
                    });
                }

                $scope.ddCandidateStage = await StageFactory.getAllStagesDd()

            } else {
                hideLoader();
            }

            buildLiveSessionDocuments(candidate);

            $scope.$broadcast('onCandidateData', candidate);

            if (Util.matchingFcts.cUses(candidate)) {
                drawProgressCircle.matching($('#matching-sidebar'), candidate.matching.score);
                setTimeout(function () {
                    $('.question__match-score__circle').each(function (i) {
                        drawProgressCircle.matching($(this), candidate.questions[i].matching.resultW);
                    });
                }, 0);
            }

            if (candidate.sessions && candidate.sessions.length > 0)
                setCandidateSession(candidate.sessions);
        };

        $scope.reloadCandidate = function () {
            const overlaySubmission = overlaySpinner.show('submission');

            var params = {
                session: $state.is('submission-documents') ? 1 : undefined,
            };

            if ($rootScope.submissionKey) {
                Server
                    .get('candidates/keys/' + $rootScope.submissionKey, params)
                    .then(data => {
                        overlaySubmission.hide();
                        return $scope.onCandidateData(data);
                    }).catch(err => {
                        overlaySubmission.hide();
                        ToasterService.failure(err, 'load_candidates_error');
                    });
                return;
            }

            if ($stateParams.markCandidateAsViewed) {
                Server.post('candidates/' + $stateParams.candidateId, { views: true });
            }

            const overlayCandidate = overlaySpinner.show('submission');
            Server.get(`candidates/${$stateParams.candidateId}?sessions=true`, params)
                .then(function (candidate) {
                    const overlayCampaign = overlaySpinner.show('submission');
                    overlayCandidate.hide();
                    Server.get(`campaigns/${candidate.campaignId}`)
                        .then(function (campaignFull) {
                            overlayCampaign.hide();
                            $rootScope.campaign = campaignFull;
                            $scope.onCandidateData(candidate);
                        }).catch(err => {
                            overlayCampaign.hide();
                            ToasterService.failure(err, 'load_campaign_error');
                        });
                }).catch(err => {
                    overlayCandidate.hide();
                    if (err.status === 404) {
                        $state.go('campaigns');
                        ToasterService.failure(err, 'err_107b_missing_candidate');
                        return;
                    }
                    if (err.error && err.error < 0) {
                        $state.go('candidate-page');
                        ToasterService.failure(err, 'load_candidate_forbidden');
                    } else {
                        ToasterService.failure(err, 'load_candidates_error');
                    }
                });
        };

        var candidateActions = [{
            text: Translate.getLangString('candidate_send_message_option'),
            value: 'message'
        }, {
            text: Translate.getLangString('candidate_archive_option'),
            value: 'archive'
        }, {
            text: Translate.getLangString('candidate_archive_option'),
            value: 'unarchive'
        }, {
            text: Translate.getLangString('share'),
            value: 'share',
            requiredPrivileges: ['canShareCandidates']
        }, {
            text: Translate.getLangString('candidate_reinvite_option'),
            value: 'reinvite',
            in3DotsMenu: true
        }, {
            text: Translate.getLangString('candidate_interview_invite_option'),
            value: 'liveinvite',
            requiredPrivileges: ['canLiveInterview'],
            in3DotsMenu: false
        }, {
            text: `${Translate.getLangString('candidate_assessment_invite_option')}`,
            value: 'assessmentInvite',
            requiredPrivileges: ['hasAssessments'],
            in3DotsMenu: true
        }, {
            text: Translate.getLangString('candidate_add_to_campaign'),
            requiredUserRights: { name: 'candidates.profileInfo', level: 'edit' },
            value: 'copy',
            in3DotsMenu: true
        }, {
            text: Translate.getLangString('candidate_print_documents'),
            value: 'printDocuments',
            requiredUiSetting: ['usesMassPrinting'],
            in3DotsMenu: true
        }, {
            text: Translate.getLangString('candidate_print_application'),
            value: 'printApplication',
            in3DotsMenu: true
        }, {
            text: Translate.getLangString('candidate_reset_video_questions'),
            requiredUserRights: { name: 'candidates.profileInfo', level: 'edit' },
            value: 'resetVideoQuestions',
            in3DotsMenu: true
        }, {
            text: Translate.getLangString('edit'),
            value: 'edit',
            in3DotsMenu: true
        }, {
            text: Translate.getLangString('delete'),
            value: 'delete',
            in3DotsMenu: true
        },].filter(function (item) {
            const privilegeCheck = $rootScope.fns.hasPrivileges(item.requiredPrivileges);
            const userRightCheck = !item.requiredUserRights || $rootScope.fns.userHasRights(item.requiredUserRights.name, item.requiredUserRights.level);
            return privilegeCheck && userRightCheck;
        }).filter(function (item) {
            return $rootScope.fns.hasUiSettings(item.requiredUiSetting);
        });

        $scope.ddFilteredCandidateActions = $scope.ddCandidateActions = candidateActions.filter(function (item) {
            return item.in3DotsMenu
        })

        // make {archive: {text: "Archiver", value: "archive"}, delete: ...} for easy use on buttons
        $scope.ddCandidateActionsObj = candidateActions.reduce(function (fin, val) {
            fin[val.value] = val;
            return fin
        }, {});
        $scope.ddCandidateSelected = {};

        $scope.candidateActionsFilter = function(action) {
            if (action.value === 'resetVideoQuestions') {
                if (!$rootScope.candidate || !$rootScope.campaign) {
                    return false
                }
                return $rootScope.campaign.questions.some(q => !q.isWritten);
            }
            
            return true;
        }

        function checkCurrentCandidateEmail(message) {
            if ($rootScope.candidate.email.startsWith('email_not_found')) {
                ToasterService.failure(null, message);
                return false;
            }
            return true;
        }
        $scope.checkEmailAndRedirect = function(candidate, targetState) {
            if (candidate.email && candidate.email.startsWith('email_not_found')) {
                ToasterService.failure(null, 'candidate_email_not_found');
            } else {
                $state.go(targetState, { candidateId: candidate._id });
            }
        };

        $scope.navigateTo = function(stateName) {
            if ($rootScope.candidate.email.startsWith('email_not_found')) {
                ToasterService.failure(null, 'candidate_email_not_found');
            } else {
                $state.go(stateName, { candidateId: $rootScope.candidate._id });
            }
        };
        
        $scope.isActive = function(targetState) {
            return $state.current.name === targetState;
        };
        

        $scope.ddCandidateClick = function (selected) {

            switch (selected.value) {
                case 'archive':
                    Server.archiveObject('candidates', $stateParams.candidateId)
                        .then($scope.onCandidateData);
                    $rootScope.back({ reload: true });
                    break;
                case 'unarchive':
                    Server.unarchiveObject('candidates', $stateParams.candidateId)
                        .then($scope.onCandidateData);
                    $rootScope.back({ reload: true });
                    break;
                    case 'delete':
                        PopupService.openGenericPopup($scope, {
                            submit: function () {
                                $scope.modalHandle.close();
                                var promises = Promise.resolve();
                                promises = promises.then(function() {
                                    return Server.deleteObject('candidates', $stateParams.candidateId);
                                });
                                promises = promises.then(function() {            
                                    $rootScope.back({ reload: false });
                                }).catch(function(err) {
                                    ToasterService.failure(err, 'delete_candidate_error');
                                });
                            },
                        title: Translate.getLangString('delete_confirmation_title'),
                        warningText: Translate.getLangString('delete_confirmation_warning'),
                        yesText: Translate.getLangString('delete_one_candidate_confirmation_yes'),
                        noText: Translate.getLangString('cancel')
                    }, 'templates/modal-confirm-warning.html', {});
                    break;
                case 'reinvite':
                    if (!checkCurrentCandidateEmail('cannot_resend_invitation_because_candidate_no_email')) return;
                    PopupService.openCandidateInvite($scope, [$rootScope.candidate]);
                    break;
                case 'copy':
                    PopupService.openCandidateCopy($scope, [$rootScope.candidate]);
                    break;
                case 'edit':
                    PopupService.openCandidateEdit($scope, {}, $stateParams.candidateId, $rootScope.candidate);
                    break;
                case 'message':
                    if (!checkCurrentCandidateEmail('cannot_send_message_because_candidate_no_email')) return;
                    PopupService.openCandidateMessage($scope, [$rootScope.candidate], {});
                    break;
                case 'share':
                    PopupService.openSubmissionShare($scope, $stateParams.candidateId);
                    break;
                case 'liveinvite':
                    if (!checkCurrentCandidateEmail('cannot_live_invite_because_candidate_no_email')) return;
                    PopupService.openSessionEditor($scope, null, null, 'liveinvite', $rootScope.user, $rootScope.campaign, $rootScope.candidate);
                    break;
                case 'assessmentInvite':
                    if (!checkCurrentCandidateEmail('cannot_invite_to_an_assessment_because_candidate_no_email')) return;
                    PopupService.openAssessmentInvite($scope, [$rootScope.candidate]);
                    break;
                case 'printDocuments':
                    PopupService.openCandidateDocumentPrinter($scope, [$rootScope.candidate]);
                    break;
                case 'printApplication':
                    window.print();
                    break;
                case 'resetVideoQuestions':
                    if (!checkCurrentCandidateEmail('cannot_reset_video_questions_because_candidate_no_email')) return;
                    PopupService.openResetVideoQuestionsPopup($scope, [$rootScope.candidate]);
                    break;
            }
        };

        function quickReload() {
            var currentState = $state.$current.toString();
            var params = $stateParams.candidateId ? $stateParams.candidateId : {};
            $state.go(currentState, params, { reload: true });
        }

        function computeQuestionRatings(candidate, question) {
            var rating;
            if (candidate.reviews && candidate.reviews[$rootScope.user._id] && candidate.reviews[$rootScope.user._id][question._id]) {
                rating = candidate.reviews[$rootScope.user._id][question._id];
            }
            question.id = question._id;
            question.rating = rating;
        }

        //
        // Tags
        //

        $scope.showShowMoreTags = false;
        $scope.tagsExpanded = false;
        const tagContainerContainer = $(".sidebar-profile__tags");
        const tagContainer = $(".sidebar-profile__tag-container");
        let tagContainerHeight;

        function checkShowMore() {
            $timeout(function () {
                tagContainerHeight = tagContainer.height();
                if (tagContainerHeight > 45) {
                    $scope.showShowMoreTags = true;
                } else {
                    $scope.showShowMoreTags = false;
                }
            }, 1000);
        }

        $timeout(checkShowMore, 1000);

        $scope.showMoreTags = function () {
            if (!$scope.tagsExpanded) {
                tagContainerHeight = tagContainer.height();
                tagContainerContainer.animate({ height: tagContainerHeight });
                $scope.tagsExpanded = true;
            } else {
                tagContainerContainer.animate({ height: "45px" });
                $scope.tagsExpanded = false;
            }
        };

        $scope.$on('tagChange', function () {
            // Tags : Sidebar : actualiser
            loadCandidateTags();
            checkShowMore();
        });

        $scope.openStageHistory = function () {
            PopupService.openStageHistory($scope, [$rootScope.candidate]);
        }
        $scope.reloadCandidate();
    }])

    .controller('SubmissionTagsCtrl', ["$scope", "$rootScope", "$stateParams", "Server", "$timeout", "$state", "Translate", "ToasterService", "PopupService", "overlaySpinner", "multiSelect", function ($scope, $rootScope, $stateParams, Server, $timeout, $state, Translate, ToasterService, PopupService, overlaySpinner, multiSelect) {

        $scope.tagField = '';
        $scope.userTags = [];
        $scope.candidateTags = [];
        $scope.userTagCategories = [];

        $rootScope.validateUserAccess((user) => {
            if (!$rootScope.fns.userHasRights('candidates.tags', 'view', user)) {
                $state.go('submission', { candidateId: $stateParams.candidateId })
            }
        });
        
        let multiSelectln = JSON.parse(JSON.stringify(multiSelect));
        $scope.multiselectSettings = {
            ...multiSelectln.objectSettings,
            idProperty: "id",
            dynamicTitle: true,
            smartButtonMaxItems: 5,
        };
        $scope.singleSelectSettings = {
            ...$scope.multiselectSettings,
            selectionLimit: 1,
            showCheckAll: false,
            showUncheckAll: false,
            checkBoxes: false,
        };

        $scope.tagsTexts = {
            title: '_VOID',
            add_item: 'add_tags',
            items: 'tags',
        }
        /** @type { CrudListProperty } */
        const tagsProperties = [
            {
                type: 'toggle',
                location: 'header',
                text: Translate.getLangString('tag_assigned'),
                key: 'isAssigned',
                noSort: true,
                dataCssClass: 'tag__assigned__col',
                filterCssClass: 'tag__assigned__col',
                onToggle: (item) => $scope.toggleAssigned(item),
                options: [
                    { id: true, label: 'Assigned' },
                    { id: false, label: 'Unassigned' },
                ],
                extraSettings: $scope.multiselectSettings,
            },
            {
                type: 'text',
                location: 'header',
                text: Translate.getLangString('tags_label'),
                key: 'label',
                noFilter: true,
                isGlobalFilter: true,
                dataCssClass: 'tag__label__col crud-list__title__col',
                filterCssClass: 'tag__label__col crud-list__title__col',
            },
            {
                type: 'dropdown',
                location: 'header',
                text: Translate.getLangString('category'),
                emptyText: Translate.getLangString('no_category'),
                key: 'tagCategory',
                noFilter: false,
                noSort: true,
                dataCssClass: 'tag__category__col',
                filterCssClass: 'tag__category__col',
                optionsFactory: function() {
                    return $scope.userTagCategories
                },
                extraSettings: $scope.singleSelectSettings,
            }
        ]
        $scope.tagsProperties = tagsProperties;

        $scope.tagCategoriesTexts = {
            title: '_VOID',
            add_item: 'add_categories',
            items: 'categories',
            delete_warning: 'tag_category_delete_confirmation_warning',
        }
        /** @type {CrudListProperty} */
        const tagCategoriesProperties = [
            {
                type: 'text',
                location: 'header',
                text: Translate.getLangString('tags_label'),
                key: 'label',
                noFilter: true,
                dataCssClass: 'tag__label__col crud-list__title__col',
                filterCssClass: 'tag__label__col crud-list__title__cols',
            }
        ];
        $scope.tagCategoriesProperties = tagCategoriesProperties

        $scope.loadInputTagsAutocomplete = function (query) {
            let loadInputTagsAutocomplete = $scope.userTags
                .filter(c => (c.label.includes(query)));

            return loadInputTagsAutocomplete;
        };

        //
        // Tag deletion (with right click on tag)
        //

        $scope.removingTag = false;
        $scope.myContextDiv = `<div id='contextmenu-node'><ul ><li class='contextmenu-item' ng-click='clickedRemoveTag(tag)'>${Translate.getLangString('tag_delete_cta')}</li></ul></div>`;
        $scope.clickedRemoveTag = function (tag) {
            $scope.removingTag = true;
            Server.getObject('users/' + $rootScope.user.id + '/tags', tag._id)
                .then(function (candidatesWithTag) {
                    if (!candidatesWithTag || !candidatesWithTag.length) {
                        submitDeletion()
                    } else {
                        PopupService.openGenericPopup($scope, {
                            submit: submitDeletion,
                            title: Translate.getLangString('delete_confirmation_title'),
                            warningText: Translate.getLangString('delete_confirmation_warning'),
                            messageText: Translate.getLangString('delete_tag_confirmation_message')
                                .replace('...', candidatesWithTag.length),
                            yesText: Translate.getLangString('delete_tag_confirmation_yes'),
                            noText: Translate.getLangString('cancel')
                        }, 'templates/modal-confirm-warning.html', {});
                    }

                    function submitDeletion() {
                        overlaySpinner.show('modal');
                        Server.deleteObject('users/' + $rootScope.user.id + '/tags', tag._id)
                            .then(function () {
                                ToasterService.success('tag_deleted_message');
                                loadCandidateTags()
                                    .then(loadUserTags);
                                $rootScope.$broadcast('tagChange');
                                $scope.removingTag = false;
                                overlaySpinner.hide('modal');
                                $scope.modalHandle.close();
                            }, function (err) {
                                ToasterService.failure(err, 'tag_not_deleted_message');
                                $scope.removingTag = false;
                                overlaySpinner.hide('modal');
                                $scope.modalHandle.close();
                            });
                    }

                }, function (err) {
                    console.log('deleted not tag');
                    ToasterService.failure(err, 'tag_not_deleted_message');
                    $scope.removingTag = false;
                });
        };

        /**
         * Tag creation and assign from text input
         */
        $scope.addTagFromInput = function () {
            var inputTags = $scope.tagField;
            if (!inputTags)
                return;

            inputTags.forEach(tag => {
                var isInUserTags = $scope.userTags.find(function (el) {
                    return el.label === tag.label
                }) !== undefined;
                var isInCandidateTags = $scope.candidateTags.find(function (el) {
                    return el.label === tag.label
                }) !== undefined;
                if (isInUserTags && !isInCandidateTags) {
                    Server.put('candidates/' + $rootScope.candidate._id + '/tags/' + tag._id)
                        .then(function (candidate) {
                            loadCandidateTags()
                                .then(loadUserTags);
                            $rootScope.$broadcast('tagChange');
                        });
                }

                if (!isInUserTags && !isInCandidateTags) {
                    Server.post('users/' + $rootScope.user.id + '/tags', { label: tag.label })
                        .then((newtag) => {
                            if (newtag) {
                                Server.put('candidates/' + $rootScope.candidate._id + '/tags/' + newtag._id)
                                    .then(function (candidate) {
                                        loadCandidateTags()
                                            .then(loadUserTags);
                                        $rootScope.$broadcast('tagChange');
                                    });
                            }
                        });
                }
            });
            $scope.tagField = '';
        };

        $scope.toggleAssigned = function(item) {
            if (item.isAssigned) {
                $scope.assignTag(item)
            } else {
                $scope.unassignTag(item)
            }
        }

        $scope.saveTag = function(item) {
            const tagCategoryId = item.tagCategory[0] ? item.tagCategory[0].id : null;
            if (item._id) {
                return Server.patch('users/' + $rootScope.user.id + '/tags/' + item._id, {
                    label: item.label,
                    tagCategoryId,
                }).then(() => {
                    ToasterService.success('tag_updated');
                    loadUserTags();
                    $rootScope.$broadcast('tagChange');
                }).catch((err) => {
                    ToasterService.failure(err, 'tag_not_updated');
                    return false;
                })
            } else {
                var isInUserTags = $scope.userTags.find(function (el) {
                    return el.label === item.label
                }) !== undefined;
                var isInCandidateTags = $scope.candidateTags.find(function (el) {
                    return el.label === item.label
                }) !== undefined;
                if (isInUserTags && !isInCandidateTags) {
                    return Server.put('candidates/' + $rootScope.candidate._id + '/tags/' + item._id)
                        .then(function (candidate) {
                            loadCandidateTags()
                                .then(loadUserTags);
                            $rootScope.$broadcast('tagChange');
                        }).catch((err) => {
                            ToasterService.failure(err, 'tag_not_created');
                            return false;
                        });
                }

                if (!isInUserTags && !isInCandidateTags) {
                    return Server.post('users/' + $rootScope.user.id + '/tags', {
                        label: item.label,
                        tagCategoryId,
                    }).then((newtag) => {
                        ToasterService.success('tag_created');
                        if (newtag) {
                            Server.put('candidates/' + $rootScope.candidate._id + '/tags/' + newtag._id)
                                .then(function (candidate) {
                                    loadCandidateTags()
                                        .then(loadUserTags);
                                    $rootScope.$broadcast('tagChange');
                                });
                        } else {
                            loadUserTags().then(() => {
                                $rootScope.$broadcast('tagChange')
                            });
                        }
                    }).catch((err) => {
                        ToasterService.failure(err, 'tag_not_created');
                        return false;
                    });
                }
            }
        }

        $scope.deleteTag = function(item) {
            const oldUserTags = [...$scope.userTags];
            const oldCandidateTags = [...$scope.candidateTags];
            return Server.delete('users/' + $rootScope.user.id + '/tags/' + item._id)
                .then(() => {
                    $scope.userTags = $scope.userTags.filter(t => t._id !== item._id);
                    $scope.candidateTags = $scope.candidateTags.filter(t => t._id !== item._id);
                    ToasterService.success('tag_deleted');
                    $rootScope.$broadcast('tagChange');
                }).catch((err) => {
                    $scope.userTags = oldUserTags;
                    $scope.candidateTags = oldCandidateTags;
                    ToasterService.failure(err, 'tag_not_deleted');
                })
        }

        /**
         * Tag  assign from left click on user's existing tag
         */
        $scope.assignTag = function (selectedTag) {
            var tag = selectedTag;
            if (!tag || $scope.removingTag)
                return;

            var isInCandidateTags = $scope.candidateTags.find(function (el) {
                return el._id === tag._id
            }) !== undefined;
            if (!isInCandidateTags) {
                const overlay = overlaySpinner.show("submission");
                Server.put('candidates/' + $rootScope.candidate._id + '/tags/' + tag._id)
                    .then(function (candidate) {
                        loadCandidateTags()
                            .then(() => {
                                loadUserTags();
                                overlay.hide();
                            })
                            .catch(err => {
                                overlay.hide();
                            });
                        $rootScope.$broadcast('tagChange');
                    }).catch(err => {
                        overlay.hide();
                    });
            }
        };

        /**
         * Tag  unassign from left click on candidate's assigned tags
         */
        $scope.unassignTag = function (selectedTag) {
            var tag = selectedTag;
            if (!tag)
                return;

            const overlay = overlaySpinner.show("submission");
            Server.delete('candidates/' + $rootScope.candidate._id + '/tags/' + tag._id)
                .then(function (candidate) {
                    if (candidate) {
                        loadCandidateTags()
                            .then(() => {
                                loadUserTags();
                                overlay.hide();
                            })
                            .catch(err => {
                                overlay.hide();
                            });
                        $rootScope.$broadcast('tagChange');
                    } else {
                        overlay.hide();
                    }
                }).catch((err) => {
                    ToasterService.failure(err, 'tag_not_updated');
                    overlay.hide();
                });
        };

        $scope.saveTagCategory = function(item) {
            if (item._id) {
                return Server.patch('users/' + $rootScope.user.id + '/tag-categories/' + item._id, {
                    label: item.label,
                }).then(async () => {
                    ToasterService.success('tag_category_updated');
                    await $scope.loadUserTagCategories();
                }).catch((err) => {
                    ToasterService.failure(err, 'tag_category_not_updated');
                    return false;
                })
            } else {
                return Server.post('users/' + $rootScope.user.id + '/tag-categories/', {
                    label: item.label,
                }).then(async () => {
                    ToasterService.success('tag_category_created');
                    await $scope.loadUserTagCategories();
                }).catch((err) => {
                    ToasterService.failure(err, 'tag_category_not_created');
                    return false;
                })
            }
        }

        $scope.deleteTagCategory = function(item) {
            return Server.delete('users/' + $rootScope.user.id + '/tag-categories/' + item._id)
                .then(async () => {
                    ToasterService.success('tag_category_deleted');
                    $scope.tagsCrudList.setPropOptions('tagCategory');
                    await $scope.loadUserTagCategories();
                    await loadUserTags();
                }).catch((err) => {
                    ToasterService.failure(err, 'tag_category_not_deleted')
                })
        }

        function loadCandidateTags() {
            return Server.get('candidates/' + $stateParams.candidateId + '/tags')
                .then((tags) => {
                    if (tags.length) {
                        $scope.candidateTags = tags;
                    } else {
                        $scope.candidateTags = [];
                    }
                    $scope.mapTagsAssignment();
                });
        }

        function loadUserTags() {
            return Server.get('users/' + $rootScope.user.id + '/tags')
                .then((tags) => {
                    if (tags.length) {
                        tags.forEach(tag => {
                            if (tag.tagCategory) {
                                tag.tagCategory = [{ ...tag.tagCategory, id: tag.tagCategory._id}]
                            } else {
                                tag.tagCategory = []
                            }
                        });
                        $scope.userTags = tags;
                    } else {
                        $scope.userTags = [];
                    }
                    $scope.mapTagsAssignment();
                });
        }

        $scope.loadUserTagCategories = function() {
            return Server.get('users/' + $rootScope.user.id + '/tag-categories')
                .then((tagCategoryies) => {
                    if (tagCategoryies.length) {
                        $scope.userTagCategories = tagCategoryies.map(cat => {
                            cat.id = cat._id;
                            return cat;
                        });
                    } else {
                        $scope.userTagCategories = [];
                    }
                    $scope.tagsCrudList.setPropOptions('tagCategory', $scope.userTagCategories);
                })
        }

        $scope.isTagSelected = function (tag) {
            if ($scope.userTags.length < 0)
                return false;
            var isInCandidateTags = $scope.candidateTags.find(function (el) {
                return el._id === tag._id
            });
            return !!isInCandidateTags;
        }

        $scope.mapTagsAssignment = function() {
            $scope.userTags.forEach((tag) => {
                tag.isAssigned = $scope.isTagSelected(tag);
            });
        }

        $scope.onTagItemData = function(item) {
            // make fields readonly on only when editing
            // const labelProp = $scope.tagsProperties.find(p => p.key === 'label')
            // if (labelProp) {
            //     labelProp.disabled = !!item._id;
            // }
        }
        $scope.onTagCategoryItemData = function(item) {
            // make fields readonly on only when editing
            // const labelProp = $scope.tagCategoriesProperties.find(p => p.key === 'label')
            // if (labelProp) {
            //     labelProp.disabled = !!item._id;
            // }
        }

        loadCandidateTags()
            .then(loadUserTags)
            .then($scope.loadUserTagCategories);
    }]);

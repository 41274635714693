angular.module('app')
    .component('candidateList', { // This name is what AngularJS uses to match to the `<candidate-list>` element.
        templateUrl: '../templates/components/candidate-list.component.html',
        bindings: {
            pageLimit: '<',
            displayMode: '<',
            onLoadData: '&',
        },
        controller: ["$scope", "$rootScope", "$state", "$stateParams", "$window", "Server", "PopupService", "overlaySpinner", "Translate", "Util", "_", "StageFactory", "multiSelect", "$timeout", "ToasterService", "componentCache", "$q", "EventTracker", "componentCache", "ScorecardExportService", function CandidateListController($scope, $rootScope, $state, $stateParams, $window, Server, PopupService, 
            overlaySpinner, Translate, Util, _, StageFactory, multiSelect, $timeout, ToasterService, componentCache, 
            $q, EventTracker, componentCache, ScorecardExportService) {

                var $ctrl = this;
                $scope.matchingFcts = Util.matchingFcts;
                $scope.candidates = [];
                $scope.kanbanCandidates = [];

                $scope.campaignId = $stateParams.campaignId;
                $scope.kanbanSettingName = $state.is('candidate-page') ? 'useKanbanViewCandidateDb' : 'useKanbanView';

                $scope.kanbanFetchOffset = 0;
                $scope.displayMode = $rootScope.fns.hasCampaignsSettings([$scope.kanbanSettingName], $rootScope.user, true) ? 'kanban' : 'list'; 
                $scope.stages = [];
                $scope.accessibleStages = [];
                $scope.hasNoCandidates = false;
                $scope.checkedCandidatesIds = [];
                $scope.iconClass = "icon--stars"
                $scope.filteCacheKey = $state.current.name + '/candidate-list';
                $scope.searchField = '';
                $scope.splitTagCategories = $rootScope.fns.hasCandidatesSettings(['tagsCategorySplit'], $rootScope.user);
                const multiselectObjectSettings = { ...multiSelect.objectSettings, idProperty:"id" };                              
                            
                $scope.ddFilters = {
                    stage: {
                        list: [],
                        options: [],
                        multiSelSettings: multiselectObjectSettings,
                        multiSelTexts: {
                            ...multiSelect.texts,
                            buttonDefaultText: Translate.getLangString('candidates_sort_by_stage')
                        },
                        buttonClass: 'candidate-profiles__stage-search',
                        multiSelEvents: {onSelectionChanged: function(item) {
                            giveActiveClassToFilter($scope.ddFilters.stage.buttonClass, $scope.ddFilters.stage.list);
                            $scope.setTabAndFilters($rootScope.activeTab || 0);  
                        }},
                    },
                    campaign: {
                        hide: !$scope.$parent.showCampaignFilter,
                        list: [],
                        options: [],
                        multiSelSettings: { ...multiselectObjectSettings, idProperty: '_id' },
                        multiSelTexts: {
                            ...multiSelect.texts,
                            buttonDefaultText: Translate.getLangString('candidates_select_campaign')
                        },
                        buttonClass: 'candidate-profiles__campaign-search',
                        multiSelEvents: {onSelectionChanged: function(item) {
                            giveActiveClassToFilter($scope.ddFilters.campaign.buttonClass, $scope.ddFilters.campaign.list);
                            $scope.setTabAndFilters($rootScope.activeTab || 0);  
                        }},
                    },
                    tag: {
                        list: [],
                        options: [],
                        multiSelSettings: {
                            ...multiselectObjectSettings,
                            idProperty: '_id',
                            template: $scope.splitTagCategories ? '{{ option.label }}' : multiSelect.optionsTemplates.tagsWithCategories,
                            selectedToTop: !$scope.splitTagCategories,
                            searchField: 'text'
                        },
                        multiSelTexts: {
                            ...multiSelect.texts,
                            buttonDefaultText: Translate.getLangString('candidates_sort_by_tag')
                        },
                        buttonClass: 'candidate-profiles__tag-search',
                        multiSelEvents: {onSelectionChanged: function(item) {
                            giveActiveClassToFilter($scope.ddFilters.tag.buttonClass, $scope.ddFilters.tag.list);
                            $scope.setTabAndFilters($rootScope.activeTab || 0);  
                        }},
                    },
                    tagCategories: {},
                    // interview: {
                    //     list: [],
                    //     options: [
                    //         { id: 0, label: Translate.getLangString('interview_physique'), isPhysicalInterview: true},
                    //         { id: 1, label: Translate.getLangString('interview_live'), isPhysicalInterview: false}
                    //     ],
                    //     multiSelSettings: multiselectObjectSettings,
                    //     multiSelTexts: {
                    //         ...multiSelect.texts,
                    //         buttonDefaultText: Translate.getLangString('candidates_filter_by_interview')
                    //     },
                    //     buttonClass: 'candidate-profiles__interview-search',
                    //     multiSelEvents: {onSelectionChanged: function(item) {
                    //         giveActiveClassToFilter($scope.ddFilters.interview.buttonClass, $scope.ddFilters.interview.list);
                    //         $scope.setTabAndFilters($rootScope.activeTab || 0);  
                    //     }},
                    // },
                    assessFirst: {
                        list: [],
                        options: [
                            {id: 10, label: Translate.getLangString('invited'), progressStatus: 'invited'},
                            {id: 11, label: Translate.getLangString('in_progress'), progressStatus: 'in_progress'},
                            {id: 12, label: Translate.getLangString('completed'), progressStatus: 'completed'},
                            {id: 1, label: Translate.getLangString('candidates_filter_by_score_range_1'), scoreRange: {bottom: 0, top: 25}},
                            {id: 2, label: Translate.getLangString('candidates_filter_by_score_range_2'), scoreRange: {bottom: 25, top: 50}},
                            {id: 3, label: Translate.getLangString('candidates_filter_by_score_range_3'), scoreRange: {bottom: 50, top: 75}},
                            {id: 4, label: Translate.getLangString('candidates_filter_by_score_range_4'), scoreRange: {bottom: 75, top: 100}}
                        ],
                        multiSelSettings: multiselectObjectSettings,
                        multiSelTexts: {
                            ...multiSelect.texts,
                            buttonDefaultText: Translate.getLangString('candidates_filter_by_assessfirst_adequacy')
                        },
                        buttonClass: 'candidate-profiles__assessFirst-search',
                        multiSelEvents: {onSelectionChanged: function(item) {
                            giveActiveClassToFilter($scope.ddFilters.assessFirst.buttonClass, $scope.ddFilters.assessFirst.list);
                            $scope.setTabAndFilters($rootScope.activeTab || 0);  
                        }},
                    },
                    docimo: {
                        list: [],
                        options: [
                            {id: 0, label: Translate.getLangString('candidates_filter_by_score_range_0'), scoreRange: null},
                            {id: 1, label: Translate.getLangString('candidates_filter_by_score_range_1'), scoreRange: {bottom: 0, top: 25}},
                            {id: 2, label: Translate.getLangString('candidates_filter_by_score_range_2'), scoreRange: {bottom: 25, top: 50}},
                            {id: 3, label: Translate.getLangString('candidates_filter_by_score_range_3'), scoreRange: {bottom: 50, top: 75}},
                            {id: 4, label: Translate.getLangString('candidates_filter_by_score_range_4'), scoreRange: {bottom: 75, top: 100}}
                        ],
                        multiSelSettings: multiselectObjectSettings,
                        multiSelTexts: {
                            ...multiSelect.texts,
                            buttonDefaultText: Translate.getLangString('candidates_filter_by_docimo_percentage')
                        },
                        buttonClass: 'candidate-profiles__docimo-search',
                        multiSelEvents: {onSelectionChanged: function(item) {
                            giveActiveClassToFilter($scope.ddFilters.docimo.buttonClass, $scope.ddFilters.docimo.list);
                            $scope.setTabAndFilters($rootScope.activeTab || 0);  
                        }},
                    },
                    matching: {
                        list: [],
                        options: [
                            {id: 0, label: Translate.getLangString('candidates_filter_by_score_range_0'), noScore: true},
                            {id: 1, label: Translate.getLangString('candidates_filter_by_score_range_1'), scoreRange: {bottom: 0, top: 0.25}},
                            {id: 2, label: Translate.getLangString('candidates_filter_by_score_range_2'), scoreRange: {bottom: 0.25, top: 0.5}},
                            {id: 3, label: Translate.getLangString('candidates_filter_by_score_range_3'), scoreRange: {bottom: 0.5, top: 0.75}},
                            {id: 4, label: Translate.getLangString('candidates_filter_by_score_range_4'), scoreRange: {bottom: 0.75, top: 1}},
                            // conditionally add one option
                            ...($rootScope.fns.hasPrivileges(['canUseWorkflows']) ? [
                                {id: 5, label: Translate.getLangString('settings_actions_super_match'), isSuper: true}
                            ] : []),
                        ],
                        multiSelSettings: multiselectObjectSettings,
                        multiSelTexts: {
                            ...multiSelect.texts,
                            buttonDefaultText: Translate.getLangString('candidates_filter_by_matching')
                        },
                        buttonClass: 'candidate-profiles__matching-search',
                        multiSelEvents: {onSelectionChanged: function(item) {
                            giveActiveClassToFilter($scope.ddFilters.matching.buttonClass, $scope.ddFilters.matching.list);
                            $scope.setTabAndFilters($rootScope.activeTab || 0);  
                        }},
                    },
                    rating: {
                        list: [],
                        options: [
                            {id: 0, label: Translate.getLangString('candidates_filter_by_rating_range_0'), ratingRange: null},
                            {id: 1, label: Translate.getLangString('candidates_filter_by_rating_range_1'), ratingRange: {bottom: 1, top: 2}},
                            {id: 2, label: Translate.getLangString('candidates_filter_by_rating_range_2'), ratingRange: {bottom: 2, top: 3}},
                            {id: 3, label: Translate.getLangString('candidates_filter_by_rating_range_3'), ratingRange: {bottom: 3, top: 4}},
                            {id: 4, label: Translate.getLangString('candidates_filter_by_rating_range_4'), ratingRange: {bottom: 4, top: 5}}
                        ],
                        multiSelSettings: multiselectObjectSettings,
                        multiSelTexts: {
                            ...multiSelect.texts,
                            buttonDefaultText: Translate.getLangString('rating')
                        },
                        buttonClass: 'candidate-profiles__rating-search',
                        multiSelEvents: {onSelectionChanged: function(item) {
                            giveActiveClassToFilter($scope.ddFilters.rating.buttonClass, $scope.ddFilters.rating.list);
                            $scope.setTabAndFilters($rootScope.activeTab || 0);  
                        }},
                    },
                    referralSources: {
                        list: [],
                        options: [],
                        multiSelSettings: multiselectObjectSettings,
                        multiSelTexts: {
                            ...multiSelect.texts,
                            buttonDefaultText: Translate.getLangString('referral_source_short')
                        },
                        buttonClass: 'candidate-profiles__source-search',
                        multiSelEvents: {
                            onSelectionChanged: function(item){
                                giveActiveClassToFilter($scope.ddFilters.referralSources.buttonClass, $scope.ddFilters.referralSources.list);
                                $scope.setTabAndFilters($rootScope.activeTab || 0);
                            }
                        }
                    },
                    country: {
                        list: [],
                        options: [],
                        multiSelSettings: multiselectObjectSettings,
                        multiSelTexts: {
                            ...multiSelect.texts,
                            buttonDefaultText: Translate.getLangString('country')
                        },
                        buttonClass: 'candidate-profiles__country-search',
                        multiSelEvents: {
                            onSelectionChanged: function(item){
                                giveActiveClassToFilter($scope.ddFilters.country.buttonClass, $scope.ddFilters.country.list);
                                $scope.setTabAndFilters($rootScope.activeTab || 0);
                            }
                        }
                    },
                    addedBy: {
                        list: [],
                        options: [],
                        multiSelSettings: multiselectObjectSettings,
                        multiSelTexts: {
                            ...multiSelect.texts,
                            buttonDefaultText: Translate.getLangString('added_by')
                        },
                        buttonClass: 'candidate-profiles__addedBy-search',
                        multiSelEvents: {
                            onSelectionChanged: function(item){
                                giveActiveClassToFilter($scope.ddFilters.addedBy.buttonClass, $scope.ddFilters.addedBy.list);
                                $scope.setTabAndFilters($rootScope.activeTab || 0);
                            }
                        }
                    }
                };

                $scope.trackCandidateView = function() {
                    EventTracker.trackCampaignCandidateView();
                }

                $scope.hasViewRights = $rootScope.fns.userHasRights("candidates.profileInfo", "view");

                function giveActiveClassToFilter(buttonClass, list) {
                    const buttonElement = $(`ng-dropdown-multiselect.${buttonClass} .multiselect-parent .dropdown-toggle`);
                    if (list.length) {
                        buttonElement.addClass('active');
                    } else {
                        buttonElement.removeClass('active');
                    }
                }

                function giveActiveClassToFilters() {
                    giveActiveClassToFilter($scope.ddFilters.stage.buttonClass, $scope.ddFilters.stage.list);
                    giveActiveClassToFilter($scope.ddFilters.campaign.buttonClass, $scope.ddFilters.campaign.list);
                    giveActiveClassToFilter($scope.ddFilters.tag.buttonClass, $scope.ddFilters.tag.list);
                    for (const tagCategory in $scope.ddFilters.tagCategories) {
                        giveActiveClassToFilter($scope.ddFilters.tagCategories[tagCategory].buttonClass, $scope.ddFilters.tagCategories[tagCategory].list);
                    }
                    giveActiveClassToFilter($scope.ddFilters.assessFirst.buttonClass, $scope.ddFilters.assessFirst.list);
                    giveActiveClassToFilter($scope.ddFilters.docimo.buttonClass, $scope.ddFilters.docimo.list);
                    giveActiveClassToFilter($scope.ddFilters.matching.buttonClass, $scope.ddFilters.matching.list);
                    giveActiveClassToFilter($scope.ddFilters.rating.buttonClass, $scope.ddFilters.rating.list);
                    giveActiveClassToFilter($scope.ddFilters.referralSources.buttonClass, $scope.ddFilters.referralSources.list);
                    giveActiveClassToFilter($scope.ddFilters.country.buttonClass, $scope.ddFilters.country.list);
                    giveActiveClassToFilter($scope.ddFilters.addedBy.buttonClass, $scope.ddFilters.addedBy.list);
                }

                $scope.showCandidateHistory = $scope.$parent.showCandidateHistory
                $scope.$parent.reloadCandidatesList = quickReload;

                // 
                // Search bar and sorters
                // 
                $scope.isSearchingCandidates = false; 
                
                $scope.showAdvancedSearch = false;

                $scope.advancedSearchOptions = {
                    maxFuzzy: false,       // Enable partial word matching
                    useFuzzy: false,        // Enable fuzzy (typo-tolerant) search
                    isExactMatch: false,    // Enable exact phrase matching
                    logicalOperator: "OR",  // Default to "OR" logic
                    selectedFields: [],     // Fields to search; default is all fields
                    // sortBy: { field: "", order: "" } // Sort options
                };

                // Available fields to search, TODO: add translations here
                $scope.advancedSearchAvailableFields = [
                    { name: 'Email', value: 'email' },
                    { name: 'First Name', value: 'firstName' },
                    { name: 'Last Name', value: 'lastName' },
                    { name: 'Phone Number', value: 'phoneNumber' },
                    { name: 'Question Answer', value: 'questions.answer' },
                    { name: 'Custom Information Answer', value: 'customInformations.answer' },
                    { name: 'Address Name', value: 'address.name' },
                    { name: 'Full Name Filter', value: 'filters.fullName' },
                    { name: 'Documents Transcript', value: 'filters.documentsTranscript' },
                    { name: 'Notes Texts', value: 'filters.notesTexts' },
                    { name: 'Custom Information Answers', value: 'filters.customInformationsAnswers' }
                ];

                $scope.searchEdit = function() {
                    if ($scope.isSearching) {
                        return;
                    }
                
                    $scope.isSearchingCandidates = true;
                    $scope.sortByTextMatchScore = true;
                
                    var searchPromise;
                    searchPromise = $scope.onLoadData(true);
                    searchPromise.finally(function() {
                        $scope.isSearchingCandidates = false;
                    });
                };

                $scope.getTagTitle = function(tag) {
                    let text = '';
                    if (!$scope.splitTagCategories && tag.tagCategoryId) {
                        const tagOption = $scope.ddFilters.tag.options.find(x => x.tagCategoryId === tag.tagCategoryId);
                        if (tagOption && tagOption.tagCategory) {
                            text += `${tagOption.tagCategory.label} : `;
                        }
                    }
                    return text + tag.label;
                }
                $scope.tagsArrayToString = function(items) {
                    var str = '';
                    if (items && Array.isArray(items)) {
                        items.forEach(element => {
                            let text = $scope.getTagTitle(element);
                            text = text.replace(/(.+:)/, '<b>$1</b>');
                            str += `<div class="tags-tooltip-item">${text}</div>`;
                        });
                    }
                    return str;
                };
        
                $scope.sorters = [
                    {
                        title: Translate.getLangString('candidates_sort_by_application_date'),
                        property: 'time',
                        reverse: true
                    },
                    {               
                        title: Translate.getLangString('candidates_sort_by_last_treated_date'),
                        property: 'lastTreatedDate',
                        reverse: true
                    },
                ];

                $scope.setSorter = function (sorter) {
                    if (!sorter) {
                        // default sort is on "time"
                        sorter = $scope.sorters[0];
                    }
        
                    // invert sorting
                    if ($scope.activeSorter == sorter) {
                        $scope.sortReverse = !$scope.sortReverse;
                    } else {
                        // activate sorting
                        $scope.activeSorter = sorter;
                        $scope.sortProperty = sorter.property;
                        $scope.sortReverse = sorter.reverse;
                    }
                }
                /**
                 * Activate or invert sorting on chosen "sorter"
                 */
                $scope.sorterClick = function(sorter) {
                    $scope.setSorter(sorter);
                    $scope.sortByTextMatchScore = false;
                    return $scope.onLoadData(true);
                };

                // 
                // Tabs
                // 
        
                $rootScope.activeTab = $rootScope.activeTab || 0;
                $rootScope.activeTabSave = 0;
                
                const cachedCampaignId = componentCache.getCacheValue($scope.filteCacheKey, 'campaignId') || '0';
                const stateCampaignId = $stateParams.campaignId || '0';
                let excludeCachedProperties = []
                if (cachedCampaignId !== stateCampaignId) {
                    excludeCachedProperties = ['candidates', 'kanbanCandidates', 'campaignId'];
                }
                componentCache.applyCache($scope.filteCacheKey, $scope, [], excludeCachedProperties);
                hasCachedCandidates = $scope.displayMode === 'kanban' ? $scope.kanbanCandidates.length > 0 : $scope.kanbanCandidates.length > 0;
        
                $scope.setTabAndFilters = _.debounce(function(index) {
                    $rootScope.activeTab = index;
                    $rootScope.activeTabSave = index;
                    $scope.onLoadData(true);
                }, 1500);

                // 
                // Candidate data manipulation
                // 
                $rootScope.$on('candidateList.setTabAndFilters', function(evt, data) {
                    $scope.setTabAndFilters(data.index);
                });

                /**
                 * Selecting the stage of the candidate
                 * If candidates are checked, consider only the checked candidates
                 * If the candidate(s) is (are) rejected ('not_selected'), open the email popup (to enforce recruiter to contact the rejected candidate)
                 */
                /**
                 * @param  {} selectedStage
                 * @param  {} clickedCandidate
                 */
                $scope.ddStageClick = async function(selectedStage, clickedCandidate, allowBulk = true) {
                    if (selectedStage.accessible) {
                        $scope.ddStageChangeConfirm(selectedStage, clickedCandidate, allowBulk);
                    } else {
                        PopupService.openGenericPopup($scope, {
                            submit: async function () {
                                $scope.modalHandle.close();
                                await $scope.ddStageChangeConfirm(selectedStage, clickedCandidate, allowBulk);
                            },
                            warningText: Translate.getLangString('candidate_to_forbidden_stage_warning', null, [selectedStage.text]),
                            yesText: Translate.getLangString('set_stage'),
                            noText: Translate.getLangString('cancel')
                        }, 'templates/modal-confirm-warning.html', {});
                    }
                }


                /** Insert button tags-categories AND | OR */
                function createTogglesForTagCategories() {
                        var checkExist = setInterval(function() {
                            var elements = document.querySelectorAll('[id^=tag], [id^=tagCategory-]');
                            if (elements.length) {
                                clearInterval(checkExist);
                                elements.forEach(element => {
                                    // Only call makeToggle if it hasn't been created yet
                                    if (!document.querySelector(`#toggleOrFilter-${element.id}`)) {
                                        makeToggle(element.id);
                                    }
                                });
                            }
                        }, 100);
                }
                
                /**
                 * For tag filters - add an option in the filter to toggle between OR and AND
                 * @param {string} id - The ID of the button.
                 * @return {void}
                 */
                function makeToggle(id) {
                    var existingToggle = document.querySelector(`#toggleOrFilter-${id}`);
                    if (existingToggle) {
                        // Prevents the toggle from being created multiple times
                        return;
                    }
                    var button = document.querySelector('#' + id + ' button');
                    if (button) {
                        button.addEventListener('click', function () {
                            $timeout(function () {
                                var newListItem = document.createElement('li');

                                var toggleContainer = document.createElement('div');
                                toggleContainer.className = 'box__toggle-tags';
                                toggleContainer.style.minWidth = '250px';

                                var toggleLabel = document.createElement('p');
                                toggleLabel.textContent = Translate.getLangString('all_tags_required');
                                toggleContainer.appendChild(toggleLabel);

                                var toggleInput = document.createElement('input');
                                toggleInput.type = 'checkbox';
                                toggleInput.id = 'toggleOrFilter-' + id;
                                toggleInput.className = 'toggle-checkbox';
                                if (!$scope.splitTagCategories) {
                                    toggleInput.checked = !$rootScope.filters.basicTagsFilterUseOr;
                                } else {
                                    toggleInput.checked = !$rootScope.filters.tagsFilterUseOr[id];
                                }
                                toggleContainer.appendChild(toggleInput);

                                var toggleVisual = document.createElement('label');
                                toggleVisual.className = 'toggle-container';
                                toggleVisual.htmlFor = 'toggleOrFilter-' + id;
                                toggleContainer.appendChild(toggleVisual);

                                newListItem.appendChild(toggleContainer);

                                // the toggling will refresh the data
                                toggleInput.addEventListener('change', function () {
                                    // note for future dev : This filter setting should be saved in the local storage, like the rest of the filters
                                    if (!$scope.splitTagCategories) {
                                        $rootScope.filters.basicTagsFilterUseOr = !toggleInput.checked;
                                    } else {
                                        $rootScope.filters.tagsFilterUseOr[id] = !toggleInput.checked;
                                    }
                                    $scope.onLoadData(true);
                                });
                    
                                var ulElement = document.querySelector('#' + id + ' ul.dropdown-menu.dropdown-menu-form');
                                if (ulElement) {
                                    ulElement.insertBefore(newListItem, ulElement.firstChild);
                                } else {
                                    console.warn('ulElement not found for id:', id);
                                }
                            }, 0); // timeout needed, doesn't work without
                        });
                    }                
                }
                
                $scope.ddStageChangeConfirm = async function(selectedStage, clickedCandidate, allowBulk) {
                    let candidates;
                    EventTracker.trackCandidateStageChange();
                    // clicking on a checked candidate => consider the all the checked candidates
                    if(allowBulk && _.includes($scope.checkedCandidatesIds, clickedCandidate._id)) {
                        candidates = $scope.checkedCandidates;
                    } else { 
                        candidates = [clickedCandidate];
                    }
                    if (selectedStage?.action?.setDate) {
                        $timeout(function () {
                            PopupService.openStageDate($scope, candidates, selectedStage);
                        }, 500);
                        // if canceled (close the modal), or errored, revert the candidate to the previous position
                    } else if (selectedStage?.action?.openLuccaModal) {
                        $timeout(function () {
                            PopupService.openLuccaModal($scope, candidates[0], selectedStage);
                        }, 500);
                        // if canceled (close the modal), or errored, revert the candidate to the previous position
                    } else {
                        await StageFactory.ddClick(candidates, selectedStage);
                        if (selectedStage?.action?.sendmessage?.templateKey  && $rootScope.fns.userHasRights('candidates.messages', 'edit')) {
                            $timeout(function() {
                                PopupService.openCandidateMessage($scope.$parent, candidates, {
                                    preSelectedMsgTemplateKey: selectedStage.action.sendmessage.templateKey,
                                });
                                // if errored, revert the candidate to the previous position
                            }, 500);
                        } 
                    }
                }
                $scope.onKanbanStateChange = function(stage, item) {
                    item.value.stages.push({ stage: { _id: stage.id }});
                    const selectedStage = $scope.ddCandidateStage.find(stg => stg._id === stage.id);
                    return $scope.ddStageClick(selectedStage, item.value, false);
                }
                $scope.switchDisplayMode = function(newMode) {
                    if (newMode !== $scope.displayMode) {
                        $scope.displayMode = newMode;
                        $scope.kanbanFetchOffset = 0;
                        $scope.onLoadData(true);
                        Server.patch(`users/me/settings`, {
                            campaigns: {
                                [$scope.kanbanSettingName]: $scope.displayMode === 'kanban'
                            }
                        }, { preserveCache: true })
                        .then(res => {
                            $rootScope.user.settings = res;
                        })
                        .catch(err => {
                            console.warn('failed to save display mode', err);
                        })
                    }
                }

                //
                // Checkboxes
                //

                resetCheckedCandidates();
        
                $scope.candiCheckbox = {
                    single: {
                        check: function (selectedElements, element) {
                            if (_.includes(selectedElements, element)) {
                                // remove from list
                                let idx = selectedElements.indexOf(element);
                                selectedElements.splice(idx, 1);
                            } else {
                                // add to list
                                selectedElements.push(element);
                            }
                        },
                        kanban: function (selectedElements, kanbanItem) {
                            const contains = _.includes(selectedElements, kanbanItem.value._id);
                            if (kanbanItem.isChecked) {
                                if (!contains) {
                                    selectedElements.push(kanbanItem.value._id);
                                }
                            } else {
                                // remove from list
                                if (contains) {
                                    let idx = selectedElements.indexOf(kanbanItem.value._id);
                                    selectedElements.splice(idx, 1);
                                }
                            }
                        },
                        isChecked: function(selectedElements, element) {
                            return _.includes(selectedElements, element);
                        }
                    },
                    all: {
                        /**
                         * checks or unchecks boxes
                         */
                        check: function (selectedElements, allElements) {
                            if (selectedElements.length >= 1) {
                                // at least one boxe is checked => uncheck all
                                selectedElements.length = 0;
                                if ($scope.kanbanCandidates) {
                                    for (const kanbanCandidate of $scope.kanbanCandidates) {
                                        $scope.kanbanRef.checkItem(kanbanCandidate, false);
                                    }
                                }
                            } else {
                                // no box is checked => check all
                                selectedElements.length = 0;
                                for(let i=0; i<allElements.length; i++){
                                    selectedElements[i] = allElements[i]._id;
                                }
                                if ($scope.kanbanCandidates) {
                                    for (const kanbanCandidate of $scope.kanbanCandidates) {
                                        $scope.kanbanRef.checkItem(kanbanCandidate, true);
                                    }
                                }
                            }
                        },
                        /**
                         * @returns { Boolean } - only part of the boxes are checked
                         */
                        isHalfChecked: function(selectedElements, allElements) {
                            // true if only part of candidates are checked
                            return selectedElements.length != 0 
                            && selectedElements.length != allElements.length;
                        },
                        /**
                         * @returns { Boolean } - true if all boxes are checked
                         */
                        isChecked: function(selectedElements, allElements) {
                            return selectedElements.length && selectedElements.length == allElements.length;
                        }
                    }
                };
                $scope.selectAll = function() {
                    $scope.onLoadData(false, true)
                        .then(() => {
                            $scope.checkedCandidatesIds.length = 0;
                            $scope.candiCheckbox.all.check($scope.checkedCandidatesIds, $scope.candidates);
                        });
                }
                $scope.clearSelection = function() {
                    $scope.candiCheckbox.all.check($scope.checkedCandidatesIds, $scope.candidates);
                }

                $scope.infiniteScrollStep = (Math.floor(window.innerWidth /1200) || 1) * 24;
                $scope.viewAllDomLimit = $scope.infiniteScrollStep;
                $scope.memoizationCache = {};
                $scope.getFilteredCandidates = function(candidates) {
                    var cacheKey = $scope.checkedCandidatesIds.length + '_' + $scope.candidatesTotal + '_' + $scope.viewAllDomLimit + '_' + $scope.candidatesHash;
                    if ($scope.memoizationCache.hasOwnProperty(cacheKey)) {
                        // console.log('Returning from cache');
                        return $scope.memoizationCache[cacheKey];
                    }
                    var result;
                    if (!$scope.candidatesTotal || !$scope.checkedCandidatesIds.length || $scope.checkedCandidatesIds.length === $scope.candidatesTotal) {
                        result = candidates.slice(0, $scope.viewAllDomLimit);
                    } else {
                        result = candidates;
                    }
                    $scope.memoizationCache[cacheKey] = result;
                    return result;
                };

                $scope.$watch('checkedCandidatesIds', function() {
                    $scope.checkedCandidates = $scope.checkedCandidatesIds.map(function(selCandId) {
                        return  $scope.candidates.find(function(c){return c._id == selCandId})
                    });
                    $scope.checkedCandidatesFullNames = $scope.checkedCandidates.reduce(function(names, candidate) {
                        return names + Util.userFullName(candidate) + ', ';
                    }, '').trim();
                    $scope.checkedCandidateEmails = $scope.checkedCandidates.reduce(function(emails, candidate) {
                        return emails + candidate.email + ', ';
                    }, '').trim();

                    $scope.ddFilteredCandidatesActions = $scope.ddCandidatesActions.filter($scope.actionsFilterFn)
                }, true);
        
                function resetCheckedCandidates() {
                    $scope.checkedCandidatesIds = [];
                }

                $scope.actionsFilterFn = function(action) {
                    if (!$scope.checkedCandidates || $scope.checkedCandidates.length === 0) {
                        return false
                    }

                    if (action.value === 'resetVideoQuestions') {
                        return $scope.checkedCandidates.some(candidate => Util.hasVideoQuestions(candidate.campaign));
                    }
                    
                    return true;
                }

                // 
                // Bookmarks
                // 

                $scope.bookmark = function(candidate) {
                    Server
                        .post('candidates/' + candidate._id, {bookmarks: !isBookmarkedByMe(candidate)})
                        .then(function(updatedCandidate) {
                            if (isBookmarkedByMe(updatedCandidate)) {
                                addToBookmarkList(candidate);
                            } else {
                                removeFromBookmarkList(candidate);
                            }
                            candidate.bookmarks = updatedCandidate.bookmarks;
                        });
                };
        
                function isBookmarkedByMe(candidate) {
                    return candidate.bookmarks && candidate.bookmarks[$rootScope.user._id];
                }
                function addToBookmarkList(candidate) {
                    $ctrl.candidates.bookmarked.push(candidate);
                }
                function removeFromBookmarkList(candidate) {
                    $ctrl.candidates.bookmarked = _.filter($ctrl.candidates.bookmarked, function(bc) {
                        return bc._id != candidate._id;
                    });
                }
                
                // 
                // Bulk actions
                // 
        
                /** Suggest to mark as viewed if at least one candidate is not viewed yet */
                $scope.displayMarkViewed = function() {
                    return _.some($scope.checkedCandidates, function(candidate) {
                        return !hasBeenViewedByMe(candidate);
                    });
                }
        
                function hasBeenViewedByMe(candidate) {
                    return candidate.views && candidate.views[$rootScope.user._id];
                }
        
                function checkCandidatesEmail() {
                    var candidatesWithNoEmail = $scope.checkedCandidates.filter(candidate => candidate.email.startsWith('email_not_found'));
                    if (candidatesWithNoEmail.length > 0) {
                        var namesList = candidatesWithNoEmail.map(candidate => `${candidate.firstName} ${candidate.lastName}`).join(', ');
                        ToasterService.failure(null, 'bulk_candidates_email_not_found', [namesList]);
                        return false;
                    }
                    return true;
                }
                             
                $scope.ddBulkCandidateClick = function(selected) {
        
                    switch(selected.value) {
                        case 'reinvite':
                            if (!checkCandidatesEmail()) return;
                            PopupService.openCandidateInvite($scope.$parent, $scope.checkedCandidates);
                            break;
                        case 'message':
                            if (!checkCandidatesEmail()) return;
                            PopupService.openCandidateMessage( $scope.$parent, $scope.checkedCandidates, {})
                            .then(function() {
                                quickReload();
                            });
                            break;
                        case 'mark-viewed':
                            $scope.checkedCandidates.forEach(function(checkedCandidate) {
                                Server
                                    .post('candidates/' + checkedCandidate._id, {views: true})
                                    .then(function(updatedCandidate) {
                                        checkedCandidate.views = updatedCandidate.views;
                                    });
                            });
                            break
                        case 'mark-unviewed':
                            $scope.checkedCandidates.forEach(function(checkedCandidate) {
                                Server
                                    .post('candidates/' + checkedCandidate._id, {views: false})
                                    .then(function(updatedCandidate) {
                                        checkedCandidate.views = updatedCandidate.views;
                                    });
                            });
                            break
                        case 'archive':
                            var promises = Promise.resolve();
                            $scope.checkedCandidates.forEach(function(candidate) {
                                promises = promises.then(function() {
                                    return Server.archiveObject('candidates', candidate._id);
                                });
                            });
                            promises = promises.then(function() {
                                return quickReload();
                            });
                            break;
                        case 'unarchive':
                            var promises = Promise.resolve();
                            $scope.checkedCandidates.forEach(function(candidate) {
                                promises = promises.then(function() {
                                    return Server.unarchiveObject('candidates', candidate._id);
                                });
                            });
                            promises = promises.then(function() {
                                return quickReload();
                            });
                            break;
                        case 'delete':
                            PopupService.openGenericPopup($scope, {
                                submit: function() {
                                    $scope.modalHandle.close();
                                    var promises = Promise.resolve();
                                    $scope.checkedCandidates.forEach(function(candidate) {
                                        promises = promises.then(function() {
                                            return Server.deleteObject('candidates', candidate._id)
                                        });
                                    });
                                    promises = promises.then(function() {
                                        return quickReload();
                                    });
                                },
                                title: Translate.getLangString('delete_confirmation_title'),
                                warningText: Translate.getLangString('delete_confirmation_warning'),
                                yesText: $scope.checkedCandidates.length == 1 ? Translate.getLangString('delete_one_candidate_confirmation_yes') : Translate.getLangString('delete_several_candidate_confirmation_yes'),
                                noText: Translate.getLangString('cancel')
                            }, 'templates/modal-confirm-warning.html', {});
                            break;
                        case 'copy':
                            PopupService.openCandidateCopy($scope.$parent, $scope.checkedCandidates);
                            break;
                        // case 'print':
                        //     Server.post(`users/${$rootScope.user._id}/candidates-documents`, {candidateIds: $scope.checkedCandidatesIds})
                        //     .then(res => {
                        //         alert('res.documentUrl' + res.documentUrl);
                        //         console.log('res.documentUrl', res.documentUrl);
                        //         console.log('res.uploadedDocuments', res.uploadedDocuments);
                        //     })
                        //     .catch(err => {
                        //         ToasterService.failure(err, 'err_71_error_while_printing');
                        //     })
                        case 'printDocuments':
                            PopupService.openCandidateDocumentPrinter($scope, $scope.checkedCandidates);
                            break;
                        case 'inviteToAssessFirst':
                            if (!checkCandidatesEmail()) return;
                            PopupService.opentInviteToAssessFirst($scope, $scope.checkedCandidates, 'assessfirst', function(returnedCandidates) {
                                _.forEach(returnedCandidates, candidate => {
                                    let idx = _.findIndex( $scope.candidates, c => c._id === candidate._id);
                                     $scope.candidates[idx] = candidate;
                                })
                            });
                            break;
                        case 'inviteToAssessment':
                            if (!checkCandidatesEmail()) return;
                            PopupService.openAssessmentInvite($scope, $scope.checkedCandidates);
                            break;
                        case 'availability':
                            if (!checkCandidatesEmail()) return;
                            PopupService.openAskAvailability( $scope.$parent, $scope.checkedCandidates, {});
                            break;
                        case 'resetVideoQuestions':
                            if (!checkCandidatesEmail()) return;
                            PopupService.openResetVideoQuestionsPopup($scope, $scope.checkedCandidates);
                            break;
                        case 'liveinvite':
                            if (!checkCandidatesEmail()) return;
                            if($scope.checkedCandidates.length > 1) {
                                PopupService.openGenericPopup($scope, {
                                    title: Translate.getLangString('warning'),
                                    messageText: 'You cannot invite multiple candidates to a live interview at the same time. Please select only one candidate.',
                                    noText: Translate.getLangString('cancel'),
                                    friendlyAlert: true
                                }, 'templates/modal-confirm-warning.html', {});
                                break;
                            }
                            $scope.candidate = $scope.checkedCandidates[0];
                            PopupService.openSessionEditor($scope, null, null, 'liveinvite', $rootScope.user, $scope.candidate.campaign, $scope.candidate);
                            break;
                        case 'downloadDocuments':
                            PopupService.downloadDocuments($scope, $scope.checkedCandidates,$state.is('candidates'));
                            break;
                        case 'exportScorecardsToPdf':
                            Server.post(`candidates/${$rootScope.user._id}/scorecards`, { candidateIds: $scope.checkedCandidatesIds })
                            .then(res => {
                                const candidates = res;
                                
                                if (candidates.length) {
                                    ScorecardExportService.exportScorecardsToPdf(candidates);
                                } else {
                                    ToasterService.failure(null, 'err_114_no_scorecards_to_export');
                                }
                            })
                            .catch(err => {
                                ToasterService.failure(err);
                            });
                            break;
                    }
                };

                $scope.ddFilteredCandidatesActions = $scope.ddCandidatesActions = [
                    {
                        text: Translate.getLangString('candidate_reinvite_option'),
                        value: 'reinvite',
                    },{
                        text: Translate.getLangString('candidate_add_to_campaign'),
                        value: 'copy',
                    },{
                        text: Translate.getLangString('candidate_interview_invite_option'),
                        requiredPrivileges: ['canLiveInterview'],
                        requiredUserRights: { name: 'interviewScheduler.interviewSessions', level: 'edit' },
                        value: 'liveinvite',
                    },{
                        text: Translate.getLangString('candidate_ask_availability'),
                        requiredUiSetting: ['usesAskAvailability'],
                        value: 'availability',
                    },{
                        text: Translate.getLangString('candidate_print_all_documents'),
                        requiredUiSetting: ['usesMassPrinting'],
                        value: 'printDocuments',
                    },{
                        text: Translate.getLangString('download_documents'),
                        value: 'downloadDocuments',
                    },{
                        text: Translate.getLangString('candidate_send_assfir_invite'),
                        requiredPrivileges: ['canAssessfirst', 'individual_canAssessfirst'],
                        value: 'inviteToAssessFirst',
                    },{
                        text: `${Translate.getLangString('candidate_assessment_invite_option')}`,
                        requiredPrivileges: ['hasAssessments'],
                        value: 'inviteToAssessment',
                    },{
                        text: Translate.getLangString('candidate_reset_video_questions'),
                        value: 'resetVideoQuestions',
                    },{
                        text: Translate.getLangString('export_scorecards_to_pdf'),
                        value: 'exportScorecardsToPdf',
                    }
                ].filter(function(item) {
                    const privilegeCheck = $rootScope.fns.hasPrivileges(item.requiredPrivileges);
                    const uiSettingsCheck = $rootScope.fns.hasUiSettings(item.requiredUiSetting);
                    const userRightCheck = !item.requiredUserRights || $rootScope.fns.userHasRights(item.requiredUserRights.name, item.requiredUserRights.level);
                    return privilegeCheck && uiSettingsCheck && userRightCheck;
                }); 
                $scope.ddCandidatesSelected = {};
                $scope.ddCandidatesClick = function (selected) {
                    $scope.ddBulkCandidateClick(selected);
                };
                
                function quickReload() {
                    var currentState = $state.$current.toString();
                    var params = $stateParams.campaignId ? $stateParams.campaignId : {} ;
                    $state.go(currentState, params, {reload: true});
                }

                $scope.$on('$destroy', function() {
                    const properties = [
                        'ddFilters.stage.list',
                        'ddFilters.campaign.list',
                        'ddFilters.tag.list',
                        //'ddFilters.interview.list',
                        'ddFilters.assessFirst.list',
                        'ddFilters.docimo.list',
                        'ddFilters.matching.list',
                        'ddFilters.rating.list',
                        'searchField',
                        'sortByTextMatchScore',
                        'campaignId',
                        'candidates',
                        'kanbanCandidates',
                        'candidatesTotal',
                        'candidatesTotalStarted',
                        'candidatesTotalArchived',
                        'candidatesTotalOnlyInvited',
                        'accessibleStages',
                        'ddFilters.referralSources.list',
                        'ddFilters.country.list',
                        'ddFilters.addedBy.list',
                        ...($scope.ddFilters.tagCategories ? Object.keys($scope.ddFilters.tagCategories).map(tagCategory => `ddFilters.tagCategories.${tagCategory}.list`) : [])
                    ];
                    componentCache.persistCache($scope.filteCacheKey, $scope, properties);
                });

            $scope.loadFilters = async function () {
                const promises = []
                // load stage
                promises.push(
                    StageFactory.getAllStagesDd()
                    .then(function(allStagesDd) {
                        $scope.ddCandidateStage = allStagesDd;
                        $scope.stages = allStagesDd.map(stg => ({
                            id: stg._id,
                            label: stg.label,
                            style: stg.customization,
                            key: stg.id,
                            accessible: stg.accessible,
                        }));
                        $scope.accessibleStages = $scope.stages.filter(x => x.accessible);
                        $scope.ddFilters.stage.options = $scope.stages;
                        $scope.setCandidatesState();
                    })
                );

                // load campaigns
                if ($scope.$parent.showCampaignFilter) {
                    promises.push(
                        Server.get('campaigns?skipCollaboratorLoad=true&skipCandidatesLoad=true')
                        .then(function(allCampaigns) {
                            const sortedCampaigns = Util.sortByDisplayedTitleAndArchiveStatus(allCampaigns, $rootScope.user, { activeLabel: true });
                            $scope.ddFilters.campaign.options = sortedCampaigns.map(campaign => {
                                return {
                                    label: campaign.displayedTitle,
                                    ...campaign
                                };
                            });
                        })
                    )
                }

                // load tags
                if ($rootScope.fns.hasPrivileges(["canUseCandidateTags"]) && $rootScope.fns.userHasRights("candidates.tags", "view")) {
                    promises.push(
                        Server.get('users/' + $rootScope.user.id + '/tags')
                        .then((tags) => {
                            if (tags.length) {
                                if ($scope.splitTagCategories) {
                                    const sortedTags = _.sortBy(
                                    tags.map((tag) => {
                                        return { ...tag, id: tag._id, text: tag.label.replaceAll('-', ' ')+'|'+tag.label }
                                    }), 'label')
                                
                                    const tagCategories = {}
                                    for (const tag of sortedTags) {
                                        const tagCategory = tag.tagCategory || {_id: 0};
                                        let tagCategoryFilter = tagCategories[tagCategory._id]
                                        if (!tagCategoryFilter) {
                                            tagCategories[tagCategory._id] = {
                                                ...$scope.ddFilters.tag,
                                                _id: tagCategory._id,
                                                buttonClass: "candidate-profiles__tag-cat-"+tagCategory._id,
                                                list: $scope.ddFilters.tagCategories?.[tagCategory._id]?.list ? $scope.ddFilters.tagCategories[tagCategory._id].list : [],
                                                multiSelTexts: {
                                                    ...$scope.ddFilters.tag.multiSelTexts,
                                                    buttonDefaultText: Translate.getLangString('candidates_sort_by_tag') + (tagCategory.label ? ': ' + tagCategory.label : '')
                                                },
                                                multiSelEvents: {onSelectionChanged: function(item) {
                                                    giveActiveClassToFilter(tagCategories[tagCategory._id].buttonClass, tagCategories[tagCategory._id].list);
                                                    $scope.setTabAndFilters($rootScope.activeTab || 0);  
                                                }},
                                                options: [tag],
                                            }
                                        } else {
                                            tagCategories[tagCategory._id].options.push(tag);
                                        }
                                    }
    
                                    $scope.ddFilters.tagCategories = tagCategories;
                                } else {
                                    // duplicate candidate-list.component#tags
                                    const withoutCategory = _.sortBy(tags.filter(t => !t.tagCategory).map((tag) => {
                                        return { ...tag, id: tag._id, text: tag.label.replaceAll('-', ' ')+'|'+tag.label }
                                    }), 'label');
                                    const withCategory = _.sortBy(tags.filter(t => t.tagCategory).map((tag) => {
                                        return {
                                            ...tag,
                                            id: tag._id,
                                            text: tag.tagCategory.label.replaceAll('-', ' ') + ' : ' + tag.label.replaceAll('-', ' ')
                                                + '|' + tag.tagCategory.label+ ' : ' +tag.label
                                        }
                                    }), 'tagCategory.label', 'label');
        
                                    $scope.ddFilters.tag.options = [ ...withoutCategory, ...withCategory ];
                                }
                            } else {
                                if ($scope.splitTagCategories) {
                                    $scope.ddFilters.tagCategories = {};
                                } else {
                                    $scope.ddFilters.tag.options = [];
                                }
                            }
    
                            $scope.categorizeCandidateTags();
                        })
                    );
                }

                // load countries
                if($rootScope.user.settings.ui.userSpecificFields.includes('aldelia')) {
                    let countryFilters = {filters: {}};
                    if($stateParams.campaignId) {
                        countryFilters.filters.campaigns = [ $stateParams.campaignId ];
                    }
                    promises.push(
                        Server.post('candidates/countries', countryFilters)
                        .then(function(res) {
                            let countries = _.filter(res.countries, country => country && country.length);
                            $scope.ddFilters.country.options.push({
                                id: 'no_country',
                                label: Translate.getLangString('no_country'),
                            })
                            $scope.ddFilters.country.options.push(...countries.map(country => {
                                return {
                                    id: country,
                                    label: country, 
                                };
                            }));
                        })
                    );
                }

                // load addedBy
                promises.push(
                    Server.get('users/' + $rootScope.user._id + '/addedBy')
                    .then(function(response) {
                        $scope.ddFilters.addedBy.options = response.map(user => ({
                            id: user._id,
                            label: Util.userFullName(user),
                        }));
                    })
                )
                
                return $q.all(promises);
            }

            $scope.onLoadData = async function (isReset = false, fetchAll = false) {
                const filter = {};

                switch ($rootScope.activeTab) {
                    case 1:
                        filter.candidateStatus = 'toReview';
                        break;
                    case 2:
                        filter.candidateStatus = 'reviewed';
                        break;
                    case 3:
                        filter.candidateStatus = 'pending';
                        break;
                    case 4:
                        filter.candidateStatus = 'archived';
                        break;
                    case 5:
                        filter.candidateStatus = 'onlyInvited';
                        break;
                    case 6:
                        filter.candidateStatus = 'all';
                        break;
                    default: 
                        filter.candidateStatus = 'started';
                }

                let hasFilters = false;
                if ($scope.searchField) {
                    filter.textSearch = $scope.searchField
                    if ($scope.showAdvancedSearch) {
                        filter.advancedTextSearch = $scope.advancedSearchOptions;
                    }
                    hasFilters = true;
                }

                (function applyTagsByCategory() {
                    // Apply tags with their categories (used for OR | AND search)
                    const tagsByCategory = {};
                    Object.entries($scope.ddFilters.tagCategories).forEach(([key, value], index) => {
                        const categoryId = key;
                        const dynamicId = `tag-${index}`;
                        if (value.list.length > 0) {
                            tagsByCategory[categoryId] = {
                                tags: value.list.map(tag => tag.id),
                                useOr: !!$rootScope.filters.tagsFilterUseOr[dynamicId]
                            };
                        } else {
                            tagsByCategory[categoryId] = {
                                tags: [],
                                useOr: !!$rootScope.filters.tagsFilterUseOr[dynamicId]
                            };
                        }
                    });
                    if (Object.keys(tagsByCategory).length > 0) {
                        filter.tagsByCategory = tagsByCategory;
                        hasFilters = true;
                    }
                    // note for future dev : This filter setting should be saved in the local storage, like the rest of the filters
                    _.set($rootScope, 'filters.tagsFilterUseOr', _.get($rootScope, 'filters.tagsFilterUseOr', {}));
                    filter.tagsFilterUseOr = $rootScope.filters.tagsFilterUseOr;
                })();

                if (!$scope.splitTagCategories && $scope.ddFilters.tag.list.length > 0) {
                    const tags = $scope.ddFilters.tag.list.map(tag => tag.id);
                    if (tags.length > 0) {
                        const useOr = !!$rootScope.filters.basicTagsFilterUseOr;
                        filter.tagsByCategory = {
                            'default': { 
                                tags: tags,
                                useOr: useOr
                            }
                        };
                        hasFilters = true;
                    }
                }

                // note for future dev : This filter setting should be saved in the local storage, like the rest of the filters
                delete filter.tagsFilterUseOr;

                if ($scope.ddFilters.campaign.list.length > 0) {
                    filter.campaigns = $scope.ddFilters.campaign.list.map(m => m.id);
                    hasFilters = true;
                }
                if ($scope.ddFilters.stage.list.length > 0 && $scope.displayMode !== 'kanban') {
                    filter.stage = $scope.ddFilters.stage.list.map(m => m.id);
                    hasFilters = true;
                }
                if ($scope.ddFilters.assessFirst.list.length > 0) {
                    filter.assessFirst = $scope.ddFilters.assessFirst.list.map(m => ({ progressStatus: m.progressStatus, scoreRange: m.scoreRange }));
                    hasFilters = true;
                }
                if ($scope.ddFilters.docimo.list.length > 0) {
                    filter.docimo = $scope.ddFilters.docimo.list.map(m => ({ scoreRange: m.scoreRange }));
                    hasFilters = true;
                }
                if ($scope.ddFilters.matching.list.length > 0) {
                    filter.matching = $scope.ddFilters.matching.list.map(m => ({ noScore: m.noScore, scoreRange: m.scoreRange, isSuper: m.isSuper }));
                    hasFilters = true;
                }
                if ($scope.ddFilters.rating.list.length > 0) {
                    filter.rating = $scope.ddFilters.rating.list.map(m => ({ ratingRange: m.ratingRange }));
                    hasFilters = true;
                }
                if ($scope.ddFilters.referralSources.list.length > 0) {
                    filter.referralSources = $scope.ddFilters.referralSources.list;
                    hasFilters = true;
                }
                if ($scope.ddFilters.country.list.length > 0) {
                    filter.country = $scope.ddFilters.country.list.map(item => item.id);
                    hasFilters = true;
                }
                if ($scope.ddFilters.addedBy.list.length > 0) {
                    filter.addedBy = $scope.ddFilters.addedBy.list.map(item => item.id);
                    hasFilters = true;
                }

                let sort = undefined;
                if (filter.textSearch && $scope.sortByTextMatchScore) {
                    $scope.setSorter();
                } else if ($scope.sortProperty) {
                    sort = {}
                    if ($scope.sortProperty === 'time') {
                        sort.stepStartTime = $scope.sortReverse ? -1 : 1;
                        sort.invitationDate = sort.stepStartTime;
                    } else {
                        sort[$scope.sortProperty] = $scope.sortReverse ? -1 : 1;
                    }
                } else {
                    sort = {
                        stepStartTime: -1,
                        invitationDate: -1,
                    }
                }

                const deferred = $q.defer();
                
                const offset = isReset || fetchAll ? 0 : $scope.displayMode === 'kanban' ? $scope.kanbanFetchOffset : $scope.candidates.length;
                const limit = fetchAll ? 0 : $scope.pageLimit;

                $scope.$ctrl.onLoadData({ $filter: filter, $sort: sort, $offset: offset, $limit: limit, $isKanbanView: $scope.displayMode === 'kanban' })
                    .then(res => {
                        if (!res) {
                            return;
                        }

                        if (offset === 0) {
                            $scope.candidates = res.items || [];
                            $scope.candidatesTotal = res.total || 0;
                            $scope.candidatesTotalStarted = res.totalStarted || 0;
                            $scope.candidatesTotalArchived = res.totalArchived || 0;
                            $scope.candidatesTotalOnlyInvited = res.totalOnlyInvited || 0;
                            $scope.checkedCandidatesIds = [];

                            if (res.stagesTotals) {
                                $scope.stages.forEach(stage => {
                                    stage.total = res.stagesTotals[stage.id||'0']
                                })
                            }
                        } else {
                            $scope.candidates = _.uniqBy([ ...$scope.candidates, ...res.items ], '_id');
                        }

                        $scope.candidatesHash = md5($scope.candidates.map(c => c._id).join(''));

                        $scope.categorizeCandidateTags();

                        for (const candidate of res.items||[]) {
                            candidate.nextComingSession = Util.getNextComingSession(candidate.sessions);
                            candidate.nextComingSessionTooltip = Util.makeSessionTooltip(candidate.nextComingSession);

                            if (Util.matchingFcts.cUses(candidate)) {
                                // candidate.matchingText = Translate.getLangString('submission_matching_score_is') + ' ' + Util.matchingFcts.getScorePercent(candidate);
                                candidate.matchingText = Util.matchingFcts.getScorePercent(candidate);
                            } else {
                                candidate.matchingText = '';
                            }
                        }
                        
                        $scope.kanbanCandidates = $scope.candidates.map(candidate => {
                            const actions = []
                            if (candidate.messages && candidate.messages.messageSent && $rootScope.fns.userHasRights("candidates.messages", "view")) {
                                actions.push({
                                    iconClass: { "far": true, "fa-paper-plane": true },
                                    sref: `submission-messages({candidateId: "${candidate._id}" })`,
                                    label: Translate.getLangString("candidate_mail_has_been_sent"),
                                })
                            }
                            if (candidate.cvDocument && $rootScope.fns.userHasRights("candidates.documents", "view")) {
                                if(candidate.cvDocument.viewUrl) {
                                    actions.push({
                                        iconClass: { "far": true, "fa-file-alt": true},
                                        href: candidate.cvDocument.viewUrl,
                                        label: Translate.getLangString("candidate_view_cv"),
                                    });
                                } else {
                                    actions.push({
                                        iconClass: { "far": true, "fa-file-alt": true},
                                        sref: `submission-documents({ candidateId: "${candidate._id}" })`,
                                        label: Translate.getLangString("candidate_view_cv_no_view"),
                                    });
                                }

                            }
                             if (candidate.videoAnswer) {
                                var videoUrl = $state.href('submission', { candidateId: candidate._id, firstVideoQuestion: true });
                                actions.push({
                                    iconClass: { "fas": true, "fa-video": true }, 
                                    href: videoUrl,
                                    label: Translate.getLangString("candidate_answered_video_questions"),
                                });
                            }
                            if (candidate.historyCount > 1 && $rootScope.fns.userHasRights("candidates.history", "view")) {
                                actions.push({
                                    iconClass: { "fas": true, "fa-user-clock": true },
                                    sref: `submission-candidatehistory({ candidateId: "${candidate._id}" })`,
                                    label: Translate.getLangString("application_history"),
                                })
                            }

                            return {
                                image: candidate.photoSrc,
                                title: $rootScope.userFullName(candidate),
                                sref: `submission({ candidateId: "${candidate._id}" })`,
                                subTitle: Util.getRelativeDate(candidate.stepStartTime),
                                matching: { ...candidate.matching, text: candidate.matchingText },
                                rating: candidate.rating,
                                isChecked: $scope.checkedCandidatesIds.indexOf(candidate._id) >= 0,
                                actions: actions,
                                value: candidate,
                            }
                        })
                        $scope.setCandidatesState();

                        const noCandidates = [
                            $scope.candidatesTotalStarted,
                            $scope.candidatesTotalArchived,
                            $scope.candidatesTotalOnlyInvited
                        ].every(n => n === 0)
                        if (noCandidates && !hasFilters) {
                            $scope.hasNoCandidates = true;
                        } else {
                            $scope.hasNoCandidates = false;
                        }

                        deferred.resolve()
                    }).catch(deferred.reject);

                return deferred.promise;
            }

            $scope.categorizeCandidateTags = function() {
                if (!$scope.candidates || Object.keys($scope.ddFilters.tagCategories).length === 0) {
                    return;
                }

                for (const candidate of $scope.candidates) {
                    if (!candidate.tagCategories) {
                        candidate.tagCategories = {}
                    }
                    for (const tagCategoryFilterId in $scope.ddFilters.tagCategories) {
                        const tagCategoryFilter = $scope.ddFilters.tagCategories[tagCategoryFilterId]
                        candidate.tagCategories[tagCategoryFilterId] = {
                            tags: candidate.tags.filter(tag => tagCategoryFilter.options.some(t => t.id === tag._id))
                        }
                    }
                }
            }

            $scope.setCandidatesState = function() {
                if (!$scope.kanbanCandidates || !$scope.stages || $scope.stages.length === 0) {
                    return;
                }
                for(const candidate of $scope.kanbanCandidates) {
                    if (!candidate.value.stages && candidate.value.stages.length === 0) {
                        candidate.stateId = $scope.stages[0];
                    } else {
                        const currentStage = candidate.value.stages[candidate.value.stages.length - 1];
                        if (currentStage && currentStage.stage) {
                            const state = $scope.stages.find(stg => stg.id === currentStage.stage._id);
                            candidate.stateId = state && state.id;
                        } else {
                            candidate.stateId = undefined;
                        }
                    }
                }
            }


            // Settings
            $scope.translationColumnSettingsTexts = { buttonDefaultText: Translate.getLangString('assessment_step_settings') };
            $scope.isColumnDropdownOpen = false;
            $scope.columnOptions = [
                { id: 'interview', label: Translate.getLangString('display_column_interview') },
                { id: 'expandTagsCategories', label: Translate.getLangString('expand_tag_categories') },
                { id: 'addedBy', label: Translate.getLangString('display_column_added_by')},
                { id: 'lastTreatedDate', label: Translate.getLangString('display_column_last_treated_date')},
            ];

            $scope.dropdownSettings = {
                checkBoxes: true,
            };

            $scope.editSelectedColumns = function() {
                let newVal = $scope.selectedColumns; 
                $scope.showInterview = newVal.some(function(column) {
                    return column.id === 'interview';
                });
                $scope.showLastTreatedDate = newVal.some(function(column) {
                    return column.id === 'lastTreatedDate';
                });
                $scope.showAddedBy = newVal.some(function(column) {
                    return column.id === 'addedBy';
                });
                $scope.splitTagCategories = newVal.some(function(column) {
                    return column.id === 'expandTagsCategories';
                });

                // Reset the search field and filtered data when a setting is changed to ensure a fresh start
                $scope.searchField = '';
                $scope.isSearchingCandidates = false;

                $scope.resetFilters();
                $scope.saveUserSettings();
                $scope.loadFilters();
                $scope.onLoadData(true);
                giveActiveClassToFilters();

            };   

            $scope.selectedColumnsEvents = { onSelectionChanged: $scope.editSelectedColumns };

            Server.get('users/'+ $rootScope.user._id +'/settings')
                .then(function(res) {
                    $scope.showInterview = res.candidates.useInterview;
                    $scope.showLastTreatedDate = res.candidates.useLastTreatedDate;
                    $scope.showAddedBy = res.candidates.useAddedBy;
                    $scope.splitTagCategories = res.candidates.tagsCategorySplit;
                    
   
                    $scope.selectedColumns = $scope.columnOptions.filter(function(column) {
                        return ($scope.showInterview && column.id === 'interview') ||
                               ($scope.showLastTreatedDate && column.id === 'lastTreatedDate') ||
                               ($scope.showAddedBy && column.id === 'addedBy') ||
                               ($scope.splitTagCategories && column.id === 'expandTagsCategories');
                    });

                    // $scope.editSelectedColumns($scope.selectedColumns)

           });

            $scope.saveUserSettings = function() {
                const updatedSettings = {};
                if ($scope.showLastTreatedDate !== undefined) {
                    updatedSettings['candidates.useLastTreatedDate'] = $scope.showLastTreatedDate;
                }
                if ($scope.showAddedBy !== undefined) {
                    updatedSettings['candidates.useAddedBy'] = $scope.showAddedBy;
                }
                if ($scope.showInterview !== undefined) {
                    updatedSettings['candidates.useInterview'] = $scope.showInterview;
                }
                updatedSettings['candidates.tagsCategorySplit'] = $scope.splitTagCategories;
            
                Server.patch('users/'+ $rootScope.user._id +'/settings', updatedSettings)
                    .then(function(res) {
                        $rootScope.user.settings.candidates.useInterview = $scope.showInterview;
                        $rootScope.user.settings.candidates.useLastTreatedDate = $scope.showLastTreatedDate;
                        $rootScope.user.settings.candidates.useAddedBy = $scope.showAddedBy;
                        $rootScope.user.settings.candidates.tagsCategorySplit = $scope.splitTagCategories;
                    })
                    .catch(function(err) {
                        console.error("Error updating user settings", err);
                    });
            };    
            
            $scope.$watch('splitTagCategories', function(newVal, oldVal) {
                if (newVal !== oldVal) {
                    $timeout(function() {
                        createTogglesForTagCategories();
                    }, 0);
                }
            });

            $scope.resetFilters = function () {
                $scope.ddFilters.stage.list = [];
                $scope.ddFilters.campaign.list = [];
                $scope.ddFilters.tag.list = [];
                $scope.ddFilters.tagCategories = {};
                $scope.ddFilters.assessFirst.list = [];
                $scope.ddFilters.docimo.list = [];
                $scope.ddFilters.matching.list = [];
                $scope.ddFilters.rating.list = [];
                $scope.ddFilters.referralSources.list = [];
                $scope.ddFilters.country.list = [];
                $scope.ddFilters.addedBy.list = [];
            }


            $scope.$ctrl.reload = function() {
                return $scope.onLoadData(true);
            }
            $scope.$ctrl.loadNext = function() {
                if ($scope.viewAllDomLimit < $scope.candidates.length) {
                    $scope.viewAllDomLimit += $scope.infiniteScrollStep;
                }
                if ($scope.candidates.length < $scope.candidatesTotal) {
                    $scope.kanbanFetchOffset += $scope.pageLimit;
                    $scope.onLoadData(false);
                }
            }

            $scope.$ctrl.$onChanges = function(changesObj) {
                
            }

            $scope.$ctrl.$onInit = function() {
                // Check if referral-sources.json is already in the storage
                const referralSourcesData = $window.localStorage.getItem('referralSourcesData');
                if (referralSourcesData) {
                    // If data is already in the storage, we parse it and use it
                    const referralSources = JSON.parse(referralSourcesData);
                    $scope.ddFilters.referralSources.options = referralSources.igbJobBoards
                            .concat(referralSources.internalSources, referralSources.recruiterImport)
                            .map(option => ({
                                id: option.value, 
                                label: option.label,
                            }));
                } else { // If data is not in the storage, we load it from the server
                    Util.loadReferralSources(true).then(function(referralSources){
                        $window.localStorage.setItem('referralSourcesData', JSON.stringify(referralSources));
                        $scope.ddFilters.referralSources.options = referralSources.igbJobBoards
                            .concat(referralSources.internalSources, referralSources.recruiterImport)
                            .map(option => ({
                                id: option.value, 
                                label: option.label,
                            }));
                    }).catch(function(error){
                        console.error("Error when trying to retrieve the referral sources", error);
                    });
                }
                const ctrlDefer = $q.defer();
                $scope.pageLimit = $scope.$ctrl.pageLimit || 10;
                if ($scope.$ctrl.displayMode) {
                    $scope.displayMode = $scope.$ctrl.displayMode
                }
                $q.all([$scope.loadFilters(), $scope.onLoadData(true)]).then(() => {
                    $scope.setSorter();
                    if ($scope.hasViewRights) {
                        if (!hasCachedCandidates) {
                            ctrlDefer.resolve();
                        }
                    } else {
                        ctrlDefer.resolve();
                    }
                    giveActiveClassToFilters();
                }).catch((err) => {
                    ToasterService.failure(err)
                });
                $timeout(function() {
                    createTogglesForTagCategories();
                }, 0);
                return ctrlDefer.promise;
            }
            
        }]
    });
